import { FC, useContext } from "react";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { UserGoodsControl } from "../../../../../domain/control/Goods";
import { GoodsControlList } from "../../../data-display/goods-control-list";
import { GoodsControlTable } from "../../../data-display/goods-control-table";

type Props = {
  items?: UserGoodsControl[];
  onClick: (user: UserGoodsControl) => void;
  onPreview: (item: UserGoodsControl) => void;
};

export const GoodsControlGroupByRequestDataName =
  "GoodsControlGroupByRequestData";

export const GoodsControlGroupByRequestData: FC<Props> = ({
  items,
  onClick,
  onPreview,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <GoodsControlList
        onSelect={onClick}
        goodsControlList={items}
        name={GoodsControlGroupByRequestDataName}
      />
    );
  } else {
    return (
      <GoodsControlTable
        goodsControlList={items}
        onPreviewClicked={onPreview}
        onSelect={onClick}
        name={GoodsControlGroupByRequestDataName}
      />
    );
  }
};
