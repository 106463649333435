import { FC, useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLivePatrolGroupSupViewModel } from "../../../../viewmodel/patrol-group-sup/LivePatrolGroupSup";
import { UserPatrol } from "../../../../domain/patrol/Patrol";
import { PatrolGroupSupLiveData } from "./components/data";
import { LivePatrolSupModal } from "./components/modal";
import { DashboardHeaderContext } from "../../../../hook/header";

export const LivePatrolSupGroup: FC<any> = () => {
  const {
    connect,
    connectionState,
    onConnectionStateReceived,
    list,
    onSelectItemCompleted,
    requestSelectItem,
    selectedItem,
  } = useLivePatrolGroupSupViewModel();

  const navigate = useNavigate();
  const dashboardHeaderContext = useContext(DashboardHeaderContext);

  /* eslint-disable */
  useEffect(() => {
    void connect();
    dashboardHeaderContext.setTheme({
      className: "bg-secondary-header",
      title: "Rondas",
      dark: true,
      subtitle: "Ver en tiempo real",
    });
  }, []);

  const onTableAttendanceSelected = useCallback((item: UserPatrol) => {
    navigate(
      `/dashboard/patrolSup?reference=${encodeURIComponent(item.reference)}`
    );
  }, []);

  const onTablePreviewActionClicked = useCallback((item: UserPatrol) => {
    requestSelectItem(item);
  }, []);

  useEffect(() => {
    if (!!connectionState && !connectionState.isLoading()) {
      if (connectionState.isFailed()) {
      }
      onConnectionStateReceived();
    }
  }, [connectionState]);

  /* eslint-enable */

  return (
    <div className={"w-full overflow-x-hidden"}>
      {!!selectedItem ? (
        <LivePatrolSupModal
          onClose={onSelectItemCompleted}
          patrol={selectedItem}
        />
      ) : null}
      <PatrolGroupSupLiveData
        items={list}
        onClick={onTableAttendanceSelected}
        onPreview={onTablePreviewActionClicked}
      />
    </div>
  );
};
