import { FirebaseUser } from "../source/FirebaseUser";
import { NewUser, PentrackerUser, UserWrapper } from "../../domain/user/User";
import {
  newUserAsDto,
  newUserPartialAsDto,
  UserListQueryRequest,
} from "../../network/user/User";
import { FirebaseStorageSource } from "../source/FirebaseStorageSource";
import { getFileTypeMetadata } from "../../util/file";
import { UserStoreDao } from "../database/dao/User";
import { isEmpty } from "lodash";
import { mixWithPentrackerUserData } from "../../lib/userlist";
import FirebaseAuth from "../source/FirebaseAuth";
import { UserCustomClaims } from "../../domain/user/CustomClaims";
import { DeleteUser } from "../../domain/user";
import { UserNotificationFormData } from "../../domain/user/UserNotification";

export default class UserRepository {
  static source = new FirebaseUser();

  static storagePhotoBucket = "user";

  static async createUser(user: NewUser) {
    const result = await this.source.createUser(newUserAsDto(user));
    if (result) {
      if (user.photoFileType && user.photoFileType.blobFile) {
        const file = user.photoFileType.blobFile;
        const photoUrl = await FirebaseStorageSource.uploadBytes(
          `${this.storagePhotoBucket}/${result.uid}`,
          file,
          getFileTypeMetadata(file)
        );
        const editResult = await this.source.editUser(
          result.uid,
          "[Sin cambios] Actualización de foto de perfil.",
          { photoUrl }
        );
        if (editResult) {
          await UserStoreDao.putUser(editResult);
        }
      } else {
        await UserStoreDao.putUser(result);
      }
    }
  }

  static async editUser(uid: string, user: Partial<NewUser>) {
    if (user.photoFileType && user.photoFileType.blobFile) {
      const file = user.photoFileType.blobFile;
      user.photoUrl = await FirebaseStorageSource.uploadBytes(
        `${this.storagePhotoBucket}/${uid}`,
        file,
        getFileTypeMetadata(file)
      );
    } else if (user.photoUrl === null) {
      await FirebaseStorageSource.remove(`${this.storagePhotoBucket}/${uid}`);
    }
    const result = await this.source.editUser(
      uid,
      user.reason!!,
      newUserPartialAsDto(user)
    );
    if (result) {
      await UserStoreDao.putUser(result);
    }
  }

  static async deleteUser(data: DeleteUser) {
    await this.source.deleteUser(data);
    await UserStoreDao.deleteUser(data.uid);
  }

  static async getUserList(
    request: UserListQueryRequest,
    forced: boolean = false
  ): Promise<PentrackerUser[] | undefined> {
    let localList = await UserStoreDao.getList(request.orderBy, request.order);
    if (isEmpty(localList) || forced) {
      const result = await this.source.getUsers(request);
      await UserStoreDao.deleteAll();
      if (result) await UserStoreDao.putUser(...result);
      localList = await UserStoreDao.getList(request.orderBy, request.order);
    }
    if (!!localList) {
      return await Promise.resolve(
        await Promise.all(
          localList.map(async (user: PentrackerUser) => {
            user = await mixWithPentrackerUserData(user);
            return user;
          })
        )
      );
    }
    return undefined;
  }

  static async searchUser(
    keyword: string
  ): Promise<PentrackerUser[] | undefined> {
    await UserStoreDao.deleteSearchResults();
    const result = await this.source.searchUser(keyword);

    if (result) {
      await UserStoreDao.putSearchResults(...result);
      const searchResult = await UserStoreDao.getSearchResults();
      return await Promise.resolve(
        Promise.all(
          searchResult.map(async (sRes: PentrackerUser) => {
            sRes = await mixWithPentrackerUserData(sRes);
            return sRes;
          })
        )
      );
    }
  }

  static async getUser(
    uid: string,
    forceRefresh: boolean = false
  ): Promise<PentrackerUser | undefined> {
    let localUser: PentrackerUser | undefined = await UserStoreDao.getUser(uid);

    if (!localUser || forceRefresh) {
      const result = await this.source.getUser(uid);
      if (result) {
        await UserStoreDao.putUser(result);
      }
      localUser = await UserStoreDao.getUser(uid);
    }
    if (!!localUser) {
      const resultMix = await mixWithPentrackerUserData(localUser);
      return resultMix;
    }
  }

  static async getCurrentUser(): Promise<UserWrapper> {
    const sessionUser = await FirebaseAuth.getCurrentUser();
    const customClaims = (await sessionUser!!.getIdTokenResult(true))
      .claims as UserCustomClaims;
    const user = {
      ...sessionUser,
      ...sessionUser?.metadata,
      ...customClaims,
      disabled: false,
    };
    return new UserWrapper(user as PentrackerUser);
  }

  static async getUnityUsers(unityId: number) {
    const users = await UserStoreDao.getUnityUsers(unityId);
    return Promise.resolve(
      await Promise.all(
        users.map(async (plain) => await mixWithPentrackerUserData(plain))
      )
    );
  }

  static async sendNotification(data: UserNotificationFormData) {
    const newNotification = {
      ...data,
      priority: "high",
      topic: "CONTROL",
    };
    await UserRepository.source.sendNotification(newNotification);
  }
}
