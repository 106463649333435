import { FirestoreSimpleCrudSource } from "../source/FirestoreSimpleCrudSource";
import { QueryConstraint, Timestamp, where } from "firebase/firestore";
import { AppTimestamp } from "../../domain/app/Timestamp";
import { DateTime } from "luxon";
import { AppTimestampDao } from "../database/dao/AppTimestamp";
import { isEmpty } from "lodash";
import { orderBy as firestoreOrderBy } from "@firebase/firestore";
import UnityRepository from "./UnityRepository";
import UserRepository from "./UserRepository";
import { UserWrapper } from "../../domain/user/User";
import { GetVisitControlDto } from "../../network/control/Visit";
import {
  UserVisitControl,
  VisitControl,
  visitControlAsUserVisitControl,
  visitControlDtoAsDomain,
} from "../../domain/control/Visit";
import { VisitControlDao } from "../database/dao/Visit";
import Fuse from "fuse.js";

export class VisitControlRepository {
  private static visitControlSource =
    new FirestoreSimpleCrudSource<GetVisitControlDto>("visit");

  static async getList(
    timestamp: number,
    unityId: number,
    forceRefresh?: boolean
  ): Promise<
    | { visitControlList: UserVisitControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `${unityId}_visit-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let unityVisitList = await VisitControlDao.getAllUnityVisitControl(
      unityId,
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(unityVisitList) || forceRefresh) {
      await VisitControlDao.deleteAllUnityVisitControl(
        unityId,
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemoteVisitControlList(
        dayStartTimestamp,
        dayEndTimestamp,
        [where("unityId", "==", unityId)]
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      unityVisitList = await VisitControlDao.getAllUnityVisitControl(
        unityId,
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const unityVisitArray = await this.visitControlArrayToUserVisitControlArray(
      unityVisitList
    );
    return {
      visitControlList: unityVisitArray,
      timestamp: appTimestamp,
    };
  }

  static async getUserList(
    timestamp: number,
    uid: string,
    forceRefresh: boolean = false,
    appUser: UserWrapper
  ): Promise<
    | { userVisitControlList: UserVisitControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    if (appUser?.isClient()) {
      if (appUser!.user!.clientUnity) {
        return this.getUserListWithConstraints(timestamp, uid, forceRefresh, [
          where("unityId", "in", appUser!.user.clientUnity),
        ]);
      }
    } else {
      return this.getUserListWithConstraints(timestamp, uid, forceRefresh);
    }
  }

  static async getGlobal(
    timestamp: number,
    forceRefresh: boolean = false,
    appUser: UserWrapper
  ): Promise<
    | { visitControlList: UserVisitControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    if (appUser.isClient()) {
      if (appUser.user.clientUnity) {
        return this.getGlobalWithConstraints(timestamp, forceRefresh, [
          where("unityId", "in", appUser.user.clientUnity),
        ]);
      }
    } else {
      return this.getGlobalWithConstraints(timestamp, forceRefresh);
    }
  }

  static async getGlobalWithConstraints(
    timestamp: number,
    forceRefresh: boolean = false,
    constraints: QueryConstraint[] = []
  ): Promise<
    | { visitControlList: UserVisitControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `g_visit-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let visitControlList = await VisitControlDao.getAllVisitControl(
      undefined,
      undefined,
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(visitControlList) || forceRefresh) {
      await VisitControlDao.deleteAllVisitControl(
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemoteVisitControlList(
        dayStartTimestamp,
        dayEndTimestamp,
        constraints
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      visitControlList = await VisitControlDao.getAllVisitControl(
        undefined,
        undefined,
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const userVisitControlArray =
      await this.visitControlArrayToUserVisitControlArray(visitControlList);
    return {
      visitControlList: userVisitControlArray,
      timestamp: appTimestamp,
    };
  }

  static async getVisitControl(
    reference: string
  ): Promise<UserVisitControl | undefined> {
    const local = await VisitControlDao.getVisitControl(reference);
    if (!!local) {
      return await this.visitControlToUserVisitControl(local);
    }
  }

  private static async getUserListWithConstraints(
    timestamp: number,
    uid: string,
    forceRefresh?: boolean,
    constraints: QueryConstraint[] = []
  ): Promise<
    | { userVisitControlList: UserVisitControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `u_${uid}_visit-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let visitControlList = await VisitControlDao.getUserVisitControlList(
      uid,
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(visitControlList) || forceRefresh) {
      await VisitControlDao.deleteAllUserVisitControl(
        uid,
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemoteVisitControlList(
        dayStartTimestamp,
        dayEndTimestamp,
        [where("ownerUid", "==", uid), ...constraints]
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      visitControlList = await VisitControlDao.getUserVisitControlList(
        uid,
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const visitControlArray =
      await this.visitControlArrayToUserVisitControlArray(visitControlList);
    return {
      userVisitControlList: visitControlArray,
      timestamp: appTimestamp,
    };
  }

  private static async visitControlArrayToUserVisitControlArray(
    visitControlArray: VisitControl[]
  ): Promise<UserVisitControl[]> {
    return await Promise.all(
      visitControlArray.map(
        async (it) => await this.visitControlToUserVisitControl(it)
      )
    );
  }

  private static async fetchRemoteVisitControlList(
    dateStartMillis: number,
    dateEndMillis: number,
    constraints: QueryConstraint[] = []
  ): Promise<void> {
    const visitControlList = await this.visitControlSource.getList([
      where("timestamp", ">=", Timestamp.fromMillis(dateStartMillis)),
      where("timestamp", "<=", Timestamp.fromMillis(dateEndMillis)),
      firestoreOrderBy("timestamp", "desc"),
      ...constraints,
    ]);

    if (visitControlList) {
      const entries = visitControlList.map((it) =>
        visitControlDtoAsDomain(it.data, it.reference)
      );
      await VisitControlDao.putVisitControl(...entries);
    }
  }

  private static async visitControlToUserVisitControl(
    visitControl: VisitControl
  ): Promise<UserVisitControl> {
    const unity = visitControl.unityId
      ? await UnityRepository.getUnity(visitControl.unityId)
      : undefined;
    const owner = await UserRepository.getUser(visitControl.ownerUid);
    return visitControlAsUserVisitControl(visitControl, unity, owner);
  }

  static async searchVisit(
    filter: string,
    keyword: string,
    timestampStart: number | null,
    timestampEnd: number | null,
    inall?: boolean // : Promise<UnityAttendance[] | undefined>
  ) {
    // let options;
    let localVisits: VisitControl[] | UserVisitControl[];
    let dayStartTimestamp;
    let dayEndTimestamp;
    if (timestampStart && timestampEnd) {
      const dateTimeInit =
        DateTime.fromMillis(timestampStart).setZone("America/Lima");
      const dateTimeEnd =
        DateTime.fromMillis(timestampEnd).setZone("America/Lima");

      dayStartTimestamp = dateTimeInit.startOf("day").toMillis();
      dayEndTimestamp = dateTimeEnd.endOf("day").toMillis();
    }

    let visitControlList = await VisitControlDao.getAllVisitControl(
      undefined,
      undefined,
      inall === true ? undefined : dayStartTimestamp,
      inall === true ? undefined : dayEndTimestamp
    );
    console.log("visitControlList", visitControlList);
    localVisits = await this.visitControlArrayToUserVisitControlArray(
      visitControlList
    );


    // ["unity.label", "name", "lastname","dni"]
    let options = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys:
        filter === "unityId"
          ? ["unity.label"]
          : filter === "name"
          ? ["name"]
          : filter === "lastname"
          ? ["lastname"]
          : filter === "dni"
          ? ["dni"]
          : ["unity.label", "name", "lastname", "dni"],
    };

    if (localVisits) {
      const fuse = new Fuse(localVisits, options);
      return fuse.search(keyword).map((it) => ({ ...it.item }));
    }
  }
}
