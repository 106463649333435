// import { InputDataListS } from "../../../vehicle-control-group/request/modal/SearchVehicleModal";
import { InputDataListS } from "../../../../components/modal/SearchFormModal";

const FilterOptions: {
  value: string;
  label: string;
}[] = [
  {
    value: "unityId",
    label: "Unidad",
  },
  {
    value: "provider",
    label: "Proveedor",
  },
  {
    value: "dni",
    label: "DNI",
  },
];

export const SearchGoodsInputList: InputDataListS = [
  {
    name: "filter",
    type: "select",
    label: "Buscar por",
    options: FilterOptions,
    cleanable: false,
  },
  {
    name: "search",
    type: "keyword",
    label: "Palabra Clave",
    placeholder: "Ingrese busqueda",
    defaultValue: "",
    // options: FilterOptions,
    // cleanable: false,
  },
  {
    name: "dpInicio",
    type: "datepicker",
    label: "Desde",
    // options: FilterOptions,
    // cleanable: false,
  },
  {
    name: "dpFinal",
    type: "datepicker",
    label: "Hasta",
    // options: FilterOptions,
    // cleanable: false,
  },
  {
    name: "searchInAll",
    type: "checkbox",
    label: "Todos los Registros",
    defaultValue: false,
    // options: FilterOptions,
    // cleanable: false,
  },
];
