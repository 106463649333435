import React, { FC, useMemo } from "react";
import { ListChildComponentProps } from "react-window";
import { toRelativeCalendar } from "../../../../../../lib/date";
import { UserSearchListRowProps } from "./index";
import { UserWrapper } from "../../../../../../domain/user/User";
import { UserRowRole } from "../../../../data-display/user-list/role";
import { UserRowDisplayName } from "../../../../data-display/user-list/name";
import { UserRowUid } from "../../../../data-display/user-list/uid";
import { UserRowClientUnity } from "../../../../data-display/user-list/clientunity";
import { UserRowUnity } from "../../../../data-display/user-list/unity";

export const UserSearchRow: FC<
  ListChildComponentProps<UserSearchListRowProps>
> = ({ index, style, data }) => {
  const { items, onClick } = data;
  const item = items[index];
  const wrapper = useMemo(() => {
    return new UserWrapper(item);
  }, [item]);
  return (
    <div style={style} className={"p-2"}>
      <div
        data-index={index}
        onClick={onClick}
        className={
          "w-full h-full border border-neutral-300 rounded-2xl p-2 flex flex-col justify-center items-start gap-2"
        }
      >
        <UserRowRole userWrapper={wrapper} />
        <UserRowDisplayName user={item} />
        <UserRowUid user={item} />
        {wrapper.isClient() ? (
          <UserRowClientUnity user={item} />
        ) : (
          <UserRowUnity user={item} />
        )}
        {item.lastSignInTime ? (
          <p className={"m-0 p-0"}>
            <span className={"mr-2 text-neutral-400 font-semibold"}>
              ULT. SESIÓN
            </span>
            <span>{toRelativeCalendar(new Date(item.lastSignInTime))}</span>
          </p>
        ) : null}
        <p className={"m-0 p-0"}>
          <span className={"mr-2 text-neutral-400 font-semibold"}>CREADO</span>
          <span>{toRelativeCalendar(new Date(item.creationTime))}</span>
        </p>
      </div>
    </div>
  );
};
