import { FC, useContext } from "react";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { UserPatrol } from "../../../../../domain/patrol/Patrol";
import { PatrolList } from "../../../data-display/patrol-list";
import { PatrolTable } from "../../../data-display/patrol-table";

type Props = {
  items?: UserPatrol[];
  onPreview: (item: UserPatrol) => void;
  onClick: (user: UserPatrol) => void;
};

export const PatrolGroupSupByRequestDataName = "PatrolGroupSupByRequestData";

export const PatrolGroupSupByRequestData: FC<Props> = ({
  items,
  onPreview,
  onClick,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <PatrolList
        onSelect={onClick}
        patrolList={items}
        onPreviewClicked={onPreview}
        name={PatrolGroupSupByRequestDataName}
      />
    );
  } else {
    return (
      <PatrolTable
        userPatrolList={items}
        onPreviewClicked={onPreview}
        onSelect={onClick}
        name={PatrolGroupSupByRequestDataName}
      />
    );
  }
};
