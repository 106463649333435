import { FC } from "react";
import { Modal } from "rsuite";
import { UserPatrol } from "../../../../../domain/patrol/Patrol";
import { UserPatrolView } from "../../../patrol/components/view";

export const LivePatrolSupModal: FC<{
  onClose: () => void;
  patrol: UserPatrol;
}> = ({ onClose, patrol }) => {
  return (
    <Modal open={true} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Ronda</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserPatrolView patrol={patrol} />
      </Modal.Body>
    </Modal>
  );
};
