import {
  FC,
  Fragment,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Badge, Drawer, IconButton, Whisper } from "rsuite";
import DashboardMenu from "./menu";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faArrowLeft, faBars, faBell } from "@fortawesome/free-solid-svg-icons";
import { BreakPointContext } from "../../../context/BreakPointContext";
import { DashboardHeaderContext } from "../../../../hook/header";
import { useComponentSize } from "../../../../hook/rsuite";
import { UserAvatar } from "../../../components/avatar/useravatar";
import { UserAuthContext } from "../../../context/UserContext";
import { ProfileSpeaker } from "./profile";
import { Link, useNavigate } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks";
import { preferencesDb } from "../../../../data/database/db";
import styles from "react-loading-overlay-ts/dist/styles";

type Props = {
  containerRef: RefObject<HTMLDivElement>;
  stylePage: any;
};

export const DashboardHeader: FC<Props> = ({ containerRef, stylePage }) => {
  const notificationCount = useLiveQuery(() =>
    preferencesDb.notifications.count()
  );
  const config = useContext(DashboardHeaderContext);
  const navigate = useNavigate();
  const userContext = useContext(UserAuthContext);
  const [expanded, setExpanded] = useState(false);
  const buttonSize = useComponentSize({ min: "md" });
  const switchExpanded = useCallback(() => {
    setExpanded((old) => !old);
  }, []);
  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  /* eslint-disable */
  const registerSentinelObserver = useCallback(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      if (!container.className.includes("sticky-in-observer")) {
        const sentinels = container.getElementsByClassName(
          "main-app-header-top-sentinel"
        );
        const observer = new IntersectionObserver(
          (records, _) => {
            for (const record of records) {
              const targetInfo = record.boundingClientRect;
              const rootBoundsInfo = record.rootBounds;

              if (rootBoundsInfo) {
                if (targetInfo.bottom < rootBoundsInfo.top) {
                  config.setIsSticky(true);
                }

                if (
                  targetInfo.bottom >= rootBoundsInfo.top &&
                  targetInfo.bottom < rootBoundsInfo.bottom
                ) {
                  config.setIsSticky(false);
                }
              }
            }
          },
          { threshold: [0], root: container }
        );
        for (let i = 0; i < sentinels.length; i++) {
          observer.observe(sentinels.item(i)!);
        }
        container.classList.add("sticky-in-observer");
      }
    }
  }, [containerRef.current]);

  useEffect(() => {
    registerSentinelObserver();
  }, [containerRef.current]);

  /* eslint-enable */

  const breakpoint = useContext(BreakPointContext);
  const {
    subtitle,
    dark = false,
    title = "Pentracker",
    className,
    homeAsUpEnabled = false,
  } = config.theme;

  return (
    <Fragment>
      <div
        style={stylePage as any}
        className={`overflow-hidden w-full px-2 md:px-6 sticky top-0 left-0 right-0 z-50 main-app-header flex flex-row items-center gap-2 md:gap-3 ${
          config.sticky ? "shadow-md" : ""
        } ${className}`}
        ref={containerRef}
      >
        {breakpoint.isMobile ? (
          <IconButton
            circle
            appearance={"subtle"}
            size={buttonSize}
            icon={
              <FaIcon
                icon={faBars}
                className={`${dark ? "text-white" : "text-neutral-800"}`}
              />
            }
            className={"shrink-0"}
            onClick={switchExpanded}
          />
        ) : null}
        {homeAsUpEnabled ? (
          <IconButton
            circle
            appearance={"subtle"}
            size={buttonSize}
            icon={
              <FaIcon
                icon={faArrowLeft}
                className={`${dark ? "text-white" : "text-neutral-800"}`}
              />
            }
            className={"shrink-0"}
            onClick={onBack}
          />
        ) : null}
        {config.sticky ? (
          <div
            className={
              "h-full w-full flex flex-col items-start justify-center leading-none shrink overflow-hidden"
            }
          >
            <p
              className={`${
                dark ? "text-white" : "text-neutral-800"
              } p-0 m-0 text-sm md:text-lg font-semibold truncate`}
            >
              {title}
            </p>
            {subtitle ? (
              <p
                className={`p-0 m-0 text-xs ${
                  dark ? "text-neutral-200" : "text-neutral-500"
                } w-full truncate`}
              >
                {subtitle}
              </p>
            ) : null}
          </div>
        ) : null}
        <div
          className={
            "ml-auto flex flex-row gap-1 shrink-1 justify-center items-center"
          }
        >
          {userContext.appUser?.isAdmin() ? (
            <Badge content={`${notificationCount}`}>
              <Link to={"/dashboard/notification-center"}>
                <IconButton
                  circle
                  appearance={"subtle"}
                  size={buttonSize}
                  icon={
                    <FaIcon
                      icon={faBell}
                      className={`${dark ? "text-white" : "text-neutral-800"}`}
                    />
                  }
                  onClick={switchExpanded}
                />
              </Link>
            </Badge>
          ) : null}
          {userContext.appUser?.user ? (
            <Whisper
              speaker={ProfileSpeaker}
              placement={"auto"}
              preventOverflow
              trigger={"click"}
            >
              <button>
                <UserAvatar user={userContext.appUser.user} size={40} />
              </button>
            </Whisper>
          ) : null}
        </div>
      </div>
      {breakpoint.isMobile ? (
        <Drawer
          open={expanded}
          onClose={switchExpanded}
          backdrop={true}
          placement={"left"}
          size={"full"}
          className={"w-fit bg-white relative"}
        >
          <DashboardMenu
            onTouch={switchExpanded}
            onToggle={switchExpanded}
            stylePage={stylePage}
          />
        </Drawer>
      ) : null}
    </Fragment>
  );
};
