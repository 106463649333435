import {
  AttendanceSup,
  attendanceAsUserAttendance,
  attendanceDtoAsDomain,
  AttendancePair,
} from "../../domain/attendance-sup/Attendance";
import UserRepository from "./UserRepository";
import {
  collection,
  doc,
  DocumentSnapshot,
  getDoc,
  getDocFromCache,
  onSnapshot,
  orderBy as firestoreOrderBy,
  query,
  QueryConstraint,
  startAfter,
  Timestamp,
  Unsubscribe,
  where,
} from "firebase/firestore";
import FirestoreSource from "../source/FirestoreSource";
import { AttendanceSupStoreDao } from "../database/dao/AttendanceSup";

import { isEmpty, isNil } from "lodash";
import { DateTime } from "luxon";
import { AppTimestampDao } from "../database/dao/AppTimestamp";
import { FirestoreSimpleCrudSource } from "../source/FirestoreSimpleCrudSource";
import { AttendanceSupDto } from "../../network/attendance-sup/Attendance";
import { AppTimestamp } from "../../domain/app/Timestamp";
import UnityRepository from "./UnityRepository";
import { AccountTypeRepository } from "./AccountTypeRepository";
import ShiftRepository from "./ShiftRepository";
import { UserWrapper } from "../../domain/user/User";

export class AttendanceSupRepository {
  private static unsubscribeEntries: Unsubscribe | null;
  private static unsubscribeDepartures: Unsubscribe | null;

  private static entriesSource =
    new FirestoreSimpleCrudSource<AttendanceSupDto>("entrySup");

  private static departureSource =
    new FirestoreSimpleCrudSource<AttendanceSupDto>("departureSup");

  static async getAttendance(entryReference: string): Promise<AttendancePair> {
    let entry = await AttendanceSupStoreDao.getEntryByReference(entryReference);

    if (!entry) throw new Error("El ingreso no fue encontrado.");
    return await this.doPair(entry);
  }

  static async getList(
    timestamp: number,
    unityId: number,
    forceRefresh?: boolean
  ): Promise<
    { pairs: AttendancePair[]; timestamp?: AppTimestamp } | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `${unityId}/${dayStartTimestamp}/${dayEndTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let entries = await AttendanceSupStoreDao.getAllUnityEntries(
      unityId,
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(entries) || forceRefresh) {
      await AttendanceSupStoreDao.deleteUnityEntries(
        unityId,
        dayStartTimestamp,
        dayEndTimestamp
      );
      await AttendanceSupStoreDao.deleteUnityDepartures(
        unityId,
        dayStartTimestamp,
        dayEndTimestamp
      );

      await this.fetchRemoteAttendanceList(
        dayStartTimestamp,
        dayEndTimestamp,
        [where("unityId", "==", unityId)],
        [where("unityId", "==", unityId)]
      );

      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
      entries = await AttendanceSupStoreDao.getAllUnityEntries(
        unityId,
        dayStartTimestamp,
        dayEndTimestamp
      );
    }
    const pairs = await this.doPairs(entries);
    return {
      pairs,
      timestamp: appTimestamp,
    };
  }

  static async getGlobalList(
    timestamp: number,
    forceRefresh: boolean = false,
    appUser: UserWrapper
  ): Promise<
    { pairs: AttendancePair[]; timestamp?: AppTimestamp } | undefined
  > {
    if (appUser?.isClient()) {
      if (appUser!.user.clientUnity) {
        const clientUnityConstraint = [
          where("unityId", "in", appUser.user.clientUnity),
        ];
        return this.getGlobalListWithConstraints(
          timestamp,
          forceRefresh,
          clientUnityConstraint,
          clientUnityConstraint
        );
      }
    } else {
      return this.getGlobalListWithConstraints(timestamp, forceRefresh);
    }
  }

  static async getLiveList(
    entry: AttendanceSup,
    appUser: UserWrapper
  ): Promise<AttendancePair> {
    const dateTime = DateTime.fromMillis(
      DateTime.now().toJSDate().getTime()
    ).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const entryAttendanceEntry = await AttendanceSupRepository.injectAttendance(
      entry
    );
    let departure = null;

    let result: AttendancePair = {
      entry: entryAttendanceEntry,
    };

    if (appUser?.isClient()) {
      if (appUser!.user!.clientUnity) {
        const clientUnityConstraint = [
          where("unityId", "in", appUser!.user!.clientUnity),
        ];
        departure = await this.fetchLiveDeparture(entry, [
          where("ownerUid", "==", entry.ownerUid),
          ...clientUnityConstraint,
        ]);
      }
    } else {
      departure = await this.fetchLiveDeparture(entry, [
        where("ownerUid", "==", entry.ownerUid),
      ]);
    }

    if (departure) {
      result.departure = await this.injectAttendance(departure);
    } else {
      console.log("departure no pass", departure);
    }

    // const pairs = await this.doPair(entry);
    // return {
    //   entry: entry,
    //   // departure: departure?.data,
    // };
    return result;
  }

  static async getLiveListDeparture(entry: AttendanceSup) {
    let result: AttendancePair = {
      entry: entry,
    };

    let departure = null;

    departure = await this.fetchLiveDeparture(entry, [
      where("ownerUid", "==", entry.ownerUid),
    ]);

    if (departure) {
      result.departure = await this.injectAttendance(departure);
    } else {
      console.log("departure no pass", departure);
    }
  }

  static async getUserAttendance(
    uid: string,
    timestamp: number,
    forceRefresh: boolean = false,
    appUser: UserWrapper
  ): Promise<AttendancePair | undefined> {
    if (appUser?.isClient()) {
      if (appUser!.user!.clientUnity) {
        const clientUnityConstraint = [
          where("unityId", "in", appUser.user.clientUnity),
        ];
        return await this.getUserAttendanceWithConstraints(
          uid,
          timestamp,
          forceRefresh,
          clientUnityConstraint,
          clientUnityConstraint
        );
      }
    } else {
      return await this.getUserAttendanceWithConstraints(
        uid,
        timestamp,
        forceRefresh
      );
    }
    return undefined;
  }

  static async getLive(
    onError: (error: Error) => void,
    appUser: UserWrapper
  ): Promise<Unsubscribe | undefined> {
    if (this.unsubscribeEntries) return undefined;
    const dayStartTimestamp = DateTime.now()
      .setZone("America/Lima")
      .startOf("day")
      .toMillis();
    let lastDayEntry = await AttendanceSupStoreDao.getLastEntryAboveTimestamp(
      dayStartTimestamp
    );
    let lastDocument: DocumentSnapshot | undefined;
    if (lastDayEntry) {
      try {
        lastDocument = await getDocFromCache(
          doc(FirestoreSource.firestore, lastDayEntry.reference)
        );
      } catch (e: any) {
        lastDocument = await getDoc(
          doc(FirestoreSource.firestore, lastDayEntry.reference)
        );
      }
    }
    const entryCollection = collection(FirestoreSource.firestore, "entry");
    // const departureCollection = collection(
    //   FirestoreSource.firestore,
    //   "departure"
    // );

    const constraints: QueryConstraint[] = [
      firestoreOrderBy("timestamp", "asc"),
    ];
    if (!!lastDocument) constraints.push(startAfter(lastDocument));
    if (appUser.isClient() && appUser.user.clientUnity)
      constraints.push(where("unityId", "in", appUser.user.clientUnity));
    let entryQuery = query(entryCollection, ...constraints);
    this.unsubscribeEntries = onSnapshot(
      entryQuery,
      {
        includeMetadataChanges: false,
      },
      async (snapshot) => {
        for (let documentSnapshot of snapshot.docs) {
          const attendance = attendanceDtoAsDomain(
            documentSnapshot.data() as AttendanceSupDto,
            documentSnapshot.ref.path
          );
          await AttendanceSupStoreDao.putEntry(attendance);
        }
      },
      (error) => {
        onError(error);
      }
    );
    return this.unsubscribeEntries;
  }

  static async getLiveDepartures(
    onError: (error: Error) => void,
    appUser: UserWrapper
  ): Promise<Unsubscribe | undefined> {
    if (this.unsubscribeDepartures) return undefined;
    const dayStartTimestamp = DateTime.now()
      .setZone("America/Lima")
      .startOf("day")
      .toMillis();
    let lastDayDeparture =
      await AttendanceSupStoreDao.getLastDepartureAboveTimestamp(
        dayStartTimestamp
      );
    let lastDocument: DocumentSnapshot | undefined;
    if (lastDayDeparture) {
      try {
        lastDocument = await getDocFromCache(
          doc(FirestoreSource.firestore, lastDayDeparture.reference)
        );
      } catch (e: any) {
        lastDocument = await getDoc(
          doc(FirestoreSource.firestore, lastDayDeparture.reference)
        );
      }
    }
    const departureCollection = collection(
      FirestoreSource.firestore,
      "departure"
    );

    const constraints: QueryConstraint[] = [
      firestoreOrderBy("timestamp", "asc"),
    ];
    if (!!lastDocument) constraints.push(startAfter(lastDocument));
    if (appUser.isClient() && appUser.user.clientUnity)
      constraints.push(where("unityId", "in", appUser.user.clientUnity));
    let departureQuery = query(departureCollection, ...constraints);
    this.unsubscribeDepartures = onSnapshot(
      departureQuery,
      {
        includeMetadataChanges: false,
      },
      async (snapshot) => {
        for (let documentSnapshot of snapshot.docs) {
          const attendance = attendanceDtoAsDomain(
            documentSnapshot.data() as AttendanceSupDto,
            documentSnapshot.ref.path
          );
          await AttendanceSupStoreDao.putDeparture(attendance);
        }
      },
      (error) => {
        onError(error);
      }
    );
    return this.unsubscribeDepartures;
  }

  static async stopLive() {
    if (this.unsubscribeEntries) {
      this.unsubscribeEntries();
      this.unsubscribeEntries = null;
    }
  }

  static async doPairs(entryArray: AttendanceSup[]): Promise<AttendancePair[]> {
    return await Promise.all(
      entryArray.map(async (entry) => {
        return await this.doPair(entry);
      })
    );
  }

  static async doPair(entry: AttendanceSup): Promise<AttendancePair> {
    // console.time("injectAttendance");
    // console.timeEnd("injectAttendance");
    let attendanceEntry = await this.injectAttendance(entry);

    let result: AttendancePair = {
      entry: attendanceEntry!!,
    };

    const departure = await AttendanceSupStoreDao.getDeparture(
      entry.ownerUid,
      entry.timestamp,
      entry.timestamp + 14 * 60 * 60 * 1000
    );

    if (departure) {
      result.departure = await this.injectAttendance(departure);
    } else {
      console.log("departure false", departure);
    }

    return result;
  }

  static async injectAttendance(currentAttendance: AttendanceSup) {
    let user = await UserRepository.getUser(currentAttendance.ownerUid);

    let currentAttendanceUnity = await UnityRepository.getUnity(
      currentAttendance.unityId
    );
    let currentAttendanceAccountType = !isNil(currentAttendance.accountTypeId)
      ? await AccountTypeRepository.getAccountType(
          currentAttendance.accountTypeId
        )
      : undefined;
    let currentAttendanceShift = !isNil(currentAttendance.shiftId)
      ? await ShiftRepository.getShift(currentAttendance.shiftId)
      : undefined;
    return attendanceAsUserAttendance(
      currentAttendance,
      user,
      currentAttendanceUnity,
      currentAttendanceAccountType,
      currentAttendanceShift
    );
  }

  private static async getGlobalListWithConstraints(
    timestamp: number,
    forceRefresh: boolean = false,
    entryConstraints: QueryConstraint[] = [],
    departureConstraints: QueryConstraint[] = []
  ): Promise<
    { pairs: AttendancePair[]; timestamp?: AppTimestamp } | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `attendance-joined/${dayStartTimestamp}/${dayEndTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let entries = null;

    // await AttendanceStoreDao.getAllEntries(
    //   dayStartTimestamp,
    //   dayEndTimestamp
    // );

    if (isEmpty(entries) || forceRefresh) {
      await AttendanceSupStoreDao.deleteEntries(
        dayStartTimestamp,
        dayEndTimestamp
      );
      await AttendanceSupStoreDao.deleteDepartures(
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemoteAttendanceList(
        dayStartTimestamp,
        dayEndTimestamp,
        entryConstraints,
        departureConstraints
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
      entries = await AttendanceSupStoreDao.getAllEntries(
        dayStartTimestamp,
        dayEndTimestamp
      );
    } else {
      entries = await AttendanceSupStoreDao.getAllEntries(
        dayStartTimestamp,
        dayEndTimestamp
      );
    }
    const pairs = await this.doPairs(entries);
    return {
      pairs,
      timestamp: appTimestamp,
    };
  }

  private static async getUserAttendanceWithConstraints(
    uid: string,
    timestamp: number,
    forceRefresh: boolean = false,
    entryConstraints: QueryConstraint[] = [],
    departureConstraints: QueryConstraint[] = []
  ): Promise<AttendancePair | undefined> {
    const datetime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dateStart = datetime.startOf("day").toMillis();
    const dateEnd = datetime.endOf("day").toMillis();
    let localEntry = await AttendanceSupStoreDao.getEntry(
      uid,
      dateStart,
      dateEnd
    );
    if (!localEntry || forceRefresh) {
      await this.fetchRemoteAttendanceList(
        dateStart,
        dateEnd,
        [where("ownerUid", "==", uid), ...entryConstraints],
        [where("ownerUid", "==", uid), ...departureConstraints]
      );
      localEntry = await AttendanceSupStoreDao.getEntry(
        uid,
        dateStart,
        dateEnd
      );
    }
    if (!!localEntry) {
      return await this.doPair(localEntry);
    }
  }

  private static async fetchRemoteAttendanceList(
    dateStartMillis: number,
    dateEndMillis: number,
    entryConstraints: QueryConstraint[] = [],
    departureConstraints: QueryConstraint[] = []
  ): Promise<void> {
    const remoteEntry = await this.entriesSource.getList([
      where("timestamp", ">=", Timestamp.fromMillis(dateStartMillis)),
      where("timestamp", "<=", Timestamp.fromMillis(dateEndMillis)),
      firestoreOrderBy("timestamp", "desc"),
      ...entryConstraints,
    ]);

    if (!!remoteEntry) {
      const entries = remoteEntry.map((it) =>
        attendanceDtoAsDomain(it.data, it.reference)
      );
      const departuresTasks = entries.map(async (it) => {
        const dps = await this.departureSource.getByQuery([
          where("timestamp", ">=", Timestamp.fromMillis(it.timestamp)),
          //Hasta 14 horas después
          where(
            "timestamp",
            "<=",
            Timestamp.fromMillis(it.timestamp + 14 * 60 * 60 * 1000)
          ),
          where("ownerUid", "==", it.ownerUid),
          ...departureConstraints,
        ]);
        if (!!dps) {
          return attendanceDtoAsDomain(dps.data, dps.reference);
        }
      });
      const departures = (await Promise.all(departuresTasks)).filter(
        (it) => !!it
      ) as Array<AttendanceSup>;
      await AttendanceSupStoreDao.putEntry(...entries);
      if (!!departures && departures.length > 0) {
        await AttendanceSupStoreDao.putDeparture(...departures);
      }
    }
  }

  private static async fetchLiveDeparture(
    entry: AttendanceSup,
    departureConstraints: QueryConstraint[] = []
  ) {
    const departure = await this.departureSource.getByQuery([
      where("timestamp", ">=", Timestamp.fromMillis(entry.timestamp)),
      //Hasta 14 horas después
      where(
        "timestamp",
        "<=",
        Timestamp.fromMillis(entry.timestamp + 14 * 60 * 60 * 1000)
      ),
      where("ownerUid", "==", entry.ownerUid),
      ...departureConstraints,
    ]);

    if (!!departure) {
      return attendanceDtoAsDomain(departure.data, departure.reference);
    }

    return departure;
  }
}
