import { TableColumnRelation } from "../../ui/context/TableContext";
import { VehicleControl } from "../../domain/control/Vehicle";

export type VehicleControlTableColumns = {
  timestamp: string;
  plate: string;
  unityId: string;
  driver: string;
  entry: string;
  departure: string;
  actions: string;
};

export const VehicleControlTableSchema: TableColumnRelation<VehicleControlTableColumns> =
  {
    large: {
      breakpoints: ["lg", "xl", "2xl"],
      columns: [
        {
          name: "timestamp",
          label: "Añadido en",
          weight: 0.1,
        },
        {
          name: "plate",
          label: "Placa",
          weight: 0.1,
        },
        {
          name: "unityId",
          label: "Unidad",
          weight: 0.2,
        },
        {
          name: "driver",
          label: "Conductor",
          weight: 0.3,
        },
        {
          name: "entry",
          label: "Ingreso",
          weight: 0.1,
        },
        {
          name: "departure",
          label: "Salida",
          weight: 0.1,
        },
        {
          name: "actions",
          label: "Acciones",
          weight: 0.1,
        },
      ],
    },
  };
