import { db } from "../db";
import { Supervision } from "../../../domain/supervision/Supervision";

export class SupervisionDao {
  static async putSupervision(...supeervision: Supervision[]) {
    return db.supervision.bulkPut(supeervision);
  }
  
  static async getAllUnitySupervision(
    unityId: number,
    start: number,
    end: number
  ) {
    return db.supervision
      .where(["unityId+timestamp"])
      .between([unityId, start], [unityId, end], true, true)
      .reverse()
      .sortBy("timestamp");
  }

  static async getAllSupervision(start: number, end: number) {
    return db.supervision
      .where("timestamp")
      .between(start, end, true, true)
      .reverse()
      .sortBy("timestamp");
  }

  static async deleteAllUnitySupervision(
    unityId: number,
    start: number,
    end: number
  ) {
    return db.supervision
      .where(["unityId+timestamp"])
      .between([unityId, start], [unityId, end], true, true)
      .delete();
  }

  static async deleteAllSupervision(start: number, end: number) {
    return db.supervision
      .where("timestamp")
      .between(start, end, true, true)
      .delete();
  }

  static async getUserSupervisionList(
    ownerUid: string,
    start: number,
    end: number
  ) {
    return db.supervision
      .where(["ownerUid+timestamp"])
      .between([ownerUid, start], [ownerUid, end])
      .reverse()
      .sortBy("timestamp");
  }

  static async getLastSupervisionAboveTimestamp(preferredTimestamp: number) {
    return db.supervision
      .where("timestamp")
      .aboveOrEqual(preferredTimestamp)
      .last();
  }

  static async deleteAllUserSupervision(
    ownerUid: string,
    start: number,
    end: number
  ) {
    return db.supervision
      .where(["ownerUid+timestamp"])
      .between([ownerUid, start], [ownerUid, end])
      .delete();
  }

  static async getSupervision(reference: string) {
    return db.supervision.get(reference);
  }
}
