import { FC, useCallback, useEffect, useState } from "react";
import { AppLoader } from "../../../components/loading/LoadingOverlay";
import { DatePicker, IconButton, Loader } from "rsuite";
import { formatDate } from "../../../../util/date";
import { FaIcon } from "../../../components/fontawesome/icon";
import {
  faFilter,
  faRefresh,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { toRelativeCalendar } from "../../../../lib/date";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../../hook/query";
import { DateTime } from "luxon";
import { toInteger } from "lodash";
import { useQueryNavigate } from "../../../../hook/navigate";
import { VehicleControlGroupByRequestData } from "./component/data";
import { useVehicleControlViewModel } from "../../../../viewmodel/control/Vehicle";
import { UserVehicleControl } from "../../../../domain/control/Vehicle";
import { SearchBar } from "rsuite/esm/Picker";
import { Search } from "@rsuite/icons";
import { FormModal } from "../../../components/modal/FormModal";
import {
  FilterVehicleInputList,
  SearchVehicleInputList,
} from "./modal/FilterVehicleInputList";
import AlertModal from "../../../components/modal/AlertModal";
import { SearchFormModal } from "../../../components/modal/SearchFormModal";
import { ByRequestVehicleControlModal } from "./component/modal";

export const ByRequestVehicleControlGroup: FC<any> = () => {
  // const queryTimestamp = useQuery().get("timestamp");
  // const initialSelectedTime = queryTimestamp
  //   ? DateTime.fromMillis(toInteger(queryTimestamp)).toJSDate()
  //   : new Date();
  // const [selectedTime, setSelectedTime] = useState(initialSelectedTime);
  const [isFirstFetchDone, setIsFirstFetchDone] = useState(false);
  const [selection, setSelection] = useState<UserVehicleControl | null>(null);

  const queryNavigate = useQueryNavigate();
  const {
    //fetch
    fetchState,
    fetchVehicleControlList,
    onFetchStateReceived,
    vehicleControlTimestamp,
    vehicleControlList,
    //refresh
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,

    //filter
    filterEvent,
    requestParams,
    requestFilterEvent,
    onFilterEventCompleted,
    updateRequestParams,
    //search
    searchEvent,
    searchState,
    searchResults,
    requestSearchEvent,
    onSearchEventCompleted,
    searchVehicle,
    //generate
    generateEvent,
    generateState,
    requestGenerateEvent,
    onGenerateEventCompleted,
    toGenerateExcel,
    //alert
    isAlertVisible,
    descriptionAlert,
    setIsAlertVisible,
    //others
    selectedTime,
    setSelectedTime,
  } = useVehicleControlViewModel();
  const navigate = useNavigate();

  /* eslint-disable */

  useEffect(() => {
    void fetchVehicleControlList(undefined, undefined).then(() => {
      setIsFirstFetchDone(true);
    });
  }, []);

  useEffect(() => {
    if (isFirstFetchDone) {
      void fetchVehicleControlList(undefined, selectedTime.getTime());
    }
  }, [isFirstFetchDone, selectedTime]);

  useEffect(() => {
    if (fetchState && !fetchState.isLoading()) {
      if (fetchState.isFailed()) {
        window.toast.error(
          "Ocurrió un error al obtener la lista de control vehicular."
        );
        console.log(fetchState?.error);
      }
      onFetchStateReceived();
    }
  }, [fetchState]);
  {
  }

  useEffect(() => {
    if (refreshEvent) {
      void fetchVehicleControlList(undefined, selectedTime.getTime(), true);
      onRefreshEventCompleted();
    }
  }, [refreshEvent]);

  // useEffect(() => {
  //   if (searchEvent) {
  //     // void fetchVehicleControlList(selectedTime.getTime(), true);
  //     onSearchEventCompleted();
  //   }
  // }, [searchEvent]);
  function onCancelModal() {}
  const onTableItemSelected = useCallback((item: UserVehicleControl) => {
    navigate(
      `/dashboard/vehicle-control?reference=${encodeURIComponent(
        item.reference
      )}`
    );
  }, []);

  /* eslint-enable */
  const onModalClosed = useCallback(() => {
    setSelection(null);
  }, []);

  const loading = fetchState?.loading || undefined;

  const onCloseAlert = () => {
    setIsAlertVisible(false);
  };
  const onConfirmationAlert = () => {
    setIsAlertVisible(false);
  };

  const onTablePreviewActionClicked = useCallback(
    (item: UserVehicleControl) => {
      setSelection(item);
    },
    []
  );

  return (
    <div className={"w-full h-full flex flex-col p-3 gap-0 overflow-hidden"}>
      <AppLoader isActive={loading} />
      <AlertModal
        onClose={onCloseAlert}
        onConfirmation={onConfirmationAlert}
        visible={isAlertVisible}
        description={descriptionAlert}
      />
      {selection ? (
        <ByRequestVehicleControlModal
          vehicleControl={selection}
          onClose={onModalClosed}
        />
      ) : null}

      <div
        className={
          "flex-none w-full flex flex-row flex-wrap gap-2 p-2 border-b-2 border-neutral-100 overflow-x-hidden items-center justify-between"
        }
      >
        <span className={"text-lg font-normal text-neutral-600 uppercase"}>
          {formatDate(selectedTime)}
        </span>
        <div className={"flex flex-row flex-wrap items-center gap-2"}>
          <div className={"flex flex-row flex-wrap gap-2 items-center z-0"}>
            <span>Seleccionar fecha</span>
            <DatePicker
              value={selectedTime}
              onChange={(newDate: any) => {
                if (
                  !!newDate &&
                  newDate?.getTime() !== selectedTime.getTime()
                ) {
                  setSelectedTime(newDate);
                  queryNavigate({
                    timestamp: newDate.getTime(),
                  });
                }
              }}
            />
          </div>

          <IconButton
            onClick={requestRefreshEvent}
            icon={<FaIcon icon={faRefresh} />}
          >
            Actualizar
          </IconButton>

          {/* <IconButton
            onClick={requestFilterEvent}
            icon={<FaIcon icon={faFilter} />}
          >
            Filtrar
          </IconButton> */}

          <IconButton
            onClick={requestSearchEvent}
            icon={<FaIcon icon={faSearch} />}
          >
            Buscar
          </IconButton>
        </div>
      </div>

      {filterEvent ? (
        <FormModal
          onCancel={onFilterEventCompleted}
          visible={true}
          inputList={FilterVehicleInputList}
          // disabled={fetchListState?.isLoading()}
          title={"Filtrar Vehiculos"}
          initialValues={{
            order: requestParams.order,
            orderBy: requestParams.orderBy,
          }}
          onOk={(values: any) => {
            updateRequestParams({ ...values });
          }}
        />
      ) : null}

      {/* <SearchVehicleModal 
         onClose={onSearchEventCompleted}
         loading={searchState?.loading}
        onSearch={searchVehicle}
         results={searchResults}
      > */}
      {searchEvent ? (
        <SearchFormModal
          typeSearchModal="VehicleControlSearch"
          onCancel={onSearchEventCompleted}
          visible={true}
          inputList={SearchVehicleInputList}
          // disabled={fetchListState?.isLoading()}
          title={"Buscar Vehiculos"}
          // initialValues={{
          //   filter: "driver",
          // }}
          // onOk={(values: any) => {
          //   updateRequestParams({ ...values });
          // }}
          results={searchResults}
          onSearch={searchVehicle}
          onGenerate={toGenerateExcel}
        />
      ) : null}
      {fetchState?.isLoading() ? (
        <Loader center backdrop size={"sm"} content={"Cargando..."} />
      ) : (
        <div
          className={
            "flex-1 w-full h-full flex justify-center sm:items-center overflow-hidden"
          }
        >
          <div className={"w-full h-full flex flex-col"}>
            {vehicleControlTimestamp ? (
              <div className={"w-full flex-none p-2"}>
                <p>
                  <span className={"text-neutral-400 uppercase"}>
                    Última actualización:{" "}
                  </span>
                  {toRelativeCalendar(vehicleControlTimestamp.timestamp, true)}
                </p>
              </div>
            ) : null}
            <div className={"w-full flex-1 overflow-x-hidden"}>
              <VehicleControlGroupByRequestData
                onClick={onTableItemSelected}
                onPreview={onTablePreviewActionClicked}
                items={vehicleControlList}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
