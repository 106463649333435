import { DateTime, DateTimeUnit } from "luxon";
import { db } from "../../data/database/db";
import { useContext, useState } from "react";
import { toInteger } from "lodash";
import UnityRepository from "../../data/repository/UnityRepository";
import { UserPatrol } from "../../domain/patrol/Patrol";
import { getCalendar } from "../../lib/date";
import { PatrolSupRepository } from "../../data/repository/PatrolSupRepository";
import { UserAuthContext } from "../../ui/context/UserContext";

export type DailyPatrol = {
  date: Date;
  formattedDate: string;
  total: number | undefined;
};

export type PeriodicPatrolWrapper = {
  total: number;
  data: DailyPatrol[];
};

export type ByUnityPatrol = {
  name: string;
  total: number;
};

export type PatrolPeriodicCount = {
  label: string;
  total: number;
};

export function useOverviewPatrolGroupSupViewModel() {
  const [lastWeekPatrol, setLastWeekPatrol] = useState<PeriodicPatrolWrapper>();
  const [selectedPeriodicPatrol, setSelectedPeriodicPatrol] =
    useState<PeriodicPatrolWrapper>();

  const [byUnityPatrol, setByUnityPatrol] = useState<ByUnityPatrol[]>([]);

  const [todayEntries, setTodayEntries] = useState<UserPatrol[]>([]);

  const [patrolPeriodicCount, setPatrolPeriodicCount] = useState<
    PatrolPeriodicCount[]
  >([]);

  const { appUser } = useContext(UserAuthContext);

  async function fetchPatrolPeriodicCount() {
    const result: PatrolPeriodicCount[] = [];
    const today = DateTime.now().setZone("America/Lima");
    const todayCount = await getPeriodicPatrol(today.toJSDate(), "day", "day");
    const yesterdayCount = await getPeriodicPatrol(
      today.minus({ day: 1 }).toJSDate(),
      "day",
      "day"
    );
    const lastWeekCount = await getPeriodicPatrol(
      today.toJSDate(),
      "week",
      "week"
    );
    const lastMonthCount = await getPeriodicPatrol(
      today.toJSDate(),
      "month",
      "month"
    );
    result.push({
      label: "Hoy",
      total: todayCount.total,
    });
    result.push({
      label: "Ayer",
      total: yesterdayCount.total,
    });
    result.push({
      label: "Última semana",
      total: lastWeekCount.total,
    });
    result.push({
      label: "Último mes",
      total: lastMonthCount.total,
    });
    setPatrolPeriodicCount(result);
  }

  async function fetchLastWeekPatrol() {
    const thisWeekPatrol = await getPeriodicPatrol(new Date(), "week", "week");
    setLastWeekPatrol(thisWeekPatrol);
  }

  async function fetchPeriodicPatrol(date: Date) {
    const periodicPatrol = await getPeriodicPatrol(date, "month", "month");
    setSelectedPeriodicPatrol(periodicPatrol);
  }

  async function fetchLastEntries() {
    const today = DateTime.now()
      .setZone("America/Lima")
      .startOf("day")
      .toJSDate();
    const entries = await db.patrolSup
      .where("timestamp")
      .aboveOrEqual(today.getTime())
      .reverse()
      .toArray();
    const task = await Promise.all(
      entries.map(async (entry) => {
        return await PatrolSupRepository.patrolToUserPatrol(entry);
      })
    );
    setTodayEntries(task);
  }

  async function getPeriodicPatrol(
    date: Date,
    fromStartOf: DateTimeUnit,
    toEndOf: DateTimeUnit
  ): Promise<PeriodicPatrolWrapper> {
    const calendar = getCalendar(date, fromStartOf, toEndOf);
    const result = [];
    let totalCount: number = 0;
    for (const date of calendar) {
      const dateTime = DateTime.fromJSDate(date);
      const start = dateTime.startOf("day").toMillis();
      const end = dateTime.endOf("day").toMillis();
      const count = await db.patrolSup
        .where("timestamp")
        .between(start, end, true, true)
        .count();
      totalCount += count;
      result.push({
        date,
        total: toInteger(count),
        formattedDate: dateTime.toFormat("dd MMM"),
      });
    }
    return {
      total: totalCount,
      data: result,
    };
  }

  async function fetchByUnityPatrol() {
    const today = DateTime.now()
      .setZone("America/Lima")
      .startOf("day")
      .toJSDate();
    const unityList = await UnityRepository.getList(false, appUser);
    const entries = await db.patrolSup
      .where("timestamp")
      .aboveOrEqual(today.getTime())
      .toArray();
    const result: Array<ByUnityPatrol> = [];
    if (!!unityList) {
      for (let unity of unityList) {
        const count = entries.filter((it) => it.unityId === unity.id).length;
        result.push({
          name: unity.label,
          total: count,
        });
      }
      setByUnityPatrol(result);
    }
  }

  return {
    fetchPatrolPeriodicCount,
    patrolPeriodicCount,
    fetchLastWeekPatrol,
    fetchPeriodicPatrol,
    selectedPeriodicPatrol,
    lastWeekPatrol,
    fetchLastEntries,
    todayEntries,
    byUnityPatrol,
    fetchByUnityPatrol,
  };
}
