import { TableColumnRelation } from "../../ui/context/TableContext";

export type SupervisionTableColumns = {
  time: string;
  entry: string;
  departure: string;
  liable: string;
  unity: string;
  //   zone: string;
  //   incident: string;
  //   actions: string;
};

export const SupervisionTableSchema: TableColumnRelation<SupervisionTableColumns> =
  {
    large: {
      breakpoints: ["lg", "xl", "2xl"],
      columns: [
        {
          name: "time",
          label: "Fecha Creación",
          weight: 0.1,
        },
        {
          name: "liable",
          label: "Responsable",
          weight: 0.4,
        },
        {
          name: "unity",
          label: "Unidad",
          weight: 0.3,
        },
        {
          name: "entry",
          label: "Entrada",
          weight: 0.1,
        },
        {
          name: "departure",
          label: "Salida",
          weight: 0.1,
        },

        //   {
        //     name: "operator",
        //     label: "Operador",
        //     weight: 0.3,
        //   },
        //   {
        //     name: "unity",
        //     label: "Unidad",
        //     weight: 0.2,
        //   },
        //   {
        //     name: "zone",
        //     label: "Zona",
        //     weight: 0.2,
        //   },
        //   {
        //     name: "incident",
        //     label: "Incidente",
        //     weight: 0.1,
        //   },
        //   {
        //     name: "actions",
        //     label: "Acciones",
        //     weight: 0.1,
        //   },
      ],
    },
  };
