import { PentrackerUser } from "../user/User";
import { AttendanceSupDto } from "../../network/attendance-sup/Attendance";
import { Unity } from "../unity";
import { AccountType } from "../account-type/AccountType";
import { Shift } from "../user/Shift";

export type AttendanceSup = Omit<AttendanceSupDto, "timestamp"> & {
  timestamp: number;
  reference: string;
  ownerPhotoUrl?: string;
};

export type UserAttendance = AttendanceSup & {
  reference: string;
  unity?: Unity;
  owner?: PentrackerUser;
  accountType?: AccountType;
  shift?: Shift;
  //   talkToAgents?: boolean;
};

export type AttendancePair = {
  entry: UserAttendance;
  departure?: UserAttendance | null;
};

export function attendanceDtoAsDomain(
  dto: AttendanceSupDto,
  reference: string
): AttendanceSup {
  return {
    ...dto,
    timestamp: dto.timestamp.toMillis(),
    reference,
  };
}

export function attendanceAsUserAttendance(
  attendance: AttendanceSup,
  user?: PentrackerUser,
  unity?: Unity,
  accountType?: AccountType,
  shift?: Shift
) {
  return {
    ...attendance,
    owner: user,
    unity: unity,
    accountType: accountType,
    shift: shift,
  };
}
