import {
  FC,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
  useCallback,
  FormEvent,
  SetStateAction,
  Dispatch,
} from "react";
import {
  Button,
  Form,
  FormGroupProps,
  FormInstance,
  IconButton,
  Input,
  InputGroup,
  InputNumber,
  InputProps,
  Modal,
  SelectPicker,
  SelectPickerProps,
  DatePicker,
  Checkbox,
} from "rsuite";
import { Search } from "@rsuite/icons";
import { useNavigate } from "react-router-dom";
import { Send } from "@rsuite/icons";
import { isEmpty, keysIn } from "lodash";
import { VehicleSearchListWindow } from "../../dashboard/vehicle-control-group/request/modal/components/search-list";
import { AttendanceSearchListWindow } from "../../dashboard/attendance-group/request/modal/components/search-list";
import {
  UserVehicleControl,
  VehicleControl,
} from "../../../domain/control/Vehicle";
import {
  UserAttendance,
  Attendance,
  AttendancePair,
} from "../../../domain/attendance/Attendance";
import { PatrolSearchListWindow } from "../../dashboard/patrol-group/request/modal/components/search-list";
import { UserPatrol } from "../../../domain/patrol/Patrol";
import { UserVisitControl } from "../../../domain/control/Visit";
import { VisitSearchListWindow } from "../../dashboard/visit-control-group/request/modal/components/search-list";
import { UserGoodsControl } from "../../../domain/control/Goods";
import { GoodsSearchListWindow } from "../../dashboard/goods-control-group/request/modal/components/search-list";

type BasicTypeWithStringProperties = { [key: string]: any };

export type SearchFormModalProps = {
  clear?: boolean | null | undefined;
  title: string;
  onOk?: (values: any, clear?: () => void) => void;
  onCancel?: () => void;
  inputList: InputDataListS<any>;
  disabled?: boolean;
  visible?: boolean;
  initialValues?: any | undefined;
  onValuesChanged?: (values: any) => void;
  okButtonDisabled?: boolean;
  okButtonLoading?: boolean;
  model?: any;
  layout?: "horizontal" | "vertical" | "inline";
  results: UserVehicleControl[] | AttendancePair[] | null;
  typeSearchModal?: string;
  onSearch?: (values: FormModalValues) => void;
  onGenerate?: () => void;
};

export enum FormModalInputTypes {
  number = "number",
  text = "text",
  textarea = "textarea",
  password = "password",
  select = "select",
  checkbox = "checkbox",
  datepicker = "datepicker",
  keyword = "keyword",
  custom = "custom",
}

interface InputData<Type extends BasicTypeWithStringProperties>
  extends FormGroupProps {
  type?: `${FormModalInputTypes}`;
  name: Extract<keyof Type, string>;
  label?: string;
  help?: string;
  hidden?: boolean;
  defaultValue?: any;
}

export interface NumberInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "number";
  min?: number;
  max?: number;
  maxLength?: number;
  placeholder?: string;
  addon?: ReactNode;
  defaultValue?: number;
}

export type InputDataListS<
  Type extends BasicTypeWithStringProperties = BasicTypeWithStringProperties
> = (
  | NumberInputData<Type>
  | TextInputData<Type>
  | PasswordInputData<Type>
  | TextAreaInputData<Type>
  | SelectInputData<Type>
  | CheckBoxInputData<Type>
  | DatePickerInputData<Type>
  | KeyWordInputData<Type>
  | CustomInputData<Type>
)[];

export interface TextInputData<T extends BasicTypeWithStringProperties>
  extends InputData<T> {
  addon?: ReactNode;
  type: "text" | undefined;
  placeholder?: string;
  defaultValue?: string;
}

export interface PasswordInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "password";
  placeholder?: string;
  defaultValue?: string;
}

export interface TextAreaInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "textarea";
  placeholder?: string;
  rows?: number;
  defaultValue?: string;
}

export interface SelectInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "select";
  options: SelectPickerProps<any>["data"];
  placeholder?: string;
  cleanable?: boolean;
  block?: boolean;
  defaultValue?: any;
}

export interface CheckBoxInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "checkbox";
  defaultValue?: boolean;
  checked?: false;
}

export interface DatePickerInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "datepicker";
  defaultValue?: any;
}

export interface KeyWordInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "keyword";
  placeholder?: string;
  // defaultValue?: string;
}

export interface CustomInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "custom";
  provide: FC<CustomInputProps<Type>>;
  defaultValue?: any;
}

type Values<Type extends BasicTypeWithStringProperties> = {
  [key in keyof Type]: any;
};

export type CustomInputProps<Type extends BasicTypeWithStringProperties> = {
  values: Values<Type>;
  initialValues?: FormModalValues;
  updateValue: (key: Extract<keyof Type, string>, newValue: any) => void;
  disabled: boolean;
  name: Extract<keyof Type, string>;
  errorWrapper: ErrorWrapper;
};

type FormModalValues = {
  [key: string]: any;
};

type ErrorWrapper = {
  hasError?: boolean;
  formError?: {
    [key: string]: string;
  };
};

function getDefaults(inputList: InputDataListS) {
  const result: { [key: string]: any } = {};
  inputList.forEach((i) => {
    // @ts-ignore
    result[i.name] = keysIn(i).includes("defaultValue") ? i.defaultValue : null;
  });
  return result;
}

export const SearchFormModal: FC<SearchFormModalProps> = ({
  title,
  typeSearchModal,
  onOk,
  onCancel,
  inputList,
  // disabled,
  visible,
  initialValues,
  clear,
  onValuesChanged,
  okButtonDisabled,
  okButtonLoading,
  model,
  layout,
  results,
  onSearch,
  onGenerate,
}) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const formRef = useRef<FormInstance>(null);
  const ghostButtonRef = useRef<HTMLButtonElement>(null);
  const ghostGenerateButtonRef = useRef<HTMLButtonElement>(null);
  const [values, setValues] = useState<FormModalValues>(getDefaults(inputList));
  const [errorWrapper, setErrorWrapper] = useState<ErrorWrapper>({});
  const [keyword, setKeyword] = useState("");
  const [resultSearchList, setResultSearchList] = useState(results);

  // guard type
  function isVehicleControl(
    data: VehicleControl | AttendancePair
  ): data is VehicleControl {
    return (data as VehicleControl).reference !== undefined;
  }
  function isAttendancePair(
    data: VehicleControl | AttendancePair
  ): data is AttendancePair {
    return (data as AttendancePair).entry !== undefined;
  }
  function isUserPatrol(
    data: VehicleControl | AttendancePair | UserPatrol | AttendancePair
  ): data is UserPatrol {
    return (data as UserPatrol).reference !== undefined;
  }
  function isUserVisitControl(
    data: VehicleControl | AttendancePair | UserPatrol | UserVisitControl
  ): data is UserVisitControl {
    return (data as UserVisitControl).reference !== undefined;
  }
  function isUserGoodsControl(
    data:
      | VehicleControl
      | AttendancePair
      | UserPatrol
      | UserVisitControl
      | UserGoodsControl
  ): data is UserGoodsControl {
    return (data as UserGoodsControl).reference !== undefined;
  }
  /* eslint-disable */
  const onClick = useCallback(
    (
      data: VehicleControl | AttendancePair | UserPatrol | UserVisitControl,
      type: string
    ) => {
      let reference;
      if (type === "VehicleControl" && isVehicleControl(data)) {
        reference = data.reference;
        navigate(
          `/dashboard/vehicle-control?reference=${encodeURIComponent(
            reference
          )}`
        );
      } else if (type === "Attendance" && isAttendancePair(data)) {
        reference = data.entry?.reference;
        navigate(
          `/dashboard/attendance?entry=${encodeURIComponent(reference)}`
        );
      } else if (type === "Patrol" && isUserPatrol(data)) {
        reference = data.reference;
        navigate(
          `/dashboard/patrol?reference=${encodeURIComponent(reference)}`
        );
      } else if (type === "Visit" && isUserVisitControl(data)) {
        reference = data.reference;
        navigate(
          `/dashboard/visit-control?reference=${encodeURIComponent(reference)}`
        );
      } else if (type === "Goods" && isUserGoodsControl(data)) {
        reference = data.reference;
        navigate(
          `/dashboard/goods-control?reference=${encodeURIComponent(reference)}`
        );
      }
    },
    []
  );

  useEffect(() => {
    if (!!initialValues) {
      setValues(initialValues);
      if (onValuesChanged) onValuesChanged(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    if (!!results) {
      setResultSearchList(results);
    }
  }, [results]);

  useEffect(() => {
    if (!!clear && clear) {
      resetFields();
      if (onValuesChanged) onValuesChanged({});
    }
  }, [clear]);

  /* eslint-enable */
  function resetFields() {
    const defaults = getDefaults(inputList);
    setValues(defaults);
    if (onValuesChanged) onValuesChanged(defaults);
  }

  function updateValue(key: string, value: any) {
    if (key === "filter") setResultSearchList(null);
    if (key === "search") setKeyword(value);
    if (key === "searchInAll" && value === true) {
      setIsChecked(true);
      setDisabled(true);
    }
    if (key === "searchInAll" && value === false) {
      setIsChecked(false);
      setDisabled(false);
    }
    setValues((oldValues) => ({ ...oldValues, [key]: value }));
  }

  /* eslint-disable */
  useEffect(() => {
    console.log("values", values);
    if (onValuesChanged) onValuesChanged(values);
  }, [values]);

  /* eslint-enable */

  const handleSubmit = (proceed: any, e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const submitter = (e.nativeEvent as SubmitEvent)
      .submitter as HTMLButtonElement;
    console.log("submitter", submitter.name);
    console.log("values to send", values);

    if (submitter.name === "generate") {
      if (onGenerate) onGenerate();
    } else {
      if (proceed && onSearch) onSearch(values);
    }
  };

  // @ts-ignore
  return (
    <Modal
      size="lg"
      overflow
      open={visible}
      onClose={!!onCancel ? onCancel : undefined}
      title={title}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          ref={formRef}
          model={model as any}
          fluid
          formValue={values}
          onChange={setValues}
          onError={(err: any) => {
            setErrorWrapper({
              hasError: !isEmpty(err),
              formError: err,
            });
          }}
          layout={layout}
          checkTrigger={"change"}
          onSubmit={handleSubmit}
        >
          {inputList?.map((inputData, i) => (
            <RenderInput
              inputData={inputData}
              values={values}
              // values={{}}
              initialValues={initialValues}
              updateValue={updateValue}
              setIsChecked={setIsChecked}
              disabled={disabled ? disabled : false}
              isChecked={isChecked ? isChecked : false}
              key={`input-${String(inputData.name)}-${i}`}
              errorWrapper={errorWrapper}
            />
          ))}

          <button
            ref={ghostButtonRef}
            type={"submit"}
            style={{
              display: "none",
            }}
            name="search"
          ></button>
          <button
            ref={ghostGenerateButtonRef}
            type={"submit"}
            style={{
              display: "none",
            }}
            name="generate"
          ></button>
        </Form>

        <div className="w-full h-96">
          {typeSearchModal === "VehicleControlSearch" ? (
            <VehicleSearchListWindow
              onClick={onClick}
              items={resultSearchList as UserVehicleControl[]}
            />
          ) : typeSearchModal === "AttendanceSearch" ? (
            <AttendanceSearchListWindow
              onClick={onClick}
              items={resultSearchList as AttendancePair[]}
            />
          ) : typeSearchModal === "PatrolSearch" ? (
            <PatrolSearchListWindow
              onClick={onClick}
              items={resultSearchList as UserPatrol[]}
            />
          ) : typeSearchModal === "VisitControlSearch" ? (
            <VisitSearchListWindow
              onClick={onClick}
              items={resultSearchList as UserVisitControl[]}
            />
          ) : typeSearchModal === "GoodsSearch" ? (
            <GoodsSearchListWindow
              onClick={onClick}
              items={resultSearchList as UserGoodsControl[]}
            />
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={!!onCancel ? onCancel : undefined}>Cancelar</Button>
        <IconButton
          loading={okButtonLoading}
          disabled={okButtonDisabled}
          appearance="primary"
          icon={<Send />}
          onClick={() => {
            ghostButtonRef.current?.click();
          }}
        >
          Buscar
        </IconButton>
        <IconButton
          loading={okButtonLoading}
          disabled={okButtonDisabled}
          appearance="primary"
          icon={<Send />}
          onClick={() => {
            ghostGenerateButtonRef.current?.click();
          }}
          color="green"
          name="generate"
        >
          Generar Excel
        </IconButton>
      </Modal.Footer>
    </Modal>
  );
};

const RenderInput: FC<{
  inputData: InputData<BasicTypeWithStringProperties>;
  values: FormModalValues;
  updateValue: (key: string, arg: any) => void;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
  isChecked: boolean;
  initialValues?: FormModalValues;
  errorWrapper: ErrorWrapper;
}> = ({
  inputData,
  values,
  updateValue,
  setIsChecked,
  disabled,
  isChecked,
  initialValues,
  errorWrapper,
}) => {
  const { type, name, label, help } = inputData;
  let Control: ReactNode = null;
  switch (type) {
    case "custom":
      const Renderer: FC<CustomInputProps<BasicTypeWithStringProperties>> = (
        inputData as CustomInputData<BasicTypeWithStringProperties>
      ).provide;
      Control = (
        <Renderer
          values={values as any}
          updateValue={updateValue}
          disabled={disabled}
          name={name}
          initialValues={initialValues}
          errorWrapper={errorWrapper}
        />
      );
      break;
    case "text":
      let data1 = inputData as TextInputData<BasicTypeWithStringProperties>;
      let Input = (
        <Form.Control
          name={name}
          placeholder={data1.placeholder}
          // disabled={disabled}
          value={values[name]}
          onChange={(value: any) => {
            updateValue(name, value);
          }}
        />
      );
      Control = data1.addon ? (
        <InputGroup>
          <InputGroup.Addon>{data1.addon}</InputGroup.Addon>
          {Input}
        </InputGroup>
      ) : (
        Input
      );
      break;
    case "number":
      let data2 = inputData as NumberInputData<BasicTypeWithStringProperties>;
      let NumberInput = (
        <Form.Control
          name={name}
          accepter={InputNumber}
          min={data2.min}
          max={data2.max}
          maxLength={data2.maxLength}
          placeholder={data2.placeholder}
          // disabled={disabled}
          value={values[name]}
          onChange={(value: any) => {
            updateValue(name, value);
          }}
        />
      );
      Control = data2.addon ? (
        <InputGroup>
          <InputGroup.Addon>{data2.addon}</InputGroup.Addon>
          {NumberInput}
        </InputGroup>
      ) : (
        NumberInput
      );
      break;
    case "textarea":
      let data3 = inputData as TextAreaInputData<BasicTypeWithStringProperties>;
      Control = (
        <Form.Control
          name={name}
          accepter={Textarea}
          {...{ rows: data3.rows }}
          // disabled={disabled}
          value={values[name]}
          onChange={(value: any) => {
            updateValue(name, value);
          }}
        />
      );
      break;
    case "password":
      let data4 = inputData as PasswordInputData<BasicTypeWithStringProperties>;
      Control = (
        <Form.Control
          name={name}
          placeholder={data4.placeholder}
          type={"password"}
          autoComplete={"off"}
          // disabled={disabled}
          value={values[name]}
          onChange={(value: any) => {
            updateValue(name, value);
          }}
        />
      );
      break;
    case "select":
      let data5 = inputData as SelectInputData<BasicTypeWithStringProperties>;
      Control = (
        <Form.Control
          name={name}
          accepter={SelectPicker}
          placeholder={data5.placeholder}
          {...{
            data: data5.options,
            cleanable: data5.cleanable,
            block: data5.block,
          }}
          // disabled={disabled}
          value={values[name]}
          onChange={(value: any) => {
            updateValue(name, value);
          }}
        />
      );
      break;
    case "datepicker":
      let data6 =
        inputData as DatePickerInputData<BasicTypeWithStringProperties>;
      Control = (
        <Form.Control
          name={name}
          accepter={DatePicker}
          // {...{
          //   data: data6.options,
          //   cleanable: data6.cleanable,
          //   block: data6.block,
          // }}
          disabled={disabled}
          value={values[name]}
          onChange={(value: any) => {
            updateValue(name, value);
          }}
        />
      );
      break;
    case "checkbox":
      let data7 = inputData as CheckBoxInputData<BasicTypeWithStringProperties>;
      Control = (
        <Checkbox
          name={name}
          checked={isChecked}
          onChange={(
            value: any,
            checked: boolean,
            event: React.ChangeEvent<HTMLInputElement>
          ) => {
            // setIsChecked(checked);
            updateValue(name, checked);
          }}
        />
      );
      break;
    case "keyword":
      let data8 = inputData as KeyWordInputData<BasicTypeWithStringProperties>;
      Control = (
        <Form.Control
          name={name}
          value={values["search"]}
          placeholder={data8.placeholder}
          onChange={(value: any) => {
            updateValue(name, value);
          }}
        />
      );
      break;
  }
  return (
    <Form.Group controlId={`${name}-1`}>
      {label ? <Form.ControlLabel>{label}</Form.ControlLabel> : null}
      {Control}
      {help ? <Form.HelpText>{help}</Form.HelpText> : null}
    </Form.Group>
  );
};

const Textarea = forwardRef((props: InputProps, ref) => (
  <Input {...props} as="textarea" ref={ref as any} />
));
