import React, { FC, useContext, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { ContextHeader } from "../../components/header/secondary";
import { UserSupervisionView } from "./components/view";
import { DashboardHeaderContext } from "../../../hook/header";

import { useStyleContext } from "../../context/StyleContext";
import { UserSupervision } from "../../../domain/supervision/Supervision";

export const SupervisionUnityPage: FC = () => {
  const data = useLoaderData() as UserSupervision;
  /* eslint-disable */
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-gray-100",
      title: "Supervision",
      dark: false,
      subtitle: data.formDataPageMain.controlLiable.value,
      homeAsUpEnabled: true,
    });
  }, []);
  const { stylePage } = useStyleContext();

  /* eslint-enable */
  return (
    <div className={"w-full h-full flex flex-col overflow-hidden px-3"}>
      <ContextHeader
        breadcrumbItems={[
          {
            label: "Supervision",
            path: "/dashboard/supervision-unity-group",
          },
          {
            label: "...",
            active: true,
          },
        ]}
        title={"Registro de Supervision"}
        stylePage={stylePage}
      />
      <div className={"p-3 flex-1 w-full overflow-y-auto"}>
        <UserSupervisionView supervision={data} />
      </div>
    </div>
  );
};
