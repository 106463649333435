import { FC } from "react";
import { CellProps, Table } from "rsuite";
import { getTimeMilliseconds } from "../../../../util/date";
import { toTime } from "../../../../lib/date";
import { AttendancePair } from "../../../../domain/attendance-sup/Attendance";

export const AttendanceGroupSupTableEntryCell: FC<
  CellProps<AttendancePair> 
> = ({ rowData, ...props }) => {
  const {
    entry: { unity, shiftId, unityId, timestamp },
  } = rowData!;

  const firstShiftMillis = unity?.firstShiftLimit
    ? getTimeMilliseconds(unity.firstShiftLimit)
    : undefined;
  const secondShiftMillis = unity?.secondShiftLimit
    ? getTimeMilliseconds(unity.secondShiftLimit)
    : undefined;

  if (shiftId === 0 && firstShiftMillis) {
    const currentEntryMillis = getTimeMilliseconds(timestamp);
    return (
      <Table.Cell
        {...props}
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        <div
          className={`w-full h-full flex flex-wrap justify-center items-center ${
            currentEntryMillis > firstShiftMillis
              ? "bg-red-500 text-white"
              : "bg-green-300"
          }`}
        >
          <span>{toTime(timestamp)}</span>
        </div>
      </Table.Cell>
    );
  } else if (shiftId === 1 && secondShiftMillis) {
    const currentEntryMillis = getTimeMilliseconds(timestamp);
    return (
      <Table.Cell
        {...props}
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        <div
          className={`w-full h-full flex flex-wrap justify-center items-center ${
            currentEntryMillis > secondShiftMillis
              ? "bg-red-500 text-white"
              : "bg-green-300"
          }`}
        >
          <span>{toTime(timestamp)}</span>
        </div>
      </Table.Cell>
    );
  }
  return <Table.Cell {...props}>{toTime(timestamp)}</Table.Cell>;
};
