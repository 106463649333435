import { Outlet } from "react-router-dom";
import {
  FC,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { SignOutModal } from "./components/modal";
import { SideDashboardMenu } from "./components/sidemenu";
import { TableColumnContext } from "../../context/TableContext";
import "react-virtualized/styles.css";
import { DashboardHeader } from "./components/header";
import { DashboardFooter } from "./components/footer";
import { AttendanceGroupLiveDataName } from "../../dashboard/attendance-group/live/components/data";
import { AttendanceGroupSupLiveDataName } from "../../dashboard/attendance-group-sup/live/components/data";
import { AttendanceTableSchema } from "../../../schema/table/AttendanceTable";
import { AttendanceGroupByRequestDataName } from "../../dashboard/attendance-group/request/components/data";

import { AttendanceGroupSupByRequestDataName } from "../../dashboard/attendance-group-sup/request/components/data";

import { IncidentGroupByRequestDataName } from "../../dashboard/incident-group/request/component/data";
import { IncidentTableSchema } from "../../../schema/table/IncidentTable";
import { PatrolGroupLiveDataName } from "../../dashboard/patrol-group/live/components/data";
import { PatrolTableSchema } from "../../../schema/table/PatrolTable";
import { PatrolGroupByRequestDataName } from "../../dashboard/patrol-group/request/component/data";
import { UnityDataName } from "../../dashboard/unitylist/components/data";
import { UnityTableSchema } from "../../../schema/table/UnityTable";
import { UserListDataName } from "../../dashboard/userlist/main/components/data";
import {
  UserTableSchema,
  UserTableSchemaBasic,
} from "../../../schema/table/UserTable";

import { PatrolGroupSupByRequestDataName } from "../../dashboard/patrol-group-sup/request/component/data";
import { PatrolGroupSupLiveDataName } from "../../dashboard/patrol-group-sup/live/components/data";

import { SupervisionTableSchema } from "../../../schema/table/SupervisionTable";

import { UnityAttendanceDataName } from "../../dashboard/unity/attendance/components/data";
import { UnityIncidentDataName } from "../../dashboard/unity/incident/components/data";
import { UnityPatrolDataName } from "../../dashboard/unity/patrol/components/data";
import { UnityUserListDataName } from "../../dashboard/unity/user/components/data";
import { UserIncidentDataName } from "../../dashboard/user/incident/components/data";
import { UserPatrolDataName } from "../../dashboard/user/patrol/components/data";
import { VisitControlGroupByRequestDataName } from "../../dashboard/visit-control-group/request/component/data";
import { VisitControlTableSchema } from "../../../schema/table/VisitControlTable";
import { VehicleControlGroupByRequestDataName } from "../../dashboard/vehicle-control-group/request/component/data";
import { VehicleControlTableSchema } from "../../../schema/table/VehicleControlTable";
import { GoodsControlGroupByRequestDataName } from "../../dashboard/goods-control-group/request/component/data";
import { GoodsControlTableSchema } from "../../../schema/table/GoodsControlTable";
import { UserVisitControlDataName } from "../../dashboard/user/visit-control/components/data";
import { ShiftTableName } from "../../dashboard/operator/shift/components/table";
import { ShiftTableSchema } from "../../../schema/table/ShiftTable";
import { UserVehicleControlDataName } from "../../dashboard/user/vehicle-control/components/data";
import { UserGoodsControlDataName } from "../../dashboard/user/goods-control/components/data";
import { UnityZoneTableName } from "../../dashboard/unity/zone/components/table";
import { ZoneTableSchema } from "../../../schema/table/ZoneTable";
import { UnityVisitControlDataName } from "../../dashboard/unity/visit-control/components/data";
import { UnityVehicleControlDataName } from "../../dashboard/unity/vehicle-control/components/data";
import { UnityGoodsControlDataName } from "../../dashboard/unity/goods-control/components/data";
import FirebaseMessaging from "../../../data/source/FirebaseMessaging";
import { useDashboardPageViewModel } from "../../../viewmodel/DashboardPage";
import { AppLoader } from "../../components/loading/LoadingOverlay";
import { Unsubscribe } from "firebase/firestore";
import { MessagePayload } from "firebase/messaging";
import { AppNotificationDao } from "../../../data/database/dao/AppNotification";
import { AppNotification } from "../../../domain/app/AppNotification";
import notificationSound from "../../../resources/sound_notification.wav";

import { useStyleContext } from "../../context/StyleContext";
import { UserAuthContext } from "../../context/UserContext";
import { SupervisionUnityGroupByRequestDataName } from "../../dashboard/supervision-unity-group/request/component/data";
import { SupervisionUnityGroupLiveDataName } from "../../dashboard/supervision-unity-group/live/components/data";

const DashboardPage: FC = () => {
  const { stylePage } = useStyleContext();
  const { appUser } = useContext(UserAuthContext);

  const tableContext = useContext(TableColumnContext)!;
  const {
    checkPermissions,
    onPermissionsStateReceived,
    permissionsState,
    uploadTokenState,
    onUploadTokenStateReceived,
    updateToken,
  } = useDashboardPageViewModel();
  const mainContainerRef = useRef<HTMLDivElement>(
    null
  ) as MutableRefObject<HTMLDivElement>;
  const listenTurnOffRef = useRef<Unsubscribe>(
    null
  ) as MutableRefObject<Unsubscribe>;

  const onMessage = useCallback(async (payload: MessagePayload) => {
    const { notification, data, messageId, fcmOptions } = payload;
    const { link } = fcmOptions || {};
    const notificationId = messageId?.length <= 0 ? `${Date.now()}` : messageId;
    if (!!notification) {
      const { title, body } = notification;
      window.toast.info(`Nueva notificación: ${title}`, {
        autoClose: false,
        toastId: notificationId,
        theme: "dark",
      });
      // await new Audio("sound_notification.wav").play()
      await AppNotificationDao.putNotification({
        title,
        body,
        date: Date.now(),
        link,
      } as AppNotification);
      try {
        const audio = new Audio(notificationSound);
        audio.load();
        await audio.play();
      } catch (e) {
        console.log(e);
      }
    } else if (!!data) {
    }
  }, []);

  useEffect(() => {
    tableContext.addSchema(
      AttendanceGroupLiveDataName,
      1,
      AttendanceTableSchema
    );
    tableContext.addSchema(
      AttendanceGroupByRequestDataName,
      1,
      AttendanceTableSchema
    );

    tableContext.addSchema(
      AttendanceGroupSupLiveDataName,
      1,
      AttendanceTableSchema
    );

    tableContext.addSchema(
      AttendanceGroupSupByRequestDataName,
      1,
      AttendanceTableSchema
    );

    tableContext.addSchema(
      IncidentGroupByRequestDataName,
      1,
      IncidentTableSchema
    );

    tableContext.addSchema(
      PatrolGroupSupByRequestDataName,
      1,
      PatrolTableSchema
    );

    tableContext.addSchema(
      SupervisionUnityGroupByRequestDataName,
      1,
      SupervisionTableSchema
    );

    tableContext.addSchema(
      SupervisionUnityGroupLiveDataName,
      1,
      SupervisionTableSchema
    );

    tableContext.addSchema(PatrolGroupSupLiveDataName, 1, PatrolTableSchema);

    tableContext.addSchema(PatrolGroupLiveDataName, 1, PatrolTableSchema);
    tableContext.addSchema(PatrolGroupByRequestDataName, 1, PatrolTableSchema);
    tableContext.addSchema(UnityDataName, 1, UnityTableSchema);
    tableContext.addSchema(UserListDataName, 1, UserTableSchema);
    tableContext.addSchema(UnityAttendanceDataName, 1, AttendanceTableSchema);
    tableContext.addSchema(UnityIncidentDataName, 1, IncidentTableSchema);
    tableContext.addSchema(UnityPatrolDataName, 1, PatrolTableSchema);
    tableContext.addSchema(UnityUserListDataName, 2, UserTableSchemaBasic);
    tableContext.addSchema(UserIncidentDataName, 1, IncidentTableSchema);
    tableContext.addSchema(UserPatrolDataName, 1, PatrolTableSchema);
    tableContext.addSchema(
      VisitControlGroupByRequestDataName,
      1,
      VisitControlTableSchema
    );
    tableContext.addSchema(
      VehicleControlGroupByRequestDataName,
      1,
      VehicleControlTableSchema
    );
    tableContext.addSchema(
      GoodsControlGroupByRequestDataName,
      1,
      GoodsControlTableSchema
    );
    tableContext.addSchema(
      UserVisitControlDataName,
      1,
      VisitControlTableSchema
    );
    tableContext.addSchema(ShiftTableName, 1, ShiftTableSchema);
    tableContext.addSchema(
      UserVehicleControlDataName,
      1,
      VehicleControlTableSchema
    );
    tableContext.addSchema(
      UserGoodsControlDataName,
      1,
      GoodsControlTableSchema
    );
    tableContext.addSchema(UnityZoneTableName, 1, ZoneTableSchema);
    tableContext.addSchema(
      UnityVisitControlDataName,
      1,
      VisitControlTableSchema
    );
    tableContext.addSchema(
      UnityVehicleControlDataName,
      1,
      VehicleControlTableSchema
    );
    tableContext.addSchema(
      UnityGoodsControlDataName,
      1,
      GoodsControlTableSchema
    );
  }, [tableContext]);

  useEffect(() => {
    void checkPermissions();
    return () => {
      if (listenTurnOffRef.current) {
        listenTurnOffRef.current();
      }
    };
  }, []);

  useEffect(() => {
    if (!!permissionsState && !permissionsState.isLoading()) {
      if (permissionsState.isSuccess()) {
        if (permissionsState.data!) {
          void updateToken();
        } else {
          window.toast.info("No se activon las notificaciones.");
        }
      } else {
        window.toast.error("No se activon las notificaciones.");
      }
      onPermissionsStateReceived();
    }
  }, [permissionsState]);

  useEffect(() => {
    if (!!uploadTokenState && !uploadTokenState.isLoading()) {
      if (uploadTokenState.isSuccess()) {
        if (listenTurnOffRef.current) {
          listenTurnOffRef.current();
          listenTurnOffRef.current =
            FirebaseMessaging.listenMessages(onMessage);
        } else {
          listenTurnOffRef.current =
            FirebaseMessaging.listenMessages(onMessage);
        }
      } else {
        window.toast.error("Ocurrió un error activando las notificaciones..");
      }
      onUploadTokenStateReceived();
    }
  }, [uploadTokenState]);

  return (
    <div className={"w-screen h-screen overflow-hidden flex flex-row relative"}>
      <AppLoader
        isActive={
          permissionsState?.isLoading() || uploadTokenState?.isLoading()
        }
      />
      <SideDashboardMenu stylePage={stylePage} />
      <div className="w-full h-full overflow-hidden">
        <div
          className="h-full w-full overflow-y-auto overflow-x-hidden relative scrollbar-hide md:scrollbar-default bg-neutral-100"
          ref={mainContainerRef}
        >
          <div className={"main-app-header-top-sentinel"} />
          <DashboardHeader
            containerRef={mainContainerRef}
            stylePage={stylePage}
          />
          <div
            className={
              "w-full min-h-full flex flex-col overflow-y-auto flex-nowrap"
            }
          >
            <Outlet />
            <div className={"flex-1"}></div>

            <DashboardFooter stylePage={stylePage} />
          </div>
        </div>
      </div>
      <SignOutModal />
    </div>
  );
};

export default DashboardPage;
