import React, { FC, useCallback } from "react";
import { EmptyState } from "../../../components/state/empty";
import { useDataPager } from "../../../../hook/pager";
import { Pagination } from "rsuite";
import { UserGoodsControl } from "../../../../domain/control/Goods";
import { GoodsControlRow } from "./row";

type GoodsControlListProps = {
  goodsControlList?: UserGoodsControl[];
  onSelect: (item: UserGoodsControl) => void;
  name?: string;
};

export const GoodsControlList: FC<GoodsControlListProps> = ({
  goodsControlList,
  onSelect,
  name,
}) => {
  /* eslint-disable */

  const { limits, changeLimit, limit, page, partialData, changePage } =
    useDataPager<UserGoodsControl>({
      id: `goods_list_${name}`,
      items: goodsControlList,
    });

  const onSelectRow = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      if (!partialData) return;
      const index = event.currentTarget.dataset["index"] as unknown as number;
      const item = partialData[index];
      if (item) {
        onSelect(item);
      }
    },
    [partialData]
  );

  /* eslint-enable */

  return goodsControlList && goodsControlList.length > 0 ? ( //@ts-ignore
    <div className={"w-full h-auto"}>
      {partialData?.map((visit, i) => (
        <GoodsControlRow
          index={i}
          style={{}}
          key={`visit-row-${visit.reference}`}
          data={{
            userGoodsControlList: partialData,
            onSelect: onSelectRow,
          }}
        />
      ))}
      <div className={"py-4 flex flex-col gap-4"}>
        <span className={"text-md"}>
          Mostrando {partialData?.length} elementos / Total{" "}
          {goodsControlList.length}
        </span>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["limit", "pager"]}
          total={goodsControlList.length}
          limitOptions={limits}
          limit={limit}
          activePage={page}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>
    </div>
  ) : (
    <EmptyState
      title={"No hay registros de control de bienes"}
      message={
        "No se encontraron reportes de control de bienes para la fecha seleccionada."
      }
    />
  );
};
