import React, { FC, useContext } from "react";
import { ContextHeader } from "../../../components/header/secondary";
import { faEdit, faRefresh, faTrash } from "@fortawesome/free-solid-svg-icons";
import { PentrackerUser } from "../../../../domain/user/User";
import { UserAuthContext } from "../../../context/UserContext";
import { UserRoles } from "../../../../domain/user/Role";

type OnClick = () => void;

type Props = {
  openUser: PentrackerUser;
  onEditClick: OnClick;
  onDeleteClick: OnClick;
  onRefreshClick: OnClick;
  stylePage?: any;
};

export const UserPageMainHeader: FC<Props> = ({
  onDeleteClick,
  onEditClick,
  onRefreshClick,
  openUser,
  stylePage,
}) => {
  const { appUser } = useContext(UserAuthContext);
  let hideEditButton = true;
  if (appUser.isMainAdmin()) {
    hideEditButton = false;
  } else if (appUser.uid() === openUser.uid) {
    hideEditButton = false;
  } else if (
    appUser.isManager() &&
    openUser.roleId &&
    UserRoles.operator.includes(openUser.roleId)
  ) {
    hideEditButton = false;
  }
  return (
    <ContextHeader
      breadcrumbItems={[
        {
          label: "Usuarios",
          path: "/dashboard/users",
        },
        {
          label: openUser?.displayName
            ? openUser.displayName
            : openUser?.uid || "unknown",
          active: true,
        },
      ]}
      title={openUser?.displayName || "Cargando..."}
      actionsSchema={[
        {
          id: "refresh",
          label: "Actualizar",
          onClick: onRefreshClick,
          icon: faRefresh,
        },
        {
          id: "edit",
          label: "Editar",
          onClick: onEditClick,
          icon: faEdit,
          hide: !appUser.isAdmin(),
        },
        {
          id: "delete",
          label: "Eliminar",
          onClick: onDeleteClick,
          icon: faTrash,
          hide: !appUser.isAdmin(),
        },
      ]}
      stylePage={stylePage}
    />
  );
};
