import { useContext, useState } from "react";
import { Response } from "../../domain/app/Response";
import { PatrolSupRepository } from "../../data/repository/PatrolSupRepository";
import { UserPatrol } from "../../domain/patrol/Patrol";
import { AppTimestamp } from "../../domain/app/Timestamp";
import { UserAuthContext } from "../../ui/context/UserContext";

export function useByRequestPatrolGroupSupViewModel() {
  const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
  const [userPatrolList, setUserPatrolList] = useState<
    UserPatrol[] | undefined
  >();
  const [userPatrolTimestamp, setUserPatrolTimestamp] = useState<
    AppTimestamp | undefined
  >();
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
  const [selectedItem, setSelectedItem] = useState<UserPatrol | null>(null);
  const { appUser } = useContext(UserAuthContext);

  async function fetchUserPatrolList(
    timestamp: number,
    forceRefresh: boolean = false
  ) {
    if (fetchState?.isLoading()) return;
    setFetchState(Response.loading());
    try {
      const result = await PatrolSupRepository.getGlobal(
        timestamp,
        forceRefresh,
        appUser
      );
      setUserPatrolList(result?.patrolList);
      setUserPatrolTimestamp(result?.timestamp);
      setFetchState(Response.success(true));
    } catch (e: any) {
      setFetchState(Response.failure(e));
    }
  }

  function onFetchStateReceived() {
    setFetchState(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  function requestSelectItem(item: UserPatrol) {
    setSelectedItem(item);
  }

  function onSelectItemCompleted() {
    setSelectedItem(null);
  }

  return {
    fetchState,
    onFetchStateReceived,
    userPatrolList,
    userPatrolTimestamp,
    fetchUserPatrolList,
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
    requestSelectItem,
    onSelectItemCompleted,
    selectedItem,
  };
}
