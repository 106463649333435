import React, { FC, useCallback } from "react";

import { FixedSizeList as List } from "react-window";
import { AutoSizer, Size } from "react-virtualized";
import { PatrolSearchRow } from "./row";
import { EmptyState } from "../../../../../../components/state/empty";
import { faList, faSearch } from "@fortawesome/free-solid-svg-icons";
import { UserPatrol } from "../../../../../../../domain/patrol/Patrol";

export type PatrolSearchListProps = {
  items: UserPatrol[] | null;
  onClick: (vehicle: UserPatrol, type: string) => void;
};

export type PatrolSearchListRowProps = {
  items: UserPatrol[];
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

export const PatrolSearchListWindow: FC<PatrolSearchListProps> = ({
  items,
  onClick,
}) => {
  /* eslint-disable */
  const onRowClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (!items) return;
      const index = e.currentTarget.dataset.index;
      if (index) {
        onClick(items[index as unknown as number], "Patrol");
      }
    },
    [items]
  );

  /* eslint-enable */

  return items === null ? (
    <EmptyState
      icon={faSearch}
      title={"Buscar Ronda"}
      message={"Ingrese un término de búsqueda."}
    />
  ) : items.length > 0 ? (
    //@ts-ignore
    <AutoSizer>
      {(props: Size) => (
        <List
          height={props.height}
          itemCount={items?.length || 0}
          itemSize={200}
          width={props.width}
          itemData={{
            items,
            onClick: onRowClick,
          }}
        >
          {PatrolSearchRow}
        </List>
      )}
    </AutoSizer>
  ) : (
    <EmptyState
      icon={faList}
      title={"Sin resultados"}
      message={`No hay rondas que coincidan con la búsqueda.`}
    />
  );
};
