import { UserListQueryRequest } from "../../../../../network/user/User";
import { InputDataList } from "../../../../components/modal/FormModal";

const OrderByOptions: {
  value: `${UserListQueryRequest["orderBy"]}`;
  label: string;
}[] = [
  {
    value: "email",
    label: "Correo Electrónico",
  },
  {
    value: "displayName",
    label: "Nombre",
  },
  {
    value: "creationTime",
    label: "Fecha de Creación",
  },
  {
    value: "lastSignInTime",
    label: "Ult. Sesión",
  },
  {
    value: "unityId",
    label: "Unidad",
  },
];

const OrderOptions: {
  value: `${UserListQueryRequest["order"]}`;
  label: string;
}[] = [
  {
    value: "asc",
    label: "Ascendente",
  },
  {
    value: "desc",
    label: "Descendente",
  },
];

export const FilterUserInputList: InputDataList = [
  {
    name: "orderBy",
    type: "select",
    label: "Ordenar por",
    options: OrderByOptions,
    cleanable: false,
  },
  {
    name: "order",
    type: "select",
    label: "Orden",
    options: OrderOptions,
    cleanable: false,
  },
];
