import { FC, useCallback, useEffect, useState } from "react";
import { formatDate } from "../../../../util/date";
import { DatePicker, IconButton, Loader } from "rsuite";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { toRelativeCalendar } from "../../../../lib/date";
import { PentrackerUser } from "../../../../domain/user/User";
import { useUserLocationViewModel } from "../../../../viewmodel/user/UserLocation";
import { LocationTableView } from "./components/table";
import { UserLocation } from "../../../../domain/location/UserLocation";

type Props = {
  user: PentrackerUser;
};

export const UserLocationTabContent: FC<Props> = ({ user }) => {
  const [selectedTime, setSelectedTime] = useState(new Date());
  const {
    fetchState,
    fetchUserLocationList,
    onFetchStateReceived,
    userLocationList,
    userLocationTimestamp,
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
  } = useUserLocationViewModel(user);

  /* eslint-disable */
  const onTableOpenWithMapsActionSelected = useCallback(
    (item: UserLocation) => {
      const url = `https://maps.google.com/?q=${item.latitude},${item.longitude}`;
      window.open(
        url,
        "_blank",
        "location=yes,height=570,width=800,scrollbars=yes,status=yes"
      );
    },
    []
  );

  const onTableOpenActionSelected = useCallback((_: UserLocation) => {}, []);

  useEffect(() => {
    void fetchUserLocationList(selectedTime.getTime());
  }, [selectedTime]);

  useEffect(() => {
    if (fetchState && !fetchState.isLoading()) {
      if (fetchState.isFailed()) {
        window.toast.error(
          "Ocurrió un error al obtener la lista de ubicaciones del usuario."
        );
        console.log(fetchState?.error);
      }
      onFetchStateReceived();
    }
  }, [fetchState]);

  useEffect(() => {
    if (refreshEvent) {
      void fetchUserLocationList(selectedTime.getTime(), true);
      onRefreshEventCompleted();
    }
  }, [refreshEvent]);
  /* eslint-enable */
  return (
    <div className={"relative w-full overflow-x-hidden overflow-y-auto"}>
      <div
        className={
          "w-full flex flex-row flex-wrap gap-2 p-2 border-b-2 border-neutral-100 overflow-x-hidden items-center justify-between"
        }
      >
        <span className={"text-lg font-normal text-neutral-600 uppercase"}>
          {formatDate(selectedTime)}
        </span>
        <div className={"flex flex-row flex-wrap items-center gap-2"}>
          <div className={"flex flex-row flex-wrap gap-2 items-center z-0"}>
            <span>Seleccionar fecha</span>
            <DatePicker
              size={"sm"}
              appearance={"default"}
              value={selectedTime}
              onChange={(newDate) => {
                if (!!newDate && newDate?.getTime() !== selectedTime.getTime())
                  setSelectedTime(newDate);
              }}
            />
          </div>
          <IconButton
            size={"sm"}
            onClick={requestRefreshEvent}
            icon={<FaIcon icon={faRefresh} />}
            appearance={"ghost"}
          >
            Actualizar
          </IconButton>
        </div>
      </div>
      {fetchState?.isLoading() ? (
        <Loader center backdrop size={"sm"} content={"Cargando..."} />
      ) : (
        <div className={"w-full"}>
          <div className={"w-full h-full flex flex-col"}>
            {userLocationTimestamp ? (
              <div className={"w-full flex-none p-2"}>
                <p>
                  <span className={"text-neutral-400 uppercase"}>
                    Última actualización:{" "}
                  </span>
                  {toRelativeCalendar(userLocationTimestamp.timestamp, true)}
                </p>
              </div>
            ) : null}
            <LocationTableView
              userLocationList={userLocationList}
              onOpenWithMapsActionClicked={onTableOpenWithMapsActionSelected}
              onOpenActionClicked={onTableOpenActionSelected}
            />
          </div>
        </div>
      )}
    </div>
  );
};
