import React, { FC, useCallback } from "react";

import { FixedSizeList as List } from "react-window";
import { AutoSizer, Size } from "react-virtualized";
import { VisitSearchRow } from "./row";
import { EmptyState } from "../../../../../../components/state/empty";
import { faList, faSearch } from "@fortawesome/free-solid-svg-icons";
import { UserVisitControl } from "../../../../../../../domain/control/Visit";

export type VisitSearchListProps = {
  items: UserVisitControl[] | null;
  onClick: (visit: UserVisitControl, type: string) => void;
};

export type VisitSearchListRowProps = {
  items: UserVisitControl[];
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

export const VisitSearchListWindow: FC<VisitSearchListProps> = ({
  items,
  onClick,
}) => {
  /* eslint-disable */
  const onRowClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (!items) return;
      const index = e.currentTarget.dataset.index;
      if (index) {
        onClick(items[index as unknown as number], "Visit");
      }
    },
    [items]
  );

  /* eslint-enable */

  return items === null ? (
    <EmptyState
      icon={faSearch}
      title={"Buscar Visita"}
      message={"Ingrese un término de búsqueda."}
    />
  ) : items.length > 0 ? (
    //@ts-ignore
    <AutoSizer>
      {(props: Size) => (
        <List
          height={props.height}
          itemCount={items?.length || 0}
          itemSize={200}
          width={props.width}
          itemData={{
            items,
            onClick: onRowClick,
          }}
        >
          {VisitSearchRow}
        </List>
      )}
    </AutoSizer>
  ) : (
    <EmptyState
      icon={faList}
      title={"Sin resultados"}
      message={`No hay visitas que coincidan con la búsqueda.`}
    />
  );
};
