import React, {
  CSSProperties,
  FC,
  Fragment,
  SyntheticEvent,
  useCallback,
  useContext,
  useRef,
} from "react";
import { Pagination, Table } from "rsuite";
import { PentrackerUser, UserWrapper } from "../../../../domain/user/User";
import { TableInstance } from "rsuite/Table";
import { useNavigate } from "react-router-dom";
import { TableHeaderStyleSecondary } from "../../../components/table/style";
import { TableColumnContext } from "../../../context/TableContext";
import { useTableSize } from "../../../../hook/width";
import { UserTableRoleCell } from "./role";
import { UserTableNameCell } from "./name";
import { EmptyState } from "../../../components/state/empty";
import { UserTableUnityCell } from "./unity";
import { CheckHeaderCell } from "../../../components/table/checkheader";
import { UserAuthContext } from "../../../context/UserContext";
import { UserTableUidCell } from "./uid";
import { UserTableTimestampCell } from "./timestamp";
import { CheckCell } from "../../../components/table/checkcell";
import { useDataPager } from "../../../../hook/pager";
import { UserTableColumns } from "../../../../schema/table/UserTable";

const { Column, HeaderCell } = Table;

type Props = {
  items?: PentrackerUser[];
  selectedUsers?: string[];
  loading?: boolean;
  onDeleteClick?: (item: PentrackerUser) => void;
  onEditClick?: (item: PentrackerUser) => void;
  onClick: (user: PentrackerUser) => void;
  name: string;
  onItemSelectionSwitch?: (uid: string) => void;
  onSelectAllSwitch?: () => void;
};

const Actions = {
  edit: "edit",
  delete: "delete",
  navigateToUnity: "navigate",
  open: "open",
};
export const UserTableName = "user_table";

export const UserTable_CurrentUserCellStyle: CSSProperties = {
  backgroundColor: "#dcfce7",
};
export const UserTable: FC<Props> = ({
  items,
  loading,
  onDeleteClick,
  onEditClick,
  onClick,
  name = UserTableName
}) => {
  const actionRef = useRef<keyof typeof Actions>();
  const ref = useRef<TableInstance<any, any>>(null);
  const navigate = useNavigate();
  const tableContext = useContext(TableColumnContext)!;
  const currentTablePreferences =
    tableContext.getTablePreferences<UserTableColumns>(name);
  const authContext = useContext(UserAuthContext);
  const { partialData, changePage, page, changeLimit, limits, limit } =
    useDataPager({
      id: `user_table_${name}`,
      items,
    });

  function onTableRowClick(user: PentrackerUser) {
    if (actionRef.current === Actions.edit) {
      if (onEditClick) onEditClick(user);
    } else if (actionRef.current === Actions.delete) {
      if (onDeleteClick) onDeleteClick(user);
    } else if (actionRef.current === Actions.navigateToUnity) {
      navigate(`/dashboard/unity/${user.unityId}`);
    } else {
      onClick(user);
    }

    actionRef.current = undefined;
  }

  const tableSize = useTableSize(ref);

  return (
    <Fragment>
      <Table
        className={"overflow-hidden rounded-xl shadow"}
        loading={loading}
        data={partialData}
        ref={ref}
        onRowClick={onTableRowClick as any}
        renderEmpty={() => (
          <EmptyState
            title={"Sin usuarios"}
            message={"Aún no hay usuarios en Pentracker"}
          />
        )}
        cellBordered
        autoHeight
      >
        {currentTablePreferences.map((tableColumn) => {
          switch (tableColumn.name) {
            case "role":
              return (
                <Column
                  verticalAlign={"middle"}
                  align={"left"}
                  key={tableColumn.name}
                  width={tableSize.calculateWeight(tableColumn.weight)}
                  resizable
                  onResize={(columnSize) => {
                    if (columnSize)
                      tableContext.updateTableColumnWeight(
                        name,
                        tableColumn.name,
                        tableSize.sizeToWeight(columnSize)
                      );
                  }}
                >
                  <HeaderCell style={TableHeaderStyleSecondary}>
                    Cargo
                  </HeaderCell>
                  <UserTableRoleCell currentUid={authContext.appUser.uid()} />
                </Column>
              );
            case "uid":
              return (
                <Column
                  resizable
                  key={tableColumn.name}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(tableColumn.weight)}
                  onResize={(columnSize) => {
                    if (columnSize)
                      tableContext.updateTableColumnWeight(
                        name,
                        tableColumn.name,
                        tableSize.sizeToWeight(columnSize)
                      );
                  }}
                >
                  <HeaderCell style={TableHeaderStyleSecondary}>ID</HeaderCell>
                  <UserTableUidCell currentUid={authContext.appUser.uid()} />
                </Column>
              );
            case "displayName":
              return (
                <Column
                  resizable
                  key={tableColumn.name}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(tableColumn.weight)}
                  onResize={(columnSize) => {
                    if (columnSize)
                      tableContext.updateTableColumnWeight(
                        name,
                        tableColumn.name,
                        tableSize.sizeToWeight(columnSize)
                      );
                  }}
                >
                  <HeaderCell style={TableHeaderStyleSecondary}>
                    Usuario
                  </HeaderCell>
                  <UserTableNameCell currentUid={authContext.appUser.uid()} />
                </Column>
              );
            case "unity":
              return (
                <Column
                  resizable
                  key={tableColumn.name}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(tableColumn.weight)}
                  onResize={(columnSize) => {
                    if (columnSize)
                      tableContext.updateTableColumnWeight(
                        name,
                        tableColumn.name,
                        tableSize.sizeToWeight(columnSize)
                      );
                  }}
                >
                  <HeaderCell style={TableHeaderStyleSecondary}>
                    Unidad
                  </HeaderCell>
                  <UserTableUnityCell currentUid={authContext.appUser.uid()} />
                </Column>
              );
            case "lastSession":
              return (
                <Column
                  verticalAlign={"middle"}
                  align={"center"}
                  resizable
                  key={tableColumn.name}
                  width={tableSize.calculateWeight(tableColumn.weight)}
                  onResize={(columnSize) => {
                    if (columnSize)
                      tableContext.updateTableColumnWeight(
                        name,
                        tableColumn.name,
                        tableSize.sizeToWeight(columnSize)
                      );
                  }}
                >
                  <HeaderCell style={TableHeaderStyleSecondary}>
                    Ult. Sesión
                  </HeaderCell>
                  <UserTableTimestampCell
                    currentUid={authContext.appUser.uid()}
                    dataKey={"lastSignInTime"}
                  />
                </Column>
              );
            case "creationTime":
              return (
                <Column
                  resizable
                  verticalAlign={"middle"}
                  align={"center"}
                  key={tableColumn.name}
                  width={tableSize.calculateWeight(tableColumn.weight)}
                  onResize={(columnSize) => {
                    if (columnSize)
                      tableContext.updateTableColumnWeight(
                        name,
                        tableColumn.name,
                        tableSize.sizeToWeight(columnSize)
                      );
                  }}
                >
                  <HeaderCell style={TableHeaderStyleSecondary}>
                    Añadido
                  </HeaderCell>
                  <UserTableTimestampCell
                    currentUid={authContext.appUser.uid()}
                    dataKey={"creationTime"}
                  />
                </Column>
              );
            default:
              return null;
          }
        })}
      </Table>

      {items ? (
        <div className={"py-4 flex flex-col gap-4"}>
          <span className={"text-md"}>
            Mostrando {partialData?.length} elementos / Total {items.length}
          </span>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["limit", "|", "pager", "skip"]}
            total={items.length}
            limitOptions={limits}
            limit={limit}
            activePage={page}
            onChangePage={changePage}
            onChangeLimit={changeLimit}
          />
        </div>
      ) : null}
    </Fragment>
  );
};
