import { FC } from "react";
import { Modal } from "rsuite";
import { GoodsControlView } from "../../../goods-control/components/view";
import { UserGoodsControl } from "../../../../../domain/control/Goods";

export const ByRequestGoodsModal: FC<{
  onClose: () => void;
  userGoodsControl: UserGoodsControl;
}> = ({ onClose, userGoodsControl }) => {
  return (
    <Modal open={true} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Bienes y Equipos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoodsControlView goodsControl={userGoodsControl} />
      </Modal.Body>
    </Modal>
  );
};
