import { FC, useContext } from "react";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { PentrackerUser, UserWrapper } from "../../../../../domain/user/User";
import { Unity } from "../../../../../domain/unity";
import { UserList } from "../../../data-display/user-list";
import { UserTable } from "../../../data-display/user-table";

type Props = {
  items?: PentrackerUser[];
  onClick: (user: PentrackerUser) => void;
  unity: Unity;
};

export const UnityUserListDataName = "UnityUserListData";

export const UnityUserListData: FC<Props> = ({ items, onClick, unity }) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <UserList name={UnityUserListDataName} onClick={onClick} items={items} />
    );
  } else {
    return (
      <UserTable
        name={UnityUserListDataName}
        onClick={onClick}
        items={items}
      />
    );
  }
};
