import { AttendancePair } from "../../../../../domain/attendance-sup/Attendance";
import { FC, useContext } from "react";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { AttendanceList } from "../../../data-display/attendance-list";
import { AttendanceSupTable } from "../../../data-display/attendance-sup-table";

type Props = {
  items?: AttendancePair[];
  onPreview: (item: AttendancePair) => void;
  onClick: (user: AttendancePair) => void;
};

export const AttendanceGroupSupLiveDataName = "AttendanceGroupSupLiveData";

export const AttendanceGroupLiveData: FC<Props> = ({
  items,
  onPreview,
  onClick,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <AttendanceList
        onSelect={onClick}
        attendanceList={items}
        onPreviewClicked={onPreview}
        name={AttendanceGroupSupLiveDataName}
      />
    );
  } else {
    return (
      <AttendanceSupTable
        userAttendanceList={items}
        onSelect={onClick}
        onPreviewClicked={onPreview}
        name={AttendanceGroupSupLiveDataName}
      />
    );
  }
};
