import { useState } from "react";
import { Response } from "../../domain/app/Response";
import { NewUser, PentrackerUser } from "../../domain/user/User";
import UserRepository from "../../data/repository/UserRepository";
import { useLoaderData } from "react-router-dom";
import { DeleteUser } from "../../domain/user";

export function useUserViewModel() {
  const initialUser = useLoaderData() as PentrackerUser;
  const [user, setUser] = useState<PentrackerUser>(initialUser);
  const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
  const [editEvent, setEditEvent] = useState<boolean | null>(null);
  const [editState, setEditState] = useState<Response<boolean> | null>(null);
  const [deleteEvent, setDeleteEvent] = useState<boolean | null>(null);
  const [deleteState, setDeleteState] = useState<Response<boolean> | null>();

  async function fetchUser() {
    if (fetchState?.loading) return;
    setFetchState(Response.loading());
    try {
      const nUser = await UserRepository.getUser(initialUser.uid, true);
      setUser(nUser!);
      setFetchState(Response.success(true));
    } catch (e: any) {
      setFetchState(Response.failure(e));
    }
  }

  function onFetchStateReceived() {
    setFetchState(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  function requestEditEvent() {
    setEditEvent(true);
  }

  async function editUser(newData: Partial<NewUser>) {
    if (editState?.loading) return;
    try {
      setEditState(Response.loading());
      await UserRepository.editUser(initialUser.uid, newData);
      setEditState(Response.success(true));
      setRefreshEvent(true);
    } catch (e: any) {
      setEditState(Response.failure(e));
    }
  }

  function onEditStateReceived() {
    setEditState(null);
  }

  function onEditEventCompleted() {
    setEditEvent(null);
  }

  function requestDeleteEvent() {
    setDeleteEvent(true);
  }

  async function deleteUser(data: DeleteUser) {
    if (deleteState?.loading) return;
    try {
      setDeleteState(Response.loading());
      await UserRepository.deleteUser({
        uid: initialUser.uid,
        reason: data.reason,
      });

      setDeleteState(Response.success(true));
    } catch (e: any) {
      setDeleteState(Response.failure(e));
    }
  }

  function onDeleteStateReceived() {
    setDeleteState(null);
  }

  function onDeleteEventCompleted() {
    setDeleteEvent(null);
  }

  const loadingObserver =
    fetchState?.loading || editState?.loading || deleteState?.loading;

  return {
    fetchState,
    refreshEvent,
    editEvent,
    editState,
    deleteEvent,
    deleteState,
    requestRefreshEvent,
    onRefreshEventCompleted,
    requestEditEvent,
    editUser,
    onEditStateReceived,
    onEditEventCompleted,
    requestDeleteEvent,
    deleteUser,
    onDeleteStateReceived,
    onDeleteEventCompleted,
    loadingObserver,
    fetchUser,
    onFetchStateReceived,
    user,
  };
}
