import { FC } from "react";
import { Modal } from "rsuite";
import { UserSupervision } from "../../../../../domain/supervision/Supervision";
import { UserSupervisionView } from "../../../supervision-unity/components/view";

export const ByRequestSupervisionUnityModal: FC<{
  onClose: () => void;
  supervision: UserSupervision;
}> = ({ onClose, supervision }) => {
  return (
    <Modal open={true} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Supervisión</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserSupervisionView supervision={supervision} />
      </Modal.Body>
    </Modal>
  );
};
