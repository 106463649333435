import { FirestoreSimpleCrudSource } from "../source/FirestoreSimpleCrudSource";
import { QueryConstraint, Timestamp, where } from "firebase/firestore";
import { AppTimestamp } from "../../domain/app/Timestamp";
import { DateTime } from "luxon";
import { AppTimestampDao } from "../database/dao/AppTimestamp";
import { isEmpty } from "lodash";
import { orderBy as firestoreOrderBy } from "@firebase/firestore";
import UnityRepository from "./UnityRepository";
import UserRepository from "./UserRepository";
import { UserWrapper } from "../../domain/user/User";
import {
  GetVehicleControlDto,
  VehicleListQueryRequest,
} from "../../network/control/Vehicle";
import {
  UserVehicleControl,
  UnityVehicleControl,
  VehicleControl,
  vehicleControlAsUserVehicleControl,
  vehicleControlAsUnityVehicleControl,
  vehicleControlDtoAsVehicleControl,
} from "../../domain/control/Vehicle";
import { VehicleControlDao } from "../database/dao/Vehicle";
import Fuse from "fuse.js";

export class VehicleControlRepository {
  private static vehicleControlSource =
    new FirestoreSimpleCrudSource<GetVehicleControlDto>("vehicle_control");

  static async getList(
    timestamp: number,
    unityId: number,
    forceRefresh?: boolean
  ): Promise<
    | { vehicleControlList: UserVehicleControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `${unityId}_vehicle-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let unityVehicleList = await VehicleControlDao.getAllUnityVehicleControl(
      unityId,
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(unityVehicleList) || forceRefresh) {
      await VehicleControlDao.deleteAllUnityVehicleControl(
        unityId,
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemoteVehicleControlList(
        dayStartTimestamp,
        dayEndTimestamp,
        [where("unityId", "==", unityId)]
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      unityVehicleList = await VehicleControlDao.getAllUnityVehicleControl(
        unityId,
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const unityVehicleArray =
      await this.vehicleControlArrayToUserVehicleControlArray(unityVehicleList);
    return {
      vehicleControlList: unityVehicleArray,
      timestamp: appTimestamp,
    };
  }

  static async getUserList(
    timestamp: number,
    uid: string,
    forceRefresh: boolean = false,
    appUser: UserWrapper
  ): Promise<
    | { userVehicleControlList: UserVehicleControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    if (appUser?.isClient()) {
      if (appUser!.user!.clientUnity) {
        return this.getUserListWithConstraints(timestamp, uid, forceRefresh, [
          where("unityId", "in", appUser!.user.clientUnity),
        ]);
      }
    } else {
      return this.getUserListWithConstraints(timestamp, uid, forceRefresh);
    }
  }
  //
  static async getGlobal(
    request: VehicleListQueryRequest,
    timestamp: number | undefined,
    initialSelectedTime: number,
    forceRefresh: boolean = false,
    appUser: UserWrapper
  ): Promise<
    | { vehicleControlList: UserVehicleControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    if (appUser.isClient()) {
      if (appUser.user.clientUnity) {
        return this.getGlobalWithConstraints(
          request,
          timestamp,
          initialSelectedTime,
          forceRefresh,
          [where("unityId", "in", appUser.user.clientUnity)]
        );
      }
    } else {
      return this.getGlobalWithConstraints(
        request,
        timestamp,
        initialSelectedTime,
        forceRefresh
      );
    }
  }

  static async getGlobalWithConstraints(
    request: VehicleListQueryRequest | undefined,
    timestamp: number | undefined,
    initialSelectedTime: number,
    forceRefresh: boolean = false,
    constraints: QueryConstraint[] = []
  ): Promise<
    | { vehicleControlList: UserVehicleControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    let dayStartTimestamp;
    let dayEndTimestamp;
    let appTimestampKey;
    let appTimestamp;

    if (timestamp === undefined) {
      appTimestampKey = `g_vehicle-${initialSelectedTime}`;
    } else {
      const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
      dayStartTimestamp = dateTime.startOf("day").toMillis();
      dayEndTimestamp = dateTime.endOf("day").toMillis();
      appTimestampKey = `g_vehicle-${dayStartTimestamp}`;
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    let vehicleControlList = await VehicleControlDao.getAllVehicleControl(
      request?.orderBy,
      request?.order,
      timestamp === undefined ? undefined : dayStartTimestamp,
      timestamp === undefined ? undefined : dayEndTimestamp
    );
    if (isEmpty(vehicleControlList) || forceRefresh) {
      await VehicleControlDao.deleteAllVehicleControl(
        timestamp === undefined ? undefined : dayStartTimestamp,
        timestamp === undefined ? undefined : dayEndTimestamp
      );
      await this.fetchRemoteVehicleControlList(
        timestamp === undefined ? undefined : dayStartTimestamp,
        timestamp === undefined ? undefined : dayEndTimestamp,
        constraints
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      vehicleControlList = await VehicleControlDao.getAllVehicleControl(
        undefined,
        undefined,
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const userVehicleControlArray =
      await this.vehicleControlArrayToUserVehicleControlArray(
        vehicleControlList
      );

    return {
      vehicleControlList: userVehicleControlArray,
      timestamp: appTimestamp,
    };
  }

  static async getVehicleControl(
    reference: string
  ): Promise<UserVehicleControl | undefined> {
    const local = await VehicleControlDao.getVehicleControl(reference);
    if (!!local) {
      return await this.vehicleControlToUserVehicleControl(local);
    }
  }

  private static async getUserListWithConstraints(
    timestamp: number,
    uid: string,
    forceRefresh?: boolean,
    constraints: QueryConstraint[] = []
  ): Promise<
    | { userVehicleControlList: UserVehicleControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `u_${uid}_vehicle-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let vehicleControlList = await VehicleControlDao.getUserVehicleControlList(
      uid,
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(vehicleControlList) || forceRefresh) {
      await VehicleControlDao.deleteAllUserVehicleControl(
        uid,
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemoteVehicleControlList(
        dayStartTimestamp,
        dayEndTimestamp,
        [where("ownerUid", "==", uid), ...constraints]
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      vehicleControlList = await VehicleControlDao.getUserVehicleControlList(
        uid,
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const vehicleControlArray =
      await this.vehicleControlArrayToUserVehicleControlArray(
        vehicleControlList
      );
    return {
      userVehicleControlList: vehicleControlArray,
      timestamp: appTimestamp,
    };
  }

  private static async vehicleControlArrayToUserVehicleControlArray(
    vehicleControlArray: VehicleControl[]
  ): Promise<UserVehicleControl[]> {
    return await Promise.all(
      vehicleControlArray.map(
        async (it) => await this.vehicleControlToUserVehicleControl(it)
      )
    );
  }
  
  private static async vehicleControlArrayToUnityVehicleControlArray(
    vehicleControlArray: VehicleControl[]
  ): Promise<UnityVehicleControl[]> {
    return await Promise.all(
      vehicleControlArray.map(
        async (it) => await this.vehicleControlToUnityVehicleControl(it)
      )
    );
  }

  private static async fetchRemoteVehicleControlList(
    dateStartMillis: number | undefined,
    dateEndMillis: number | undefined,
    constraints: QueryConstraint[] = []
  ): Promise<void> {
    let vehicleControlList;

    if (dateStartMillis === undefined && dateEndMillis === undefined) {
      vehicleControlList = await this.vehicleControlSource.getList([
        firestoreOrderBy("timestamp", "desc"),
        ...constraints,
      ]);
    } else {
      vehicleControlList = await this.vehicleControlSource.getList([
        where("timestamp", ">=", Timestamp.fromMillis(dateStartMillis!!)),
        where("timestamp", "<=", Timestamp.fromMillis(dateEndMillis!!)),
        firestoreOrderBy("timestamp", "desc"),
        ...constraints,
      ]);
    }

    if (vehicleControlList) {
      const entries = vehicleControlList.map((it) =>
        vehicleControlDtoAsVehicleControl(it.data, it.reference)
      );
      await VehicleControlDao.putVehicleControl(...entries);
    }
  }

  private static async vehicleControlToUserVehicleControl(
    vehicleControl: VehicleControl
  ): Promise<UserVehicleControl> {
    const unity = vehicleControl.unityId
      ? await UnityRepository.getUnity(vehicleControl.unityId)
      : undefined;

    const owner = await UserRepository.getUser(vehicleControl.ownerUid);

    return vehicleControlAsUserVehicleControl(vehicleControl, unity, owner);
  }

  private static async vehicleControlToUnityVehicleControl(
    vehicleControl: VehicleControl
  ): Promise<UnityVehicleControl> {
    const unity = vehicleControl.unityId
      ? await UnityRepository.getUnity(vehicleControl.unityId)
      : undefined;
    return vehicleControlAsUnityVehicleControl(vehicleControl, unity);
  }

  static async searchVehicle(
    filter: string,
    keyword: string,
    timestampStart: number | null,
    timestampEnd: number | null,
    inall?: boolean
  ): Promise<UnityVehicleControl[] | undefined> {
    // let options;
    let localVehicles: VehicleControl | UnityVehicleControl[];
    let dayStartTimestamp;
    let dayEndTimestamp;
    if (timestampStart && timestampEnd) {
      const dateTimeInit =
        DateTime.fromMillis(timestampStart).setZone("America/Lima");
      const dateTimeEnd =
        DateTime.fromMillis(timestampEnd).setZone("America/Lima");

      dayStartTimestamp = dateTimeInit.startOf("day").toMillis();
      dayEndTimestamp = dateTimeEnd.endOf("day").toMillis();
    }

    let vehicleControlList = await VehicleControlDao.getAllVehicleControl(
      undefined,
      undefined,
      inall === true ? undefined : dayStartTimestamp,
      inall === true ? undefined : dayEndTimestamp
    );

    localVehicles = await this.vehicleControlArrayToUnityVehicleControlArray(
      vehicleControlList
    );

    // ["plate", "unity.label", "driver"]
    let options = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys:
        filter === "driver"
          ? ["driver"]
          : filter === "plate"
          ? ["plate"]
          : filter === "unityId"
          ? ["unity.label"]
          : ["plate", "unity.label", "driver"],
    };
    console.log("localVehicles", localVehicles);
    if (localVehicles) {
      const fuse = new Fuse(localVehicles, options);
      return fuse.search(keyword).map((it) => ({ ...it.item }));
    }
  }
}
