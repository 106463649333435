import { PentrackerUser } from "../user/User";
import { AttendanceDto } from "../../network/attendance/Attendance";
import { Unity } from "../unity";
import { AccountType } from "../account-type/AccountType";
import { Shift } from "../user/Shift";

export type Attendance = Omit<AttendanceDto, "timestamp"> & {
  timestamp: number;
  reference: string;
  ownerPhotoUrl?: string;
};

export type UserAttendance = Attendance & {
  reference: string;
  unity?: Unity;
  owner?: PentrackerUser;
  accountType?: AccountType;
  shift?: Shift;
};

export type UnityAttendance = Attendance & {
  unity?: Unity;
};

export type AttendancePair = {
  entry: UserAttendance;
  departure?: UserAttendance | null;
};

export function attendanceDtoAsDomain(
  dto: AttendanceDto,
  reference: string
): Attendance {
  return {
    ...dto,
    timestamp: dto.timestamp.toMillis(),
    reference,
  };
}

export function attendanceAsUserAttendance(
  attendance: Attendance,
  user?: PentrackerUser,
  unity?: Unity,
  accountType?: AccountType,
  shift?: Shift
) {
  return {
    ...attendance,
    owner: user,
    unity: unity,
    accountType: accountType,
    shift: shift,
  };
}
