import React, { FC, useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useUnityViewModel } from "../../../viewmodel/unity/Unity";
import { AppLoader } from "../../components/loading/LoadingOverlay";
import { EditableFormModal } from "../../components/modal/EditableFormModal";
import {
  DeleteUnityInputList,
  DeleteUnityInputSchema,
  EditUnityInputList,
  UnityInputSchema,
} from "../unitylist/modal/InputList";
import { UnityPageHeader } from "./components/Header";
import { UnityPageNavigator } from "./components/Navigator";
import { DashboardHeaderContext } from "../../../hook/header";
import { FormModal } from "../../components/modal/FormModal";

import { useStyleContext } from "../../context/StyleContext";
import { UserAuthContext } from "../../context/UserContext";

export const UnityPage: FC = () => {
  const viewModel = useUnityViewModel();
  const navigate = useNavigate();
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  const {
    onEditStateReceived,
    onDeleteStateReceived,
    onFetchStateReceived,
    fetchUnity,
    refreshEvent,
    onRefreshEventCompleted,
    unity,
    editEvent,
    editUnity,
    onEditEventCompleted,
    requestEditEvent,
    editState,
    deleteEvent,
    deleteState,
    requestDeleteEvent,
    onDeleteEventCompleted,
    deleteUnity,
    fetchState,
    requestRefreshEvent,
  } = viewModel;

  const { appUser } = useContext(UserAuthContext);
  const { stylePage } = useStyleContext();

  /*eslint-disable*/
  useEffect(() => {
    if (!!editState && !editState.loading) {
      if (editState.isSuccess())
        window.toast.success("Se editó correctamente.");
      else {
        window.toast.error("Ocurrió un error al editar. Ver consola");
        console.log(editState.error);
      }
      onEditStateReceived();
      onEditEventCompleted();
    }
  }, [editState]);

  useEffect(() => {
    if (!!deleteState && !deleteState.loading) {
      if (deleteState.isSuccess()) {
        window.toast.success("Se eliminó correctamente.");
        navigate(-1);
      } else {
        window.toast.error("Ocurrió un error al eliminar. Ver consola");
        console.log(deleteState.error);
      }
      onDeleteStateReceived();
      onDeleteEventCompleted();
    }
  }, [deleteState]);

  useEffect(() => {
    if (!!fetchState && !fetchState.loading) {
      if (fetchState.isFailed()) {
        window.toast.error(
          "Ocurrió un error al obtener el módulo. Ver consola"
        );
        console.log(fetchState.error);
      }
      onFetchStateReceived();
      onRefreshEventCompleted();
    }
  }, [fetchState]);

  useEffect(() => {
    void fetchUnity(true);
    onRefreshEventCompleted();
  }, [refreshEvent]);

  useEffect(() => {
    void fetchUnity();
  }, []);

  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-neutral-100",
      subtitle: `${unity?.label || ""}`,
      title: "Unidad",
      homeAsUpEnabled: true,
    });
  }, []);
  /*eslint-enable*/

  return (
    <div
      className={"w-full overflow-x-hidden overflow-y-auto"}
      style={stylePage}
    >
      <AppLoader isActive={fetchState?.loading} />
      <div className={"w-full"}>
        {!!editEvent ? (
          <EditableFormModal
            inputList={EditUnityInputList}
            initialValues={editEvent}
            title={`Editar Unidad`}
            onOk={editUnity}
            onCancel={onEditEventCompleted}
            visible={true}
            disabled={editState?.loading}
            okButtonLoading={editState?.loading}
            model={UnityInputSchema}
          />
        ) : null}

        {!!deleteEvent ? (
          <FormModal
            onCancel={onDeleteEventCompleted}
            onOk={deleteUnity}
            visible={true}
            inputList={DeleteUnityInputList}
            title={"Eliminar Unidad"}
            model={DeleteUnityInputSchema}
            disabled={deleteState?.loading}
          />
        ) : null}

        <UnityPageHeader
          onEditButtonClick={requestEditEvent}
          onRefreshButtonClick={requestRefreshEvent}
          onDeleteButtonClick={requestDeleteEvent}
        />
        <div
          className={
            "w-full flex flex-col lg:flex-row border-t-2 border-neutral-200"
          }
        >
          <div className={"w-full md:w-fit p-2 md:p-3 lg:py-5"}>
            <div
              className={
                "lg:bg-white lg:rounded-xl lg:overflow-hidden lg:border lg:border-neutral-300"
              }
            >
              <UnityPageNavigator appUser={appUser} />
            </div>
          </div>
          <div className={"w-full overflow-y-auto p-2 md:p-3"}>
            <Outlet context={unity} />
          </div>
        </div>
      </div>
    </div>
  );
};
