import { FC } from "react";
import { Modal } from "rsuite";
import { UserVehicleControl } from "../../../../../domain/control/Vehicle";
import { VehicleControlView } from "../../../vehicle-control/components/view";

export const ByRequestVehicleControlModal: FC<{
  onClose: () => void;
  vehicleControl: UserVehicleControl;
}> = ({ onClose, vehicleControl }) => {
  return (
    <Modal open={true} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Control Vehicular</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VehicleControlView vehicleControl={vehicleControl} />
      </Modal.Body>
    </Modal>
  );
};
