import { FC, useContext } from "react";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { UserVisitControl } from "../../../../../domain/control/Visit";
import { VisitControlList } from "../../../data-display/visit-control-list";
import { VisitControlTable } from "../../../data-display/visit-control-table";

type Props = {
  items?: UserVisitControl[];
  onPreview: (item: UserVisitControl) => void;
  onClick: (user: UserVisitControl) => void;
};

export const VisitControlGroupByRequestDataName =
  "VisitControlGroupByRequestData";

export const VisitControlGroupByRequestData: FC<Props> = ({
  items,
  onPreview,
  onClick,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <VisitControlList
        onSelect={onClick}
        visitControlList={items}
        name={VisitControlGroupByRequestDataName}
      />
    );
  } else {
    return (
      <VisitControlTable
        visitControlList={items}
        onPreviewClicked={onPreview}
        onSelect={onClick}
        name={VisitControlGroupByRequestDataName}
      />
    );
  }
};
