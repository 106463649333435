import { db } from "../db";
import { VisitControl } from "../../../domain/control/Visit";

export class VisitControlDao {
  static async putVisitControl(...visitControl: VisitControl[]) {
    return db.visit_control.bulkPut(visitControl);
  }

  static async getAllUnityVisitControl(
    unityId: number,
    start: number,
    end: number
  ) {
    return db.visit_control
      .where(["unityId+timestamp"])
      .between([unityId, start], [unityId, end], true, true)
      .reverse()
      .sortBy("timestamp");
  }

  static async deleteAllUnityVisitControl(
    unityId: number,
    start: number,
    end: number
  ) {
    return db.visit_control
      .where(["unityId+timestamp"])
      .between([unityId, start], [unityId, end], true, true)
      .delete();
  }

  static async getUserVisitControlList(
    ownerUid: string,
    start: number,
    end: number
  ) {
    return db.visit_control
      .where(["ownerUid+timestamp"])
      .between([ownerUid, start], [ownerUid, end])
      .reverse()
      .sortBy("timestamp");
  }

  static async deleteAllUserVisitControl(
    ownerUid: string,
    start: number,
    end: number
  ) {
    return db.visit_control
      .where(["ownerUid+timestamp"])
      .between([ownerUid, start], [ownerUid, end])
      .delete();
  }

  // static async getAllVisitControl(start: number, end: number) {
  //     return db.visit_control
  //         .where("timestamp")
  //         .between(start, end, true, true)
  //         .reverse()
  //         .sortBy("timestamp");
  // }

  static async getAllVisitControl(
    orderBy?: string,
    order?: "asc" | "desc" | undefined,
    start?: number,
    end?: number
  ) {
    if (start === undefined && end === undefined) {
      let query = db.visit_control.orderBy("timestamp").reverse().toArray();
      let data = await query;
      if (order && order === "desc") data = data.reverse();
      return data;
    } else {
      let query = db.visit_control
        .where("timestamp")
        .between(start, end, true, true)
        .sortBy(orderBy ? orderBy : "timestamp");
      let data = await query;
      if (order && order === "desc") data = data.reverse();
      return data;
    }
  }

  static async deleteAllVisitControl(start: number, end: number) {
    return db.visit_control
      .where("timestamp")
      .between(start, end, true, true)
      .delete();
  }

  static async getVisitControl(reference: string) {
    return db.visit_control.get(reference);
  }
}
