import { useContext, useState } from "react";
import { Response } from "../../domain/app/Response";
import { SupervisionRepository } from "../../data/repository/SupervisionRepository";
import { UserSupervision } from "../../domain/supervision/Supervision";
import { AppTimestamp } from "../../domain/app/Timestamp";
import { UserAuthContext } from "../../ui/context/UserContext";

export function useByRequestSupervisionGroupViewModel() {
  const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
  const [userSupervisionList, setUserSupervisionList] = useState<
    UserSupervision[] | undefined
  >();
  const [userSupervisionTimestamp, setUserSupervisionTimestamp] = useState<
    AppTimestamp | undefined
  >();
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
  const [selectedItem, setSelectedItem] = useState<UserSupervision | null>(
    null
  );
  const { appUser } = useContext(UserAuthContext);

  async function fetchUserSupervisionList(
    timestamp: number,
    forceRefresh: boolean = false
  ) {
    if (fetchState?.isLoading()) return;
    setFetchState(Response.loading());
    try {
      const result = await SupervisionRepository.getGlobal(
        timestamp,
        forceRefresh,
        appUser
      );
      console.log("result", result);
      setUserSupervisionList(result?.supervisionList);
      setUserSupervisionTimestamp(result?.timestamp);
      setFetchState(Response.success(true));
    } catch (e: any) {
      setFetchState(Response.failure(e));
    }
  }

  function onFetchStateReceived() {
    setFetchState(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  function requestSelectItem(item: UserSupervision) {
    setSelectedItem(item);
  }

  function onSelectItemCompleted() {
    setSelectedItem(null);
  }

  return {
    fetchState,
    onFetchStateReceived,
    userSupervisionList,
    userSupervisionTimestamp,
    fetchUserSupervisionList,
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
    requestSelectItem,
    onSelectItemCompleted,
    selectedItem,
  };
}
