import { FirestoreSimpleCrudSource } from "../source/FirestoreSimpleCrudSource";
import { QueryConstraint, Timestamp, where } from "firebase/firestore";
import { AppTimestamp } from "../../domain/app/Timestamp";
import { DateTime } from "luxon";
import { AppTimestampDao } from "../database/dao/AppTimestamp";
import { isEmpty } from "lodash";
import { orderBy as firestoreOrderBy } from "@firebase/firestore";
import UnityRepository from "./UnityRepository";
import UserRepository from "./UserRepository";
import { UserWrapper } from "../../domain/user/User";
import { GetGoodsControlDto } from "../../network/control/Goods";
import {
  GoodsControl,
  goodsControlAsUserGoodsControl,
  goodsControlDtoAsGoodsControl,
  UserGoodsControl,
} from "../../domain/control/Goods";
import { GoodsControlDao } from "../database/dao/Goods";
import Fuse from "fuse.js";

export class GoodsControlRepository {
  private static goodsControlSource =
    new FirestoreSimpleCrudSource<GetGoodsControlDto>("goods_control");

  static async getList(
    timestamp: number,
    unityId: number,
    forceRefresh?: boolean
  ): Promise<
    | { goodsControlList: UserGoodsControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `${unityId}_goods-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let unityVehicleList = await GoodsControlDao.getAllUnityGoodsControl(
      unityId,
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(unityVehicleList) || forceRefresh) {
      await GoodsControlDao.deleteAllUnityGoodsControl(
        unityId,
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemoteGoodsControlList(
        dayStartTimestamp,
        dayEndTimestamp,
        [where("unityId", "==", unityId)]
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      unityVehicleList = await GoodsControlDao.getAllUnityGoodsControl(
        unityId,
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const unityVehicleArray =
      await this.goodsControlArrayToUserGoodsControlArray(unityVehicleList);
    return {
      goodsControlList: unityVehicleArray,
      timestamp: appTimestamp,
    };
  }

  static async getUserList(
    timestamp: number,
    uid: string,
    forceRefresh: boolean = false,
    appUser: UserWrapper
  ): Promise<
    | { userGoodsControlList: UserGoodsControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    if (appUser?.isClient()) {
      if (appUser!.user!.clientUnity) {
        return this.getUserListWithConstraints(timestamp, uid, forceRefresh, [
          where("unityId", "in", appUser!.user.clientUnity),
        ]);
      }
    } else {
      return this.getUserListWithConstraints(timestamp, uid, forceRefresh);
    }
  }

  static async getGlobal(
    timestamp: number,
    forceRefresh: boolean = false,
    appUser: UserWrapper
  ): Promise<
    | { goodsControlList: UserGoodsControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    if (appUser.isClient()) {
      if (appUser.user.clientUnity) {
        return this.getGlobalWithConstraints(timestamp, forceRefresh, [
          where("unityId", "in", appUser.user.clientUnity),
        ]);
      }
    } else {
      return this.getGlobalWithConstraints(timestamp, forceRefresh);
    }
  }

  static async getGlobalWithConstraints(
    timestamp: number,
    forceRefresh: boolean = false,
    constraints: QueryConstraint[] = []
  ): Promise<
    | { goodsControlList: UserGoodsControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `g_goods-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let goodsControlList = await GoodsControlDao.getAllGoodsControl(
      undefined,
      undefined,
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(goodsControlList) || forceRefresh) {
      await GoodsControlDao.deleteAllGoodsControl(
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemoteGoodsControlList(
        dayStartTimestamp,
        dayEndTimestamp,
        constraints
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      goodsControlList = await GoodsControlDao.getAllGoodsControl(
        undefined,
        undefined,
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const userGoodsControlArray =
      await this.goodsControlArrayToUserGoodsControlArray(goodsControlList);
    return {
      goodsControlList: userGoodsControlArray,
      timestamp: appTimestamp,
    };
  }

  static async getGoodsControl(
    reference: string
  ): Promise<UserGoodsControl | undefined> {
    const local = await GoodsControlDao.getGoodsControl(reference);
    if (!!local) {
      return await this.goodsControlToUserGoodsControl(local);
    }
  }

  private static async getUserListWithConstraints(
    timestamp: number,
    uid: string,
    forceRefresh?: boolean,
    constraints: QueryConstraint[] = []
  ): Promise<
    | { userGoodsControlList: UserGoodsControl[]; timestamp?: AppTimestamp }
    | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `u_${uid}_goods-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let goodsControlList = await GoodsControlDao.getUserGoodsControlList(
      uid,
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(goodsControlList) || forceRefresh) {
      await GoodsControlDao.deleteAllUserGoodsControl(
        uid,
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemoteGoodsControlList(
        dayStartTimestamp,
        dayEndTimestamp,
        [where("ownerUid", "==", uid), ...constraints]
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      goodsControlList = await GoodsControlDao.getUserGoodsControlList(
        uid,
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const goodsControlArray =
      await this.goodsControlArrayToUserGoodsControlArray(goodsControlList);
    return {
      userGoodsControlList: goodsControlArray,
      timestamp: appTimestamp,
    };
  }

  private static async goodsControlArrayToUserGoodsControlArray(
    goodsControlArray: GoodsControl[]
  ): Promise<UserGoodsControl[]> {
    return await Promise.all(
      goodsControlArray.map(
        async (it) => await this.goodsControlToUserGoodsControl(it)
      )
    );
  }

  private static async fetchRemoteGoodsControlList(
    dateStartMillis: number,
    dateEndMillis: number,
    constraints: QueryConstraint[] = []
  ): Promise<void> {
    const goodsControlList = await this.goodsControlSource.getList([
      where("timestamp", ">=", Timestamp.fromMillis(dateStartMillis)),
      where("timestamp", "<=", Timestamp.fromMillis(dateEndMillis)),
      firestoreOrderBy("timestamp", "desc"),
      ...constraints,
    ]);

    if (goodsControlList) {
      const entries = goodsControlList.map((it) =>
        goodsControlDtoAsGoodsControl(it.data, it.reference)
      );
      await GoodsControlDao.putGoodsControl(...entries);
    }
  }

  private static async goodsControlToUserGoodsControl(
    goodsControl: GoodsControl
  ): Promise<UserGoodsControl> {
    const unity = goodsControl.unityId
      ? await UnityRepository.getUnity(goodsControl.unityId)
      : undefined;
    const owner = await UserRepository.getUser(goodsControl.ownerUid);
    return goodsControlAsUserGoodsControl(goodsControl, unity, owner);
  }

  static async searchGoodsControl(
    filter: string,
    keyword: string,
    timestampStart: number | null,
    timestampEnd: number | null,
    inall?: boolean // : Promise<UnityAttendance[] | undefined>
  ) {
    // let options;
    let localGoodsControl: GoodsControl[] | UserGoodsControl[];
    let dayStartTimestamp;
    let dayEndTimestamp;
    if (timestampStart && timestampEnd) {
      const dateTimeInit =
        DateTime.fromMillis(timestampStart).setZone("America/Lima");
      const dateTimeEnd =
        DateTime.fromMillis(timestampEnd).setZone("America/Lima");

      dayStartTimestamp = dateTimeInit.startOf("day").toMillis();
      dayEndTimestamp = dateTimeEnd.endOf("day").toMillis();
    }

    let goodsControlList = await GoodsControlDao.getAllGoodsControl(
      undefined,
      undefined,
      inall === true ? undefined : dayStartTimestamp,
      inall === true ? undefined : dayEndTimestamp
    );

    localGoodsControl = await this.goodsControlArrayToUserGoodsControlArray(
      goodsControlList
    );

    // ["unity.label", "provider", "dni"]
    let options = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys:
        filter === "unityId"
          ? ["unity.label"]
          : filter === "provider"
          ? ["provider"]
          : filter === "dni"
          ? ["document"]
          : ["unity.label", "provider", "document"],
    };

    if (localGoodsControl) {
      const fuse = new Fuse(localGoodsControl, options);
      return fuse.search(keyword).map((it) => ({ ...it.item }));
    }
  }
}
