import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Response } from "../../domain/app/Response";
import useSessionStorageState from "use-session-storage-state";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../data/database/db";
import { DateTime } from "luxon";
import { Patrol, UserPatrol } from "../../domain/patrol/Patrol";
import { PatrolSupRepository } from "../../data/repository/PatrolSupRepository";
import { UserAuthContext } from "../../ui/context/UserContext";

export function useLivePatrolGroupSupViewModel() {
  const [selectedItem, setSelectedItem] = useState<UserPatrol | null>(null);
  const { appUser } = useContext(UserAuthContext);
  const query = useLiveQuery(
    () =>
      db.patrolSup
        .where("timestamp")
        .aboveOrEqual(
          DateTime.now().setZone("America/Lima").startOf("day").toMillis()
        )
        .toArray(),
    []
  );
  const [list, setList] = useSessionStorageState<UserPatrol[]>(
    "live_patrol_group_sup",
    {
      defaultValue: [],
    }
  );
  const [connectionState, setConnectionState] =
    useState<Response<boolean> | null>(null);

  const connectionReference = useRef<any>(null) as MutableRefObject<
    Awaited<ReturnType<typeof PatrolSupRepository.getLive>>
  >;

  useEffect(() => {
    return () => {
      if (connectionReference.current) connectionReference.current();
      void PatrolSupRepository.stopLive();
    };
  }, []);

  const onError = useCallback((error: any) => {
    setConnectionState(Response.failure(error));
  }, []);

  /* eslint-disable */
  const injectEntries = useCallback(
    async (patrolList: Patrol[]) => {
      if (patrolList.length > 0) {
        const tasks = patrolList.map(async (patrol) => {
          try {
            return await PatrolSupRepository.patrolToUserPatrol(patrol);
          } catch (e: any) {
            return patrol;
          }
        });
        const injectedTasksDone = (await Promise.all(tasks)) as UserPatrol[];
        setList([...injectedTasksDone, ...list]);
      }
    },
    [list]
  );

  useEffect(() => {
    if (query) {
      const currentReferences = list.map((it) => it.reference);
      const differentReferences = query
        .filter((it) => !currentReferences.includes(it.reference))
        .reverse();
      void injectEntries(differentReferences);
    }
  }, [query]);

  /* eslint-enable */

  async function connect() {
    if (connectionState?.isLoading()) return;
    setConnectionState(Response.loading());
    try {
      connectionReference.current = await PatrolSupRepository.getLive(
        onError,
        appUser
      );
      setConnectionState(Response.success(true));
    } catch (e: any) {
      setConnectionState(Response.failure(e));
    }
  }

  function onConnectionStateReceived() {
    setConnectionState(null);
  }

  function requestSelectItem(item: UserPatrol) {
    setSelectedItem(item);
  }

  function onSelectItemCompleted() {
    setSelectedItem(null);
  }

  return {
    connectionState,
    onConnectionStateReceived,
    connect,
    list,
    selectedItem,
    requestSelectItem,
    onSelectItemCompleted,
  };
}
