import React, { FC } from "react";
import { RoleBasedNavigator } from "../../../components/nav/navigator";
import {
  faCalendar,
  faPieChart,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { UserRoles } from "../../../../domain/user/Role";

export const PatrolGroupSupNavigator: FC = () => {
  return (
    <RoleBasedNavigator
      definition={[
        {
          id: `/dashboard/patrol-group-sup`,
          label: "Resúmen",
          icon: faPieChart,
          allowedRoles: UserRoles.all,
          allowIf: () => true,
        },
        {
          id: `/dashboard/patrol-group-sup/request`,
          label: "Fecha",
          icon: faCalendar,
          allowedRoles: UserRoles.all,
          allowIf: () => true,
        },
        {
          id: `/dashboard/patrol-group-sup/live`,
          label: "Ahora",
          icon: faSync,
          allowedRoles: UserRoles.manager,
          allowIf: () => true,
        },
      ]}
    />
  );
};
