import { FC } from "react";
import { CellProps, Table } from "rsuite";
import { AttendancePair } from "../../../../domain/attendance/Attendance";
import { FaIcon } from "../../../components/fontawesome/icon";
import {
  faCheckToSlot,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";

export const AttendanceGroupSupTableObservationCell: FC<
  CellProps<AttendancePair>
> = ({ rowData, ...props }) => {
  const {
    entry: { observation },
  } = rowData!!;
  return (
    <Table.Cell {...props}>
      {!!observation ? (
        <div>
          <FaIcon icon={faCheckToSlot} color={"green"} /> Sí
        </div>
      ) : (
        <div>
          <FaIcon icon={faXmarkCircle} color={"red"} /> No
        </div>
      )}
    </Table.Cell>
  );
};
