import {
  collection,
  doc,
  DocumentSnapshot,
  getDoc,
  getDocFromCache,
  onSnapshot,
  query,
  QueryConstraint,
  startAfter,
  Timestamp,
  Unsubscribe,
  where,
} from "firebase/firestore";
import FirestoreSource from "../source/FirestoreSource";
import {
  Supervision,
  supervisionAsUserSupervision,
  supervisionDtoAsDomain,
  UserSupervision,
} from "../../domain/supervision/Supervision";
import { GetSupervisionDto } from "../../network/supervision/Supervision";
import { FirestoreSimpleCrudSource } from "../source/FirestoreSimpleCrudSource";
import { AppTimestamp } from "../../domain/app/Timestamp";
import { DateTime } from "luxon";
import { isEmpty } from "lodash";
import { orderBy as firestoreOrderBy } from "@firebase/firestore";
import { AppTimestampDao } from "../database/dao/AppTimestamp";
import { SupervisionDao } from "../database/dao/Supervision";
import UnityRepository from "./UnityRepository";
import ZoneRepository from "./ZoneRepository";
import UserRepository from "./UserRepository";
import { UserWrapper } from "../../domain/user/User";

export class SupervisionRepository {
  private static supervisionSource =
    new FirestoreSimpleCrudSource<GetSupervisionDto>("unity_control");

  private static unsubscribeRef: Unsubscribe | null = null;

  static async getList(
    timestamp: number,
    unityId: number,
    forceRefresh?: boolean
  ): Promise<
    { supervisionList: UserSupervision[]; timestamp?: AppTimestamp } | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `supervisionlist-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let supervisionList = await SupervisionDao.getAllUnitySupervision(
      unityId,
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(supervisionList) || forceRefresh) {
      await SupervisionDao.deleteAllUnitySupervision(
        unityId,
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemoteSupervisionList(
        dayStartTimestamp,
        dayEndTimestamp,
        [where("unityId", "==", unityId)]
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      supervisionList = await SupervisionDao.getAllUnitySupervision(
        unityId,
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const userSupervisionArray =
      await this.supervisionArrayToUserSupervisionArray(supervisionList);
    return {
      supervisionList: userSupervisionArray,
      timestamp: appTimestamp,
    };
  }

  static async getUserList(
    timestamp: number,
    uid: string,
    forceRefresh: boolean = false,
    appUser: UserWrapper
  ): Promise<
    { supervisionList: UserSupervision[]; timestamp?: AppTimestamp } | undefined
  > {
    if (appUser?.isClient()) {
      if (appUser?.user.clientUnity) {
        return this.getUserListWithConstraints(timestamp, uid, forceRefresh, [
          where("unityId", "in", appUser!.user.clientUnity),
        ]);
      }
    } else {
      return this.getUserListWithConstraints(timestamp, uid, forceRefresh);
    }
  }

  static async getGlobal(
    timestamp: number,
    forceRefresh: boolean = false,
    appUser: UserWrapper
  ): Promise<
    { supervisionList: UserSupervision[]; timestamp?: AppTimestamp } | undefined
  > {
    if (appUser?.isClient()) {
      if (appUser!.user.clientUnity)
        return this.getGlobalWithConstraints(timestamp, forceRefresh, [
          where("unityId", "in", appUser.user.clientUnity),
        ]);
    } else {
      return this.getGlobalWithConstraints(timestamp, forceRefresh);
    }
  }
  
  static async getSupervision(
    reference: string
  ): Promise<UserSupervision | undefined> {
    const local = await SupervisionDao.getSupervision(reference);
    if (local) {
      return await this.supervisionToUserSupervision(local);
    }
  }

  static async getLive(
    onError: (error: Error) => void,
    appUser: UserWrapper
  ): Promise<Unsubscribe | undefined> {
    if (this.unsubscribeRef) return undefined;
    const dayStartTimestamp = DateTime.now()
      .setZone("America/Lima")
      .startOf("day")
      .toMillis();
    let lastDaySupervision =
      await SupervisionDao.getLastSupervisionAboveTimestamp(dayStartTimestamp);
    let lastDocument: DocumentSnapshot | undefined;
    if (lastDaySupervision) {
      try {
        lastDocument = await getDocFromCache(
          doc(FirestoreSource.firestore, lastDaySupervision.reference)
        );
      } catch (e: any) {
        lastDocument = await getDoc(
          doc(FirestoreSource.firestore, lastDaySupervision.reference)
        );
      }
    }
    const supervisionCollection = collection(
      FirestoreSource.firestore,
      "supervision"
    );
    const constraints: QueryConstraint[] = [
      firestoreOrderBy("timestamp", "asc"),
    ];
    if (!!lastDocument) constraints.push(startAfter(lastDocument));
    if (appUser.isClient() && appUser.user.clientUnity)
      constraints.push(where("unityId", "in", appUser.user.clientUnity));
    let supervisionQuery = query(supervisionCollection, ...constraints);

    this.unsubscribeRef = onSnapshot(
      supervisionQuery,
      {
        includeMetadataChanges: false,
      },
      async (snapshot) => {
        for (let documentSnapshot of snapshot.docs) {
          const supervision = supervisionDtoAsDomain(
            documentSnapshot.data() as GetSupervisionDto,
            documentSnapshot.ref.path
          );
          await SupervisionDao.putSupervision(supervision);
        }
      },
      (error) => {
        onError(error);
      }
    );
    return this.unsubscribeRef;
  }

  static async stopLive() {
    if (this.unsubscribeRef) {
      this.unsubscribeRef();
      this.unsubscribeRef = null;
    }
  }

  static async supervisionToUserSupervision(
    supervision: Supervision
  ): Promise<UserSupervision> {
    // const unity = supervision.unityId
    //   ? await UnityRepository.getUnity(supervision.unityId)
    //   : undefined;
    // const zone = supervision.submoduleId
    //   ? await ZoneRepository.getZone(supervision.unityId, supervision.submoduleId)
    //   : undefined;
    // const owner = await UserRepository.getUser(supervision.ownerUid);
    return supervisionAsUserSupervision(supervision);
  }

  private static async getUserListWithConstraints(
    timestamp: number,
    uid: string,
    forceRefresh: boolean = false,
    constraints: QueryConstraint[] = []
  ): Promise<
    { supervisionList: UserSupervision[]; timestamp?: AppTimestamp } | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `user-supervisionlist-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let supervisionList = await SupervisionDao.getUserSupervisionList(
      uid,
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(supervisionList) || forceRefresh) {
      await SupervisionDao.deleteAllUserSupervision(
        uid,
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemoteSupervisionList(
        dayStartTimestamp,
        dayEndTimestamp,
        [where("ownerUid", "==", uid), ...constraints]
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      supervisionList = await SupervisionDao.getUserSupervisionList(
        uid,
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const userSupervisionArray =
      await this.supervisionArrayToUserSupervisionArray(supervisionList);
    return {
      supervisionList: userSupervisionArray,
      timestamp: appTimestamp,
    };
  }

  private static async getGlobalWithConstraints(
    timestamp: number,
    forceRefresh: boolean = false,
    constraints: QueryConstraint[] = []
  ): Promise<
    { supervisionList: UserSupervision[]; timestamp?: AppTimestamp } | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `global-supervisionlist-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let supervisionList = await SupervisionDao.getAllSupervision(
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(supervisionList) || forceRefresh) {
      await SupervisionDao.deleteAllSupervision(
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemoteSupervisionList(
        dayStartTimestamp,
        dayEndTimestamp,
        constraints
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      supervisionList = await SupervisionDao.getAllSupervision(
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const userSupervisionArray =
      await this.supervisionArrayToUserSupervisionArray(supervisionList);
    return {
      supervisionList: userSupervisionArray,
      timestamp: appTimestamp,
    };
  }

  private static async supervisionArrayToUserSupervisionArray(
    supervisionArray: Supervision[]
  ): Promise<UserSupervision[]> {
    return await Promise.all(
      supervisionArray.map(
        async (it) => await this.supervisionToUserSupervision(it)
      )
    );
  }

  private static async fetchRemoteSupervisionList(
    dateStartMillis: number,
    dateEndMillis: number,
    constraints: QueryConstraint[] = []
  ): Promise<void> {
    const supervisionList = await this.supervisionSource.getList([
      firestoreOrderBy("timestamp", "desc"),
      where("timestamp", ">=", Timestamp.fromMillis(dateStartMillis)),
      where("timestamp", "<=", Timestamp.fromMillis(dateEndMillis)),
      ...constraints,
    ]);
    if (supervisionList) {
      const entries = supervisionList.map((it) =>
        supervisionDtoAsDomain(it.data, it.reference)
      );
      await SupervisionDao.putSupervision(...entries);
    }
  }
}
