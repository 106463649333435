import { FC } from "react";
import { CellProps, Table } from "rsuite";
import { toTime } from "../../../../lib/date";
import { UserSupervision } from "../../../../domain/supervision/Supervision";

export const SupervisionGroupTableDepartureCell: FC<
  CellProps<UserSupervision>
> = ({ rowData, ...props }) => {
  const { value } = rowData?.formDataPageMain.controlDeparture!;

  return <Table.Cell {...props}>{value}</Table.Cell>;
};
