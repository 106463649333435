import { FC } from "react";
import { CellProps, Table } from "rsuite";
import { toTime } from "../../../../lib/date";
import { UserSupervision } from "../../../../domain/supervision/Supervision";

export const SupervisionGroupTableTimeCell: FC<CellProps<UserSupervision>> = ({
  rowData,
  ...props
}) => {
  const { timestamp } = rowData!;

  return <Table.Cell {...props}>{toTime(timestamp)}</Table.Cell>;
};
