import { db } from "../db";
import { Zone } from "../../../domain/patrol/Zone";

export class ZoneForUnityDao {
  static async putZone(...zone: Zone[]) {
    await db.zoneForUnity.bulkPut(zone);
  }

  static async getZone(unityId: number, id: number) {
    return db.zoneForUnity.where("id").equals(id).first();
  }

  static async delete(unityId: number, id: number) {
    await db.zoneForUnity
      .where(["unityId", "id"])
      .between([unityId, id], [unityId, id], true, true)
      .delete();
  }

  static async clear(unityId: number) {
    await db.zoneForUnity.where("unityId").equals(unityId).delete();
  }

  static async update(id: number, newData: Partial<Zone>) {
    await db.zoneForUnity.update(id, newData);
  }

  static async getList(unityId: number) {
    return db.zoneForUnity.where("unityId").equals(unityId).toArray();
  }
}
