const logoClassMap: Record<string, string> = {
  logoPentracker2: "h-10 w-32 fill-white", // Clase por defecto en caso de no existir `unityIdParent`
  1683263204338: "h-12 w-32 fill-white",
};

export default logoClassMap;

// ABRIL 3505875964657665 -
// ALDEAS 1683263204338 -
// CAJA TRUJILLO 1683266447539 -
// LA PARCELA 3498475666800640 -
// COSTAMAR 1683265682785 -
// EFILA 1683265777496 -
// INVERSIONES EL PINO 1683266456572 -
// LADRILLOS PIRAMIDE 1707510634020 -
// PRIMA 1709676524556 -
// QUIMICA ANDINA 1691542450511 -
// SAMI 1694636297920 -
// TRANSPORTES PLUTON 1707262396216
