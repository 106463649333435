import { FC } from "react";
import { Modal } from "rsuite";
import { AttendancePair } from "../../../../../domain/attendance-sup/Attendance";
import { UserAttendanceView } from "../../../attendance/components/view";

export const ByRequestAttendanceSupModal: FC<{
  onClose: () => void;
  attendancePair: AttendancePair;
}> = ({ onClose, attendancePair }) => {
  return (
    <Modal open={true} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Asistencia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserAttendanceView attendance={attendancePair} />
      </Modal.Body>
    </Modal>
  );
};
