import React, { FC, useMemo } from "react";
import { ListChildComponentProps } from "react-window";
import { toRelativeCalendar } from "../../../../../../../lib/date";
import { VehicleSearchListRowProps } from "./index";
import { UserVehicleControl } from "../../../../../../../domain/control/Vehicle";

import { VehicleControlRowUnity } from "../../../../../data-display/vehicle-control-list/unity";
import { VehicleControlRowTimestamp } from "../../../../../data-display/vehicle-control-list/timestamp";
import { RowCell } from "../../../../../../components/row/Cell";

export const VehicleSearchRow: FC<
  ListChildComponentProps<VehicleSearchListRowProps>
> = ({ index, style, data }) => {
  const { items, onClick } = data;
  const item = items[index];
  //   const wrapper = useMemo(() => {
  //     return new UserWrapper(item);
  //   }, [item]);
  return (
    <div style={style} className={"p-2"}>
      <div
        data-index={index}
        onClick={onClick}
        className={
          "w-full h-full border border-neutral-300 rounded-2xl p-2 flex flex-col justify-center items-start gap-2"
        }
      >
        <div className={"flex w-full flex-col gap-2"}>
          <VehicleControlRowUnity item={item} />
          <VehicleControlRowTimestamp item={item} />
          <RowCell label={"CONDUCTOR"} data={item} dataKey={"driver"} />
          <RowCell label={"EMPRESA"} data={item} dataKey={"derivation"} />
          <RowCell label={"INGRESO"} data={item} dataKey={"entry"} />
          <RowCell label={"PLACA"} data={item} dataKey={"plate"} />
        </div>
        {/* <UserRowRole userWrapper={wrapper} />
        <UserRowDisplayName user={item} />
        <UserRowUid user={item} />
        {wrapper.isClient() ? (
          <UserRowClientUnity user={item} />
        ) : (
          <UserRowUnity user={item} />
        )}
        {item.lastSignInTime ? (
          <p className={"m-0 p-0"}>
            <span className={"mr-2 text-neutral-400 font-semibold"}>
              ULT. SESIÓN
            </span>
            <span>{toRelativeCalendar(new Date(item.lastSignInTime))}</span>
          </p>
        ) : null}
        <p className={"m-0 p-0"}>
          <span className={"mr-2 text-neutral-400 font-semibold"}>CREADO</span>
          <span>{toRelativeCalendar(new Date(item.creationTime))}</span>
        </p> */}
      </div>
    </div>
  );
};
