import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppLoader } from "../../../components/loading/LoadingOverlay";
import { DatePicker, IconButton, Loader } from "rsuite";
import { formatDate } from "../../../../util/date";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faRefresh, faSearch } from "@fortawesome/free-solid-svg-icons";
import { toRelativeCalendar } from "../../../../lib/date";
import { UserPatrol } from "../../../../domain/patrol/Patrol";
import { useByRequestPatrolGroupViewModel } from "../../../../viewmodel/patrol-group/ByRequestPatrolGroup";
import { ByRequestPatrolModal } from "./component/modal";
import { PatrolGroupByRequestData } from "./component/data";
import { useNavigate } from "react-router-dom";
import { DashboardHeaderContext } from "../../../../hook/header";
import { useQueryNavigate } from "../../../../hook/navigate";
import { useQuery } from "../../../../hook/query";
import { DateTime } from "luxon";
import { toInteger } from "lodash";
import { SearchFormModal } from "../../../components/modal/SearchFormModal";
import { SearchPatrolInputList } from "./modal/FilterPatrolInputList";
import AlertModal from "../../../components/modal/AlertModal";

export const ByRequestPatrolGroup: FC<any> = () => {
  const queryTimestamp = useQuery().get("timestamp");
  const initialSelectedTime = queryTimestamp
    ? DateTime.fromMillis(toInteger(queryTimestamp)).toJSDate()
    : new Date();
  const [selectedTime, setSelectedTime] = useState(initialSelectedTime);
  const navigate = useNavigate();
  const {
    //fetch
    fetchState,
    fetchUserPatrolList,
    onFetchStateReceived,
    userPatrolList,
    userPatrolTimestamp,

    //refresh
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,

    //search
    searchEvent,
    searchState,
    searchResults,
    requestSearchEvent,
    onSearchEventCompleted,
    searchPatrol,

    //generate
    generateEvent,
    generateState,
    requestGenerateEvent,
    onGenerateEventCompleted,
    toGenerateExcel,

    //alert
    isAlertVisible,
    descriptionAlert,
    setIsAlertVisible,

    // actions
    onSelectItemCompleted,
    requestSelectItem,
    selectedItem,
  } = useByRequestPatrolGroupViewModel();
  const loading = fetchState?.loading || undefined;
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  const queryNavigate = useQueryNavigate();
  /* eslint-disable */
  useEffect(() => {
    void fetchUserPatrolList(selectedTime.getTime());
  }, [selectedTime]);

  useEffect(() => {
    if (fetchState && !fetchState.isLoading()) {
      if (fetchState.isFailed()) {
        window.toast.error(
          "Ocurrió un error al obtener la lista de rondas del usuario."
        );
        console.log(fetchState?.error);
      }
      onFetchStateReceived();
    }
  }, [fetchState]);

  useEffect(() => {
    if (refreshEvent) {
      void fetchUserPatrolList(selectedTime.getTime(), true);
      onRefreshEventCompleted();
    }
  }, [refreshEvent]);

  const onTableItemSelected = useCallback((patrol: UserPatrol) => {
    navigate(
      `/dashboard/patrol?reference=${encodeURIComponent(patrol.reference)}`
    );
  }, []);

  const onTablePreviewActionClicked = useCallback((patrol: UserPatrol) => {
    requestSelectItem(patrol);
  }, []);

  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-secondary-header",
      title: "Rondas",
      dark: true,
      subtitle: "Seleccionar por fecha",
    });
  }, []);

  const onCloseAlert = () => {
    setIsAlertVisible(false);
  };
  const onConfirmationAlert = () => {
    setIsAlertVisible(false);
  };

  /* eslint-enable */
  return (
    <div className={"w-full"}>
      <AppLoader isActive={loading} />
      <AlertModal
        onClose={onCloseAlert}
        onConfirmation={onConfirmationAlert}
        visible={isAlertVisible}
        description={descriptionAlert}
      />
      {!!selectedItem ? (
        <ByRequestPatrolModal
          patrol={selectedItem}
          onClose={onSelectItemCompleted}
        />
      ) : null}

      <div
        className={
          "flex-none w-full flex flex-row flex-wrap gap-2 p-2 border-b-2 border-neutral-100 overflow-x-hidden items-center justify-between"
        }
      >
        <span className={"text-lg font-normal text-neutral-600 uppercase"}>
          {formatDate(selectedTime)}
        </span>
        <div className={"flex flex-row flex-wrap items-center gap-2"}>
          <div className={"flex flex-row flex-wrap gap-2 items-center z-0"}>
            <span>Seleccionar fecha</span>
            <DatePicker
              size={"sm"}
              appearance={"subtle"}
              value={selectedTime}
              onChange={(newDate) => {
                if (
                  !!newDate &&
                  newDate?.getTime() !== selectedTime.getTime()
                ) {
                  setSelectedTime(newDate);
                  queryNavigate({
                    timestamp: newDate.getTime(),
                  });
                }
              }}
            />
          </div>
          <IconButton
            size={"sm"}
            appearance={"primary"}
            onClick={requestRefreshEvent}
            icon={<FaIcon icon={faRefresh} />}
          >
            Actualizar
          </IconButton>
          <IconButton
            onClick={requestSearchEvent}
            icon={<FaIcon icon={faSearch} />}
          >
            Buscar
          </IconButton>
        </div>
      </div>

      {searchEvent ? (
        <SearchFormModal
          typeSearchModal="PatrolSearch"
          onCancel={onSearchEventCompleted}
          visible={true}
          inputList={SearchPatrolInputList}
          // disabled={fetchListState?.isLoading()}
          title={"Buscar Rondas"}
          // initialValues={{
          //   filter: "driver",
          // }}
          // onOk={(values: any) => {
          //   updateRequestParams({ ...values });
          // }}
          results={searchResults}
          onSearch={searchPatrol}
          onGenerate={toGenerateExcel}
        />
      ) : null}

      {fetchState?.isLoading() ? (
        <Loader center backdrop size={"sm"} content={"Cargando..."} />
      ) : (
        <Fragment>
          {userPatrolTimestamp ? (
            <div className={"w-full flex-none p-2"}>
              <p>
                <span className={"text-neutral-400 uppercase"}>
                  Última actualización:{" "}
                </span>
                {toRelativeCalendar(userPatrolTimestamp.timestamp, true)}
              </p>
            </div>
          ) : null}
          <PatrolGroupByRequestData
            items={userPatrolList}
            onPreview={onTablePreviewActionClicked}
            onClick={onTableItemSelected}
          />
        </Fragment>
      )}
    </div>
  );
};
