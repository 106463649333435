import { FC, useCallback, useContext, useEffect } from "react";
import { useLiveAttendanceGroupSupViewModel } from "../../../../viewmodel/attendance-group-sup/LiveAttendanceGroupSup";
import { AttendancePair } from "../../../../domain/attendance-sup/Attendance";
import { useNavigate } from "react-router-dom";
import { LiveAttendanceModal } from "./components/modal";
import { AttendanceGroupLiveData } from "./components/data";
import { DashboardHeaderContext } from "../../../../hook/header";

export const LiveAttendanceGroupSup: FC<any> = () => {
  const {
    connect,
    connectionState,
    onConnectionStateReceived,
    list,
    onSelectItemCompleted,
    requestSelectItem,
    selectedItem,
  } = useLiveAttendanceGroupSupViewModel();

  const navigate = useNavigate();
  const dashboardHeaderContext = useContext(DashboardHeaderContext);

  /* eslint-disable */
  const onTableAttendanceSelected = useCallback((item: AttendancePair) => {
    navigate(
      `/dashboard/attendanceSup?entry=${encodeURIComponent(
        item.entry.reference
      )}`
    );
  }, []);

  const onTablePreviewActionClicked = useCallback((item: AttendancePair) => {
    requestSelectItem(item);
  }, []);

  useEffect(() => {
    void connect();
    dashboardHeaderContext.setTheme({
      className: "bg-secondary-header",
      title: "Asistencia",
      dark: true,
      subtitle: "Ver en tiempo real",
    });
  }, []);

  useEffect(() => {
    if (!!connectionState && !connectionState.isLoading()) {
      if (connectionState.isFailed()) {
      }
      onConnectionStateReceived();
    }
  }, [connectionState]);

  /* eslint-enable */

  return (
    <div className={"w-full"}>
      {!!selectedItem ? (
        <LiveAttendanceModal
          onClose={onSelectItemCompleted}
          attendancePair={selectedItem}
        />
      ) : null}
      <AttendanceGroupLiveData
        items={list}
        onPreview={onTablePreviewActionClicked}
        onClick={onTableAttendanceSelected}
      />
    </div>
  );
};
