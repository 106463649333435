import React, { FC } from "react";
import { UserSupervision } from "../../../../domain/supervision/Supervision";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FaIcon } from "../../../components/fontawesome/icon";

type Props = {
  item: UserSupervision;
};

export const SupervisionRowOperator: FC<Props> = ({ item }) => {
  //   const { owner, ownerUid } = item;
  const { value } = item.formDataPageMain.controlLiable;
  return (
    <div className={"m-0 p-0 flex flex-row items-center flex-wrap gap-2"}>
      <FaIcon icon={faUser} className={"mr-2"} />
      <span>{value ? value : "no ingresado"}</span>

      {/* <span>{owner?.displayName ? owner.displayName : ownerUid}</span> */}
    </div>
  );
};
