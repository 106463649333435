import React, { FC } from "react";
import { RoleBasedNavigator } from "../../../components/nav/navigator";
import {
  faCalendar,
  faPieChart,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { UserRoles } from "../../../../domain/user/Role";

export const AttendanceGroupNavigator: FC = () => {
  return (
    <RoleBasedNavigator
      definition={[
        {
          id: `/dashboard/attendance-group`,
          label: "Resúmen",
          icon: faPieChart,
          allowedRoles: UserRoles.all,
          allowIf: () => true,
        },
        {
          id: `/dashboard/attendance-group/request`,
          label: "Fecha",
          icon: faCalendar,
          allowedRoles: UserRoles.all,
          allowIf: () => true,
        },
        // {
        //   id: `/dashboard/attendance-group/live`,
        //   label: "Ahora",
        //   icon: faSync,
        //   allowedRoles: [UserRoles.mainadmin],
        //   allowIf: () => true,
        // },
      ]}
    />
  );
};
