import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  QueryConstraint,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import FirestoreSource, { DataWithReference } from "./FirestoreSource";
import { isNil, toString } from "lodash";

export class FirestoreSimpleCrudSource<
  GetType = any,
  PostType = any,
  PutType = any
> {
  path: string;

  constructor(path: string) {
    this.path = path;
  }

  switchPath(path: string) {
    this.path = path;
  }

  collection() {
    return collection(FirestoreSource.firestore, this.path);
  }

  async create(
    newObject: PostType,
    uid: string | number | undefined | null = undefined
  ) {
    if (isNil(uid)) {
      return await addDoc(this.collection(), newObject as any);
    } else {
      const _nDoc = doc(this.collection(), toString(uid));
      await setDoc(_nDoc, newObject as any);
      return _nDoc;
    }
  }

  async delete(reference: string) {
    return await deleteDoc(doc(FirestoreSource.firestore, reference));
  }

  async update(reference: string, newData: PutType) {
    return await updateDoc(
      doc(FirestoreSource.firestore, reference),
      newData as any
    );
  }

  async getList(
    arg: QueryConstraint[] | null = null
  ): Promise<DataWithReference<GetType>[]> {
    const customQuery = arg
      ? query(this.collection(), ...arg)
      : this.collection();
    let list = await getDocs(customQuery);
    return list
      ? list.docs
          .filter((value) => value.data())
          .map((value) => ({
            data: value.data() as GetType,
            reference: value.ref.path,
          }))
      : [];
  }

  async getByReference(reference: string, forced: boolean = false) {
    const document = await getDoc(doc(FirestoreSource.firestore, reference));
    return {
      data: document.data() as GetType,
      reference: document.ref.path,
    };
  }

  async getDocumentRef(collectionName: string, documentId: string) {
    if (!collectionName || !documentId) {
      throw new Error("Collection name and document ID are required");
    }
    const document = await getDoc(
      doc(FirestoreSource.firestore, collectionName, documentId)
    );
    return {
      data: document.data() as GetType,
      reference: document.ref.path,
    };
  }

  async getByQuery(arg: QueryConstraint[], forced: boolean = false) {
    let result = await getDocs(query(this.collection(), ...arg));
    const item = result.empty ? null : result.docs[0];
    return item
      ? {
          data: item.data() as GetType,
          reference: item.ref.path,
        }
      : null;
  }
}
