import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useRef,
  MutableRefObject,
} from "react";
import { Pagination, Table } from "rsuite";
import { useTableSize } from "../../../../hook/width";
import { TableInstance } from "rsuite/Table";
import { TableColumnContext } from "../../../context/TableContext";

import { faCalendarXmark } from "@fortawesome/free-solid-svg-icons";
import { EmptyState } from "../../../components/state/empty";
import { useDataPager } from "../../../../hook/pager";
import { VehicleControlTableTimestampCell } from "./timestamp";
import { VehicleControlTableUnityCell } from "./unity";
import {
  UserVehicleControl,
  VehicleControl,
} from "../../../../domain/control/Vehicle";
import { useStyleContext } from "../../../context/StyleContext";
import { VehicleControlTableColumns } from "../../../../schema/table/VehicleControlTable";
import { VehicleControlGroupTableActionCell } from "./action";

type Props = {
  vehicleControlList?: UserVehicleControl[];
  name: string;
  onSelect: (item: UserVehicleControl) => void;
  onPreviewClicked?: (item: UserVehicleControl) => void;
};

export const VehicleControlTable: FC<Props> = ({
  vehicleControlList,
  name,
  onSelect,
  onPreviewClicked,
}) => {
  const actionRef = useRef<string | null>(null) as MutableRefObject<
    string | null
  >;
  const tableRef = useRef<TableInstance<any, any>>(null);
  const tableSize = useTableSize(tableRef);
  const tableContext = useContext(TableColumnContext);
  const tablePreferences =
    tableContext.getTablePreferences<VehicleControlTableColumns>(name);

  /* eslint-disable */
  const { partialData, changePage, page, changeLimit, limits, limit } =
    useDataPager({
      id: `veh_table_${name}`,
      items: vehicleControlList,
    });

  const onRowSelect = useCallback(
    (item: UserVehicleControl) => {
      if (actionRef.current && onPreviewClicked) onPreviewClicked(item);
      else onSelect(item);
      actionRef.current = null;
    },
    [partialData]
  );
  const { tableHeaderStyle, tableHeaderStyleSecondary } = useStyleContext();
  /* eslint-enable */
  const onPreviewActionSelected = useCallback(() => {
    actionRef.current = "preview";
  }, [partialData]);

  return (
    <Fragment>
      <Table
        className={"rounded md:rounded-xl"}
        autoHeight
        data={partialData}
        ref={tableRef}
        renderEmpty={() => (
          <EmptyState
            icon={faCalendarXmark}
            title={"No hay registros de control de vehículo"}
            message={
              "No se encontraron reportes de control de vehículos para la fecha seleccionada."
            }
          />
        )}
        onRowClick={onRowSelect as any}
        cellBordered
      >
        {tablePreferences.map((column) => {
          switch (column.name) {
            case "timestamp":
              return (
                <Table.Column
                  verticalAlign={"middle"}
                  align={"center"}
                  key={`column-${column.name}`}
                  resizable
                  width={tableSize.calculateWeight(column.weight)}
                  onResize={(columnWidth) => {
                    if (columnWidth) {
                      tableContext.updateTableColumnWeight<VehicleControlTableColumns>(
                        name,
                        column.name,
                        tableSize.sizeToWeight(columnWidth)
                      );
                    }
                  }}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    {column.label}
                  </Table.HeaderCell>
                  <VehicleControlTableTimestampCell />
                </Table.Column>
              );
            case "plate":
              return (
                <Table.Column
                  verticalAlign={"middle"}
                  align={"center"}
                  key={`column-${column.name}`}
                  resizable
                  width={tableSize.calculateWeight(column.weight)}
                  onResize={(columnWidth) => {
                    if (columnWidth) {
                      tableContext.updateTableColumnWeight<VehicleControlTableColumns>(
                        name,
                        column.name,
                        tableSize.sizeToWeight(columnWidth)
                      );
                    }
                  }}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    {column.label}
                  </Table.HeaderCell>
                  <Table.Cell dataKey={"plate"} />
                </Table.Column>
              );
            case "unityId":
              return (
                <Table.Column
                  verticalAlign={"middle"}
                  align={"center"}
                  key={`column-${column.name}`}
                  resizable
                  width={tableSize.calculateWeight(column.weight)}
                  onResize={(columnWidth) => {
                    if (columnWidth) {
                      tableContext.updateTableColumnWeight<VehicleControlTableColumns>(
                        name,
                        column.name,
                        tableSize.sizeToWeight(columnWidth)
                      );
                    }
                  }}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    {column.label}
                  </Table.HeaderCell>
                  <VehicleControlTableUnityCell />
                </Table.Column>
              );
            case "driver":
              return (
                <Table.Column
                  verticalAlign={"middle"}
                  align={"center"}
                  key={`column-${column.name}`}
                  resizable
                  width={tableSize.calculateWeight(column.weight)}
                  onResize={(columnWidth) => {
                    if (columnWidth) {
                      tableContext.updateTableColumnWeight<VehicleControlTableColumns>(
                        name,
                        column.name,
                        tableSize.sizeToWeight(columnWidth)
                      );
                    }
                  }}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    {column.label}
                  </Table.HeaderCell>
                  <Table.Cell dataKey={"driver"} />
                </Table.Column>
              );
            case "departure":
              return (
                <Table.Column
                  verticalAlign={"middle"}
                  align={"center"}
                  key={`column-${column.name}`}
                  resizable
                  width={tableSize.calculateWeight(column.weight)}
                  onResize={(columnWidth) => {
                    if (columnWidth) {
                      tableContext.updateTableColumnWeight<VehicleControlTableColumns>(
                        name,
                        column.name,
                        tableSize.sizeToWeight(columnWidth)
                      );
                    }
                  }}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    {column.label}
                  </Table.HeaderCell>
                  <Table.Cell dataKey={"departure"} />
                </Table.Column>
              );
            case "actions":
              return (
                <Table.Column
                  resizable
                  align={"center"}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(column.weight)}
                  key={`columns-${column.name}`}
                >
                  <Table.HeaderCell style={tableHeaderStyleSecondary}>
                    Acciones
                  </Table.HeaderCell>
                  <VehicleControlGroupTableActionCell
                    onPreviewActionClicked={onPreviewActionSelected}
                  />
                </Table.Column>
              );
            default:
              return (
                <Table.Column
                  align={"center"}
                  verticalAlign={"middle"}
                  key={`column-${column.name}`}
                  resizable
                  width={tableSize.calculateWeight(column.weight)}
                  onResize={(columnWidth) => {
                    if (columnWidth) {
                      tableContext.updateTableColumnWeight<VehicleControlTableColumns>(
                        name,
                        column.name,
                        tableSize.sizeToWeight(columnWidth)
                      );
                    }
                  }}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    {column.label}
                  </Table.HeaderCell>
                  <Table.Cell dataKey={"entry"} />
                </Table.Column>
              );
          }
        })}
      </Table>

      {vehicleControlList ? (
        <div className={"py-4 flex flex-col gap-4"}>
          <span className={"text-md"}>
            Mostrando {partialData?.length} elementos / Total{" "}
            {vehicleControlList.length}
          </span>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["limit", "|", "pager", "skip"]}
            total={vehicleControlList.length}
            limitOptions={limits}
            limit={limit}
            activePage={page}
            onChangePage={changePage}
            onChangeLimit={changeLimit}
          />
        </div>
      ) : null}
    </Fragment>
  );
};
