import { useContext, useEffect, useState } from "react";
import { Response } from "../../domain/app/Response";
import { AppTimestamp } from "../../domain/app/Timestamp";
import { UserAuthContext } from "../../ui/context/UserContext";
import { UserVehicleControl } from "../../domain/control/Vehicle";
import { VehicleControlRepository } from "../../data/repository/VehicleControlRepository";
import useLocalStorageState from "use-local-storage-state";
import { VehicleListQueryRequest } from "../../network/control/Vehicle";
import { filter, first, isEqual, remove } from "lodash";
import { db } from "../../data/database/db";
import { useLiveQuery } from "dexie-react-hooks";
import { useQuery } from "../../hook/query";
import { DateTime } from "luxon";
import { toInteger } from "lodash";
import { LocalErrorLogRepository } from "../../data/repository/LocalErrorLogRepository";
import UnityRepository from "../../data/repository/UnityRepository";
import { generateExcel } from "../../util/generateExcel";

type FormModalValues = {
  [key: string]: any;
};

export function useVehicleControlViewModel() {
  const queryTimestamp = useQuery().get("timestamp");
  const initialSelectedTime = queryTimestamp
    ? DateTime.fromMillis(toInteger(queryTimestamp)).toJSDate()
    : new Date();
  const [selectedTime, setSelectedTime] = useState(initialSelectedTime);
  const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
  const [vehicleControlList, setVehicleControlList] = useState<
    UserVehicleControl[] | undefined
  >();
  const [vehicleControlTimestamp, setVehicleControlTimestamp] = useState<
    AppTimestamp | undefined
  >();
  //refresh
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);

  // filter
  const [filterEvent, setFilterEvent] = useState<boolean | null>(null);

  // search
  const [searchEvent, setSearchEvent] = useState<boolean | null>(null);
  const [searchState, setSearchState] = useState<Response<boolean> | null>(
    null
  );
  const [searchResults, setSearchResults] = useState<
    UserVehicleControl[] | null
  >(null);

  // generate excel
  const [generateEvent, setGenerateEvent] = useState<boolean | null>(null);

  const [generateState, setGenerateState] = useState<Response<boolean> | null>(
    null
  );

  // modal alert
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [descriptionAlert, setDescriptionAlert] = useState("");

  const [requestParams, setRequestParams] =
    useLocalStorageState<VehicleListQueryRequest>(
      "vehicle_pagination_map_request_params",
      {
        defaultValue: {
          orderBy: "plate",
          order: "asc",
        },
      }
    );

  const { appUser } = useContext(UserAuthContext);
  const listLookup = useLiveQuery(
    () => db.vehicle_control.toArray(),
    [requestParams]
  );

  /* eslint-disable */
  useEffect(() => {
    fetchVehicleControlList(requestParams, selectedTime.getTime());
  }, [listLookup]);

  // useEffect(() => {
  //   console.log("Request params changed");
  //   // fetchVehicleControlList(requestParams, selectedTime.getTime());
  // }, [requestParams]);
  /* eslint-enable */

  async function fetchVehicleControlList(
    params = requestParams,
    timestamp?: number,
    forceRefresh: boolean = false
  ) {
    if (fetchState?.isLoading()) return;
    setFetchState(Response.loading());
    try {
      const result = await VehicleControlRepository.getGlobal(
        params,
        timestamp,
        initialSelectedTime.getTime(),
        forceRefresh,
        appUser
      );
      console.log("update...", result);
      if (timestamp === undefined) {
        // solo setear a la db local
      } else {
        setVehicleControlList(result?.vehicleControlList);
        setVehicleControlTimestamp(result?.timestamp);
        setFetchState(Response.success(true));
      }
    } catch (e: any) {
      setFetchState(Response.failure(e));
    }
  }

  function updateRequestParams({
    orderBy = requestParams.orderBy,
    order = requestParams.order,
  }: Partial<VehicleListQueryRequest>) {
    if (fetchState?.isLoading()) return;
    setFetchState(Response.loading());
    const newParams = {
      orderBy,
      order,
    };
    if (!isEqual(newParams, requestParams)) {
      setRequestParams(newParams);
    }
    fetchVehicleControlList(requestParams, selectedTime.getTime());
    onFilterEventCompleted();
  }

  async function searchVehicle(values: FormModalValues) {
    setFetchState(Response.loading());
    try {
      const results = await VehicleControlRepository.searchVehicle(
        values.filter,
        values.search,
        values.searchInAll === true ? null : values.dpInicio.getTime(),
        values.searchInAll === true ? null : values.dpFinal.getTime(),
        values.searchInAll
      );

      console.log("results", results);
      setSearchResults(results || []);
      setFetchState(Response.success(true));
    } catch (e: any) {
      const label = `Error al buscar usuario: '${values.search}'`;
      await LocalErrorLogRepository.registerError(label, e);
      setFetchState(Response.failure(new Error(label)));
    }
  }

  async function toGenerateExcel() {
    setFetchState(Response.loading());
    try {
      console.log("to generate excel");
      console.log("search results", searchResults);
      if (searchResults?.length !== undefined && searchResults?.length > 0) {
        generateExcel(searchResults, "UserVehicleControl");
      } else {
        setIsAlertVisible(true);
        setDescriptionAlert(
          "Se necesita obtener resultados para generar Excel"
        );
      }

      setFetchState(Response.success(true));
    } catch (e: any) {
      const label = `Error al generar excel: ''`;
      await LocalErrorLogRepository.registerError(label, e);
      setFetchState(Response.failure(new Error(label)));
    }
  }

  function onFetchStateReceived() {
    setFetchState(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  function requestFilterEvent() {
    setFilterEvent(true);
  }

  function onFilterEventCompleted() {
    setFilterEvent(null);
  }

  function requestSearchEvent() {
    setSearchEvent(true);
  }

  function onSearchEventCompleted() {
    setSearchEvent(null);
    setSearchResults(null);
  }

  function requestGenerateEvent() {
    setGenerateEvent(true);
  }

  function onGenerateEventCompleted() {
    setGenerateEvent(null);
  }

  return {
    //fetch
    fetchState,
    vehicleControlList,
    vehicleControlTimestamp,
    onFetchStateReceived,
    fetchVehicleControlList,
    //refresh
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
    //filter
    filterEvent,
    requestParams,
    requestFilterEvent,
    onFilterEventCompleted,
    updateRequestParams,
    //search
    searchEvent,
    searchState,
    searchResults,
    requestSearchEvent,
    onSearchEventCompleted,
    searchVehicle,
    //generate excel
    generateEvent,
    generateState,
    requestGenerateEvent,
    onGenerateEventCompleted,
    toGenerateExcel,
    //alert
    isAlertVisible,
    setIsAlertVisible,
    descriptionAlert,
    //others
    selectedTime,
    setSelectedTime,
  };
}
