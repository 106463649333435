import { FC, useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLiveSupervisionUnityGroupSupViewModel } from "../../../../viewmodel/supervision-unity-group/LiveSupervisionUnityGroup";

import { UserSupervision } from "../../../../domain/supervision/Supervision";
import { SupervisionUnityGroupLiveData } from "./components/data";
import { LiveSupervisionModal } from "./components/modal";
import { DashboardHeaderContext } from "../../../../hook/header";

export const LiveSupervisionUnityGroup: FC<any> = () => {
  const {
    connect,
    connectionState,
    onConnectionStateReceived,
    list,
    onSelectItemCompleted,
    requestSelectItem,
    selectedItem,
  } = useLiveSupervisionUnityGroupSupViewModel();

  const navigate = useNavigate();
  const dashboardHeaderContext = useContext(DashboardHeaderContext);

  /* eslint-disable */
  useEffect(() => {
    void connect();
    dashboardHeaderContext.setTheme({
      className: "bg-secondary-header",
      title: "Rondas",
      dark: true,
      subtitle: "Ver en tiempo real",
    });
  }, []);

  const onTableAttendanceSelected = useCallback((item: UserSupervision) => {
    navigate(
      `/dashboard/supervision?reference=${encodeURIComponent(item.reference)}`
    );
  }, []);

  const onTablePreviewActionClicked = useCallback((item: UserSupervision) => {
    requestSelectItem(item);
  }, []);

  useEffect(() => {
    if (!!connectionState && !connectionState.isLoading()) {
      if (connectionState.isFailed()) {
      }
      onConnectionStateReceived();
    }
  }, [connectionState]);

  /* eslint-enable */

  return (
    <div className={"w-full overflow-x-hidden"}>
      {!!selectedItem ? (
        <LiveSupervisionModal
          onClose={onSelectItemCompleted}
          supervision={selectedItem}
        />
      ) : null}
      <SupervisionUnityGroupLiveData
        items={list}
        onClick={onTableAttendanceSelected}
        onPreview={onTablePreviewActionClicked}
      />
    </div>
  );
};
