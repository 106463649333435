import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import { SupervisionUnityGroupNavigator } from "./components/navigator";
import { PrimaryHeader } from "../../components/header/primary";
import { useStyleContext } from "../../context/StyleContext";

export const UserSupervisionUnityGroupPage: FC = () => {
  const { stylePage } = useStyleContext();

  return (
    <div
      className={"h-full w-full bg-neutral-100 overflow-y-auto"}
      style={stylePage}
    >
      <PrimaryHeader
        breadcrumbItems={[
          {
            label: "Supervision",
            active: true,
          },
        ]}
        title={"Supervision"}
      />

      <div
        className={
          "w-full flex flex-col lg:flex-row border-t-2 border-neutral-200"
        }
      >
        <div className={"w-full md:w-fit p-2 md:p-3 lg:py-5"}>
          <div
            className={
              "lg:bg-white lg:rounded-xl lg:overflow-hidden lg:border lg:border-neutral-300"
            }
          >
            <SupervisionUnityGroupNavigator />
          </div>
        </div>
        <div className={"w-full overflow-y-auto p-2 md:p-3 lg:py-3 lg:px-5"}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
