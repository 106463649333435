import React, { FC } from "react";
import { UserSupervision } from "../../../../domain/supervision/Supervision";

type Props = {
  item: UserSupervision;
};

export const SupervisionRowUnity: FC<Props> = ({ item }) => {
  //   const { unity, unityId } = item;
  const { value } = item.formDataPageMain.controlUnity;
  return (
    <div className={"m-0 p-0 flex flex-row flex-wrap gap-2"}>
      <span className={"text-neutral-400 font-semibold"}>UNIDAD</span>
      <span>{value ? value : "no ingresado"}</span>
    </div>
  );
};
