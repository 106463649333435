import { FC } from "react";
import { toDate } from "../../../../lib/date";
import { CellProps, Table } from "rsuite";
import { UserVisitControl } from "../../../../domain/control/Visit";

export const VisitControlTableTimestampCell: FC<
  CellProps<UserVisitControl>
> = ({ rowData, ...props }) => {
  if (!rowData) {
    return null;
  }
  const { timestamp } = rowData;
  return (
    <Table.Cell {...props}>
      <span>{toDate(timestamp)}</span>
    </Table.Cell>
  );
};
