import React, { FC } from "react";
import { toDate } from "../../../../lib/date";
import { UserVehicleControl } from "../../../../domain/control/Vehicle";

type Props = {
  item: UserVehicleControl;
};

export const VehicleControlRowTimestamp: FC<Props> = ({ item }) => {
  const { timestamp } = item;
  return (
    <div className={"m-0 p-0 flex flex-row flex-wrap gap-2"}>
      <span className={"text-neutral-400 font-semibold"}>REGISTRADO EL</span>
      <span>{toDate(timestamp)}</span>
    </div>
  );
};
