import Dexie, { Table } from "dexie";
import { UserDto } from "../../network/user/User";
import { Unity } from "../../domain/unity";
import { AppTimestamp } from "../../domain/app/Timestamp";
import { Attendance } from "../../domain/attendance/Attendance";
import { AttendanceSup } from "../../domain/attendance-sup/Attendance";
import { AccountType } from "../../domain/account-type/AccountType";
import { Role } from "../../domain/user/Role";
import { Shift } from "../../domain/user/Shift";
import { PathUrlBinding } from "../../domain/storage/PathUrlBinding";
import { Patrol } from "../../domain/patrol/Patrol";
import { Zone } from "../../domain/patrol/Zone";
import { Incident } from "../../domain/incident/Incident";
import { UserLocation } from "../../domain/location/UserLocation";
import { LocalErrorLog } from "../../domain/app/LocalErrorLog";
import { VisitControl } from "../../domain/control/Visit";
import { VehicleControl } from "../../domain/control/Vehicle";
import { GoodsControl } from "../../domain/control/Goods";
import { TicketFileType } from "../../domain/ticket/UserTicket";
import { NewsItem } from "../../domain/news/NewsItem";
import { AppNotification } from "../../domain/app/AppNotification";
import { ManagerEvent } from "../../domain/event/ManagerEvent";
import { Style } from "../../domain/style/Styles";
import { Supervision } from "../../domain/supervision/Supervision";

export class AppDatabase extends Dexie {
  users!: Table<UserDto>;
  unities!: Table<Unity>;
  entries!: Table<Attendance>;
  departures!: Table<Attendance>;
  entriesSup!: Table<AttendanceSup>;
  departuresSup!: Table<AttendanceSup>;
  timestamps!: Table<AppTimestamp>;
  search_results!: Table<UserDto>;
  account_types!: Table<AccountType>;
  roles!: Table<Role>;
  shifts!: Table<Shift>;
  path_url_bindings!: Table<PathUrlBinding>;
  patrol!: Table<Patrol>;
  patrolSup!: Table<Patrol>;
  supervision!: Table<Supervision>;
  zone!: Table<Zone>;
  zoneForUnity!: Table<Zone>;
  incident!: Table<Incident>;
  location!: Table<UserLocation>;
  local_error_log!: Table<LocalErrorLog>;
  visit_control!: Table<VisitControl>;
  vehicle_control!: Table<VehicleControl>;
  goods_control!: Table<GoodsControl>;
  ticket_file!: Table<TicketFileType>;
  ticket_container!: Table<TicketFileType>;
  news!: Table<NewsItem>;
  manager_event!: Table<ManagerEvent>;
  stylesClient!: Table<Style>;

  constructor() {
    super("pentrackerDatabase2");
    this.version(1).stores({
      users:
        "uid, displayName, roleId, email, lastSignInTime, creationTime, unityId, *clientUnity",
      unities: "id",
      entries:
        "reference, timestamp, [timestamp], [unityId+timestamp], [ownerUid+timestamp]",
      departures:
        "reference, timestamp, [timestamp], [unityId+timestamp], [ownerUid+timestamp]",
      entriesSup:
        "reference, timestamp, [timestamp], [unityId+timestamp], [ownerUid+timestamp]",
      departuresSup:
        "reference, timestamp, [timestamp], [unityId+timestamp], [ownerUid+timestamp]",
      timestamps: "key",
      search_results: "uid, displayName, email",
      account_types: "reference, id",
      roles: "reference, id",
      shifts: "reference, id",
      path_url_bindings: "path, url",
      patrol:
        "reference, timestamp, [timestamp], [unityId+timestamp], [ownerUid+timestamp]",
      patrolSup:
        "reference, timestamp, [timestamp], [unityId+timestamp], [ownerUid+timestamp]",
      zone: "id, [unityId+id]",
      zoneForUnity: "id, [unityId+id]",
      supervision: "reference,timestamp",
      incident:
        "reference, timestamp, [timestamp], [unityId+timestamp], [ownerUid+timestamp]",
      location: "reference, [uid+timestamp]",
      local_error_log: "id, timestamp",
      visit_control:
        "reference, timestamp, [timestamp], [unityId+timestamp], [ownerUid+timestamp]",
      vehicle_control:
        "reference, timestamp, [timestamp], [unityId+timestamp], [ownerUid+timestamp]",
      goods_control:
        "reference, timestamp, [timestamp], [unityId+timestamp], [ownerUid+timestamp]",
      ticket_container: "path, name, temp",
      ticket_file: "path, name, temp",
      news: "reference, timestamp",
      manager_event: "reference, date",
      stylesClient: "clientUID, email, rootUnity, primaryColors",
    });
  }
}

export class PreferencesDatabase extends Dexie {
  notifications!: Table<AppNotification>;

  constructor() {
    super("Pentracker_App_Secondary_DB");
    this.version(0.2)
      .stores({
        notifications: "id++, title, body, date, link",
      })
      .upgrade(async (trans) => {
        for (let table of trans.db.tables) {
          await table.clear();
        }
      });
  }
}

export const db = new AppDatabase();
export const preferencesDb = new PreferencesDatabase();
