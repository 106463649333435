import ExcelJS from "exceljs";
import { formatDateTime } from "./date";
import { UserVehicleControl } from "../domain/control/Vehicle";
import { AttendancePair } from "../domain/attendance/Attendance";
import { UserPatrol } from "../domain/patrol/Patrol";
import { UserVisitControl } from "../domain/control/Visit";
import { UserGoodsControl } from "../domain/control/Goods";

export const generateExcel = async <T,>(
  data: T[],
  type:
    | "UserVehicleControl"
    | "Attendance"
    | "UserPatrol"
    | "VisitControl"
    | "GoodsControl"
) => {
  const currentTimeStamp = Date.now();
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data");

  if (type === "UserVehicleControl") {
    const userVehicleData: UserVehicleControl[] = data as UserVehicleControl[];

    const transformedData = userVehicleData.map((item) => ({
      label: item.unity!!.label,
      driver: item.driver,
      derivation: item.derivation,
      plate: item.plate,
    }));

    // Agregar encabezado
    worksheet.columns = [
      { header: "Unidad", key: "label", width: 40 },
      { header: "Conductor", key: "driver", width: 40 },
      { header: "Empresa", key: "derivation", width: 30 },
      { header: "Placa", key: "plate", width: 30 },
      // { header: "Resitrado el", key: "", width: 10 },
    ];

    // Agregar datos
    worksheet.addRows(transformedData);
    // worksheet.addRow({
    //   nombre: "Carlos",
    //   correo: "carlos@example.com",
    //   edad: 28,
    // });
  } else if (type === "Attendance") {
    const attendanceData: AttendancePair[] = data as AttendancePair[];

    const transformedData = attendanceData.map((item) => ({
      unity: item.entry?.unity!!.label,
      operator: item.entry?.owner?.displayName,
      entry: formatDateTime(item.entry?.timestamp),
      departure: item.departure
        ? formatDateTime(item.departure.timestamp)
        : "No Registrada",
    }));

    // Agregar encabezado
    worksheet.columns = [
      { header: "Unidad", key: "unity", width: 40 },
      { header: "Agente", key: "operator", width: 30 },
      { header: "Entrada", key: "entry", width: 40 },
      { header: "Salida", key: "departure", width: 30 },
      // { header: "Resitrado el", key: "", width: 10 },
    ];

    // Agregar datos
    worksheet.addRows(transformedData);
  } else if (type === "UserPatrol") {
    const patrolData: UserPatrol[] = data as UserPatrol[];

    const transformedData = patrolData.map((item) => ({
      unity: item.unity?.label,
      operator: item.owner?.displayName,
      date: formatDateTime(item.timestamp),
    }));

    // Agregar encabezado
    worksheet.columns = [
      { header: "Unidad", key: "unity", width: 40 },
      { header: "Agente", key: "operator", width: 30 },
      { header: "Hora", key: "date", width: 40 },
    ];

    // Agregar datos
    worksheet.addRows(transformedData);
  } else if (type === "VisitControl") {
    const visitData: UserVisitControl[] = data as UserVisitControl[];

    const transformedData = visitData.map((item) => ({
      unity: item.unity?.label,
      visitName: item.name,
      visitLastName: item.lastname,
      subject: item.subject,
      dni: item.dni,
      entry: item.entry,
      date: formatDateTime(item.timestamp),
    }));

    // Agregar encabezado
    worksheet.columns = [
      { header: "Unidad", key: "unity", width: 40 },
      { header: "Visitante Nombre", key: "visitName", width: 30 },
      { header: "Visitante Apellido", key: "visitLastName", width: 30 },
      { header: "Motivo Visita", key: "subject", width: 30 },
      { header: "DNI", key: "dni", width: 30 },
      { header: "Hora Visita", key: "entry", width: 30 },
      { header: "Fecha Registro", key: "date", width: 30 },
    ];

    // Agregar datos
    worksheet.addRows(transformedData);
  } else if (type === "GoodsControl") {
    const visitData: UserGoodsControl[] = data as UserGoodsControl[];

    const transformedData = visitData.map((item) => ({
      unity: item.unity?.label,
      provider: item.provider,
      dni: item.document,
      entry: item.entry,
      date: formatDateTime(item.timestamp),
    }));

    // Agregar encabezado
    worksheet.columns = [
      { header: "Unidad", key: "unity", width: 40 },
      { header: "Proveedor", key: "provider", width: 30 },
      { header: "DNI", key: "dni", width: 30 },
      { header: "Hora Ingreso", key: "entry", width: 30 },
      { header: "Fecha Registro", key: "date", width: 30 },
    ];

    // Agregar datos
    worksheet.addRows(transformedData);
  }

  // Estilos de encabezado
  worksheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFc31919" }, // Fondo
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  // Estilos para datos
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      // Evitar encabezado
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    }
  });

  // Guardar el archivo
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  if (type === "UserVehicleControl") {
    link.download = `export_VehicleControl_${formatDateTime(
      currentTimeStamp
    )}_.xlsx`;
  } else if (type === "Attendance") {
    link.download = `export_AttendanceControl_${formatDateTime(
      currentTimeStamp
    )}_.xlsx`;
  } else if (type === "UserPatrol") {
    link.download = `export_PatrolControl_${formatDateTime(
      currentTimeStamp
    )}_.xlsx`;
  } else if (type === "VisitControl") {
    link.download = `export_VisitControl_${formatDateTime(
      currentTimeStamp
    )}_.xlsx`;
  } else if (type === "GoodsControl") {
    link.download = `export_GoodsControl_${formatDateTime(
      currentTimeStamp
    )}_.xlsx`;
  }

  link.click();
};
