import React, { FC, MutableRefObject, useCallback, useRef } from "react";
import { EmptyState } from "../../../components/state/empty";
import { UserSupervision } from "../../../../domain/supervision/Supervision";
import { SupervisionRow } from "./row";

import { useDataPager } from "../../../../hook/pager";
import { Pagination } from "rsuite";

type SupervisionListProps = {
  supervisionList?: UserSupervision[];
  onPreviewClicked: (item: UserSupervision) => void;
  onSelect: (item: UserSupervision) => void;
  name?: string;
};

export const SupervisionList: FC<SupervisionListProps> = ({
  supervisionList,
  onPreviewClicked,
  onSelect,
  name,
}) => {
  const actionRef = useRef<string | null>(null) as MutableRefObject<
    string | null
  >;

  /* eslint-disable */

  const { limits, changeLimit, limit, page, partialData, changePage } =
    useDataPager<UserSupervision>({
      id: `pat_list_${name}`,
      items: supervisionList,
    });

  const onPreviewRowClicked = useCallback<
    React.MouseEventHandler<HTMLElement>
  >(() => {
    actionRef.current = "preview";
  }, [partialData]);

  const onSelectRow = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      if (!partialData) return;
      const index = event.currentTarget.dataset["index"] as unknown as number;
      const item = partialData[index];
      if (item) {
        if (actionRef.current === "preview") {
          onPreviewClicked(item);
        } else {
          onSelect(item);
        }
      }
      actionRef.current = null;
    },
    [partialData]
  );

  /* eslint-enable */

  return supervisionList && supervisionList.length > 0 ? ( //@ts-ignore
    <div className={"w-full h-auto"}>
      {partialData?.map((patrol, index) => (
        <SupervisionRow
          index={index}
          style={{}}
          data={{
            supervisionList: partialData,
            onSelect: onSelectRow,
            onPreviewClicked: onPreviewRowClicked,
          }}
          key={`pat-row-${patrol.reference}`}
        />
      ))}

      <div className={"py-4 flex flex-col gap-4"}>
        <span className={"text-md"}>
          Mostrando {partialData?.length} elementos / Total{" "}
          {supervisionList.length}
        </span>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["limit", "pager"]}
          total={supervisionList.length}
          limitOptions={limits}
          limit={limit}
          activePage={page}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>
    </div>
  ) : (
    <EmptyState
      title={"No hay rondas"}
      message={
        "No se encontraron reportes de rondas para la fecha seleccionada."
      }
    />
  );
};
