import { FC, useContext } from "react";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { UserVehicleControl } from "../../../../../domain/control/Vehicle";
import { VehicleControlList } from "../../../data-display/vehicle-control-list";
import { VehicleControlTable } from "../../../data-display/vehicle-control-table";

type Props = {
  items?: UserVehicleControl[];
  onPreview?: (item: UserVehicleControl) => void;
  onClick: (user: UserVehicleControl) => void;
};

export const VehicleControlGroupByRequestDataName =
  "VehicleControlGroupByRequestData";

export const VehicleControlGroupByRequestData: FC<Props> = ({
  items,
  onPreview,
  onClick,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <VehicleControlList
        onSelect={onClick}
        vehicleControlList={items}
        name={VehicleControlGroupByRequestDataName}
      />
    );
  } else {
    return (
      <VehicleControlTable
        vehicleControlList={items}
        onPreviewClicked={onPreview}
        onSelect={onClick}
        name={VehicleControlGroupByRequestDataName}
      />
    );
  }
};
