// src/components/LogoLoader.tsx
import React, { Suspense, useState, useEffect, useContext } from "react";
import logoMap from "./logoMap";
import logoClassMap from "./logoClass";
import { UserAuthContext } from "../../context/UserContext";

const LogoLoader: React.FC = () => {
  const { appUser } = useContext(UserAuthContext);

  const [LogoComponent, setLogoComponent] = useState<React.ComponentType<
    React.SVGProps<SVGSVGElement>
  > | null>(null);
  const [logoClass, setLogoClass] = useState<string>("");

  useEffect(() => {
    const loadLogo = async () => {
      if (appUser.user.unityIdParent) {
        if (logoMap[appUser.user.unityIdParent.toString()]) {
          const { ReactComponent } = await logoMap[
            appUser.user.unityIdParent.toString()
          ]();
          setLogoComponent(() => ReactComponent);
          setLogoClass(
            logoClassMap[appUser.user.unityIdParent.toString()] ||
              "h-10 w-32 fill-white"
          );
        }
      } else {
        // en caso no exista unityIdParent
        const { ReactComponent } = await logoMap["logoPentracker2"]();
        setLogoComponent(() => ReactComponent);
        setLogoClass(logoClassMap["logoPentracker2"]);
      }
    };

    loadLogo();
  }, []);

  if (!LogoComponent) {
    return <div>Logo not found...</div>;
  }

  return <LogoComponent className={`${logoClass}`} />;
};

export default LogoLoader;
