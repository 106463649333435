import React, { FC } from "react";
import {
  faBookBookmark,
  faBoxesAlt,
  faCalendar,
  faCarAlt,
  faInfo,
  faMap,
  faPeopleGroup,
  faSync,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { UserRoles } from "../../../../domain/user/Role";
import { RoleBasedNavigator } from "../../../components/nav/navigator";
import { useParams } from "react-router-dom";
import { UserWrapper } from "../../../../domain/user/User";

type Props = {
  appUser: UserWrapper;
};

export const UnityPageNavigator: FC<Props> = ({ appUser }) => {
  const { id } = useParams();
  return (
    <RoleBasedNavigator
      definition={[
        {
          id: `/dashboard/unity/${id}/main`,
          label: "Principal",
          icon: faInfo,
          allowedRoles: UserRoles.all,
        },
        {
          id: `/dashboard/unity/${id}/user`,
          label: appUser.isClient() ? "Personal" : "Agentes",
          icon: faUserGroup,
          allowedRoles: UserRoles.all,
        },
        {
          id: `/dashboard/unity/${id}/attendance`,
          label: "Asistencia",
          icon: faCalendar,
          allowedRoles: UserRoles.all,
        },
        {
          id: `/dashboard/unity/${id}/patrol`,
          label: "Rondas",
          icon: faSync,
          allowedRoles: UserRoles.all,
        },
        {
          id: `/dashboard/unity/${id}/incident`,
          label: "Incidencias",
          icon: faBookBookmark,
          allowedRoles: UserRoles.all,
        },
        {
          id: `/dashboard/unity/${id}/zone`,
          label: "Zonas",
          icon: faMap,
          allowedRoles: [UserRoles.mainadmin, UserRoles.admin],
        },
        {
          id: `/dashboard/unity/${id}/zoneForUnity`,
          label: "QR para Unidad",
          icon: faMap,
          allowedRoles: [UserRoles.mainadmin],
        },
        {
          id: `/dashboard/unity/${id}/visit-control`,
          label: "Control de Visitas",
          icon: faPeopleGroup,
          allowedRoles: UserRoles.all,
        },
        {
          id: `/dashboard/unity/${id}/vehicle-control`,
          label: "Control Vehicular",
          icon: faCarAlt,
          allowedRoles: UserRoles.all,
        },
        {
          id: `/dashboard/unity/${id}/goods-control`,
          label: "Control de Bienes y Equipos",
          icon: faBoxesAlt,
          allowedRoles: UserRoles.all,
        },
      ]}
    />
  );
};
