import {
  collection,
  doc,
  DocumentSnapshot,
  getDoc,
  getDocFromCache,
  onSnapshot,
  query,
  QueryConstraint,
  startAfter,
  Timestamp,
  Unsubscribe,
  where,
} from "firebase/firestore";
import FirestoreSource from "../source/FirestoreSource";
import {
  Patrol,
  patrolAsUserPatrol,
  patrolDtoAsDomain,
  UserPatrol,
} from "../../domain/patrol/Patrol";
import { GetPatrolDto } from "../../network/patrol/Patrol";
import { FirestoreSimpleCrudSource } from "../source/FirestoreSimpleCrudSource";
import { AppTimestamp } from "../../domain/app/Timestamp";
import { DateTime } from "luxon";
import { isEmpty } from "lodash";
import { orderBy as firestoreOrderBy } from "@firebase/firestore";
import { AppTimestampDao } from "../database/dao/AppTimestamp";
import { PatrolDao } from "../database/dao/PatrolSup";
import UnityRepository from "./UnityRepository";
import ZoneRepository from "./ZoneRepository";
import UserRepository from "./UserRepository";
import { UserWrapper } from "../../domain/user/User";

export class PatrolSupRepository {
  private static patrolSource = new FirestoreSimpleCrudSource<GetPatrolDto>(
    "patrolSup"
  );

  private static unsubscribeRef: Unsubscribe | null = null;

  static async getList(
    timestamp: number,
    unityId: number,
    forceRefresh?: boolean
  ): Promise<
    { patrolList: UserPatrol[]; timestamp?: AppTimestamp } | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `patrollist-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let patrolList = await PatrolDao.getAllUnityPatrol(
      unityId,
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(patrolList) || forceRefresh) {
      await PatrolDao.deleteAllUnityPatrol(
        unityId,
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemotePatrolList(dayStartTimestamp, dayEndTimestamp, [
        where("unityId", "==", unityId),
      ]);
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      patrolList = await PatrolDao.getAllUnityPatrol(
        unityId,
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const userPatrolArray = await this.patrolArrayToUserPatrolArray(patrolList);
    return {
      patrolList: userPatrolArray,
      timestamp: appTimestamp,
    };
  }

  static async getUserList(
    timestamp: number,
    uid: string,
    forceRefresh: boolean = false,
    appUser: UserWrapper
  ): Promise<
    { patrolList: UserPatrol[]; timestamp?: AppTimestamp } | undefined
  > {
    if (appUser?.isClient()) {
      if (appUser?.user.clientUnity) {
        return this.getUserListWithConstraints(timestamp, uid, forceRefresh, [
          where("unityId", "in", appUser!.user.clientUnity),
        ]);
      }
    } else {
      return this.getUserListWithConstraints(timestamp, uid, forceRefresh);
    }
  }

  static async getGlobal(
    timestamp: number,
    forceRefresh: boolean = false,
    appUser: UserWrapper
  ): Promise<
    { patrolList: UserPatrol[]; timestamp?: AppTimestamp } | undefined
  > {
    if (appUser?.isClient()) {
      if (appUser!.user.clientUnity)
        return this.getGlobalWithConstraints(timestamp, forceRefresh, [
          where("unityId", "in", appUser.user.clientUnity),
        ]);
    } else {
      return this.getGlobalWithConstraints(timestamp, forceRefresh);
    }
  }

  static async getPatrol(reference: string): Promise<UserPatrol | undefined> {
    const local = await PatrolDao.getPatrol(reference);
    if (local) {
      return await this.patrolToUserPatrol(local);
    }
  }

  static async getLive(
    onError: (error: Error) => void,
    appUser: UserWrapper
  ): Promise<Unsubscribe | undefined> {
    if (this.unsubscribeRef) return undefined;
    const dayStartTimestamp = DateTime.now()
      .setZone("America/Lima")
      .startOf("day")
      .toMillis();
    let lastDayPatrol = await PatrolDao.getLastPatrolAboveTimestamp(
      dayStartTimestamp
    );
    let lastDocument: DocumentSnapshot | undefined;
    if (lastDayPatrol) {
      try {
        lastDocument = await getDocFromCache(
          doc(FirestoreSource.firestore, lastDayPatrol.reference)
        );
      } catch (e: any) {
        lastDocument = await getDoc(
          doc(FirestoreSource.firestore, lastDayPatrol.reference)
        );
      }
    }
    const patrolCollection = collection(FirestoreSource.firestore, "patrol");
    const constraints: QueryConstraint[] = [
      firestoreOrderBy("timestamp", "asc"),
    ];
    if (!!lastDocument) constraints.push(startAfter(lastDocument));
    if (appUser.isClient() && appUser.user.clientUnity)
      constraints.push(where("unityId", "in", appUser.user.clientUnity));
    let patrolQuery = query(patrolCollection, ...constraints);

    this.unsubscribeRef = onSnapshot(
      patrolQuery,
      {
        includeMetadataChanges: false,
      },
      async (snapshot) => {
        for (let documentSnapshot of snapshot.docs) {
          const patrol = patrolDtoAsDomain(
            documentSnapshot.data() as GetPatrolDto,
            documentSnapshot.ref.path
          );
          await PatrolDao.putPatrol(patrol);
        }
      },
      (error) => {
        onError(error);
      }
    );
    return this.unsubscribeRef;
  }

  static async stopLive() {
    if (this.unsubscribeRef) {
      this.unsubscribeRef();
      this.unsubscribeRef = null;
    }
  }

  static async patrolToUserPatrol(patrol: Patrol): Promise<UserPatrol> {
    const unity = patrol.unityId
      ? await UnityRepository.getUnity(patrol.unityId)
      : undefined;
    const zone = patrol.submoduleId
      ? await ZoneRepository.getZone(patrol.unityId, patrol.submoduleId)
      : undefined;
    const owner = await UserRepository.getUser(patrol.ownerUid);
    return patrolAsUserPatrol(patrol, unity, zone, owner);
  }

  private static async getUserListWithConstraints(
    timestamp: number,
    uid: string,
    forceRefresh: boolean = false,
    constraints: QueryConstraint[] = []
  ): Promise<
    { patrolList: UserPatrol[]; timestamp?: AppTimestamp } | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `user-patrollist-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let patrolList = await PatrolDao.getUserPatrolList(
      uid,
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(patrolList) || forceRefresh) {
      await PatrolDao.deleteAllUserPatrol(
        uid,
        dayStartTimestamp,
        dayEndTimestamp
      );
      await this.fetchRemotePatrolList(dayStartTimestamp, dayEndTimestamp, [
        where("ownerUid", "==", uid),
        ...constraints,
      ]);
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      patrolList = await PatrolDao.getUserPatrolList(
        uid,
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const userPatrolArray = await this.patrolArrayToUserPatrolArray(patrolList);
    return {
      patrolList: userPatrolArray,
      timestamp: appTimestamp,
    };
  }

  private static async getGlobalWithConstraints(
    timestamp: number,
    forceRefresh: boolean = false,
    constraints: QueryConstraint[] = []
  ): Promise<
    { patrolList: UserPatrol[]; timestamp?: AppTimestamp } | undefined
  > {
    const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dayStartTimestamp = dateTime.startOf("day").toMillis();
    const dayEndTimestamp = dateTime.endOf("day").toMillis();
    const appTimestampKey = `global-patrollist-${dayStartTimestamp}`;
    let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    let patrolList = await PatrolDao.getAllPatrol(
      dayStartTimestamp,
      dayEndTimestamp
    );
    if (isEmpty(patrolList) || forceRefresh) {
      await PatrolDao.deleteAllPatrol(dayStartTimestamp, dayEndTimestamp);
      await this.fetchRemotePatrolList(
        dayStartTimestamp,
        dayEndTimestamp,
        constraints
      );
      await AppTimestampDao.putTimestamp({
        key: appTimestampKey,
        timestamp: Date.now(),
      });
      patrolList = await PatrolDao.getAllPatrol(
        dayStartTimestamp,
        dayEndTimestamp
      );
      appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
    }
    const userPatrolArray = await this.patrolArrayToUserPatrolArray(patrolList);
    return {
      patrolList: userPatrolArray,
      timestamp: appTimestamp,
    };
  }

  private static async patrolArrayToUserPatrolArray(
    patrolArray: Patrol[]
  ): Promise<UserPatrol[]> {
    return await Promise.all(
      patrolArray.map(async (it) => await this.patrolToUserPatrol(it))
    );
  }

  private static async fetchRemotePatrolList(
    dateStartMillis: number,
    dateEndMillis: number,
    constraints: QueryConstraint[] = []
  ): Promise<void> {
    const patrolList = await this.patrolSource.getList([
      firestoreOrderBy("timestamp", "desc"),
      where("timestamp", ">=", Timestamp.fromMillis(dateStartMillis)),
      where("timestamp", "<=", Timestamp.fromMillis(dateEndMillis)),
      ...constraints,
    ]);
    if (patrolList) {
      const entries = patrolList.map((it) =>
        patrolDtoAsDomain(it.data, it.reference)
      );
      await PatrolDao.putPatrol(...entries);
    }
  }
}
