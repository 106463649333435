import { FC } from "react";
import { Modal } from "rsuite";
import { UserVisitControl } from "../../../../domain/control/Visit";
import { VisitControlView } from "../../visit-control/components/view";

export const ByRequestVisitControlModal: FC<{
  onClose: () => void;
  visitControl: UserVisitControl;
}> = ({ onClose, visitControl }) => {
  return (
    <Modal open={true} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Visitas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VisitControlView visitControl={visitControl} />
      </Modal.Body>
    </Modal>
  );
};
