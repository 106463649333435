import { VehicleListQueryRequest } from "../../../../../network/control/Vehicle";
import { InputDataList } from "../../../../components/modal/FormModal";
import { InputDataListS } from "../../../vehicle-control-group/request/modal/SearchVehicleModal";

const OrderByOptions: {
  value: `${VehicleListQueryRequest["orderBy"]}`;
  label: string;
}[] = [
  {
    value: "plate",
    label: "Placa",
  },
  {
    value: "unityId",
    label: "Unidad",
  },
  {
    value: "driver",
    label: "Conductor",
  },
];

const OrderOptions: {
  value: `${VehicleListQueryRequest["order"]}`;
  label: string;
}[] = [
  {
    value: "asc",
    label: "Ascendente",
  },
  {
    value: "desc",
    label: "Descendente",
  },
];

const FilterOptions: {
  value: string;
  label: string;
}[] = [
  {
    value: "plate",
    label: "Placa",
  },
  {
    value: "unityId",
    label: "Unidad",
  },
  {
    value: "driver",
    label: "Conductor",
  },
];

export const FilterVehicleInputList: InputDataList = [
  {
    name: "orderBy",
    type: "select",
    label: "Ordenar por",
    options: OrderByOptions,
    cleanable: false,
  },
  {
    name: "order",
    type: "select",
    label: "Orden",
    options: OrderOptions,
    cleanable: false,
  },
];

export const SearchVehicleInputList: InputDataListS = [
  {
    name: "filter",
    type: "select",
    label: "Buscar por",
    options: FilterOptions,
    cleanable: false,
  },
  {
    name: "search",
    type: "keyword",
    label: "Palabra Clave",
    placeholder: "Ingrese busqueda",
    defaultValue: "",
    // options: FilterOptions,
    // cleanable: false,
  },
  {
    name: "dpInicio",
    type: "datepicker",
    label: "Desde",
    // options: FilterOptions,
    // cleanable: false,
  },
  {
    name: "dpFinal",
    type: "datepicker",
    label: "Hasta",
    // options: FilterOptions,
    // cleanable: false,
  },
  {
    name: "searchInAll",
    type: "checkbox",
    label: "Todos los Registros",
    defaultValue: false,
    // options: FilterOptions,
    // cleanable: false,
  },
];
