import { FC, useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Schema } from "rsuite";

type Props = {
  description?: string;
  visible?: boolean;
  onClose: () => void;
  onConfirmation: () => void;
  clear?: boolean;
};

const AlertModal: FC<Props> = ({
  description,
  visible,
  onClose,
  onConfirmation,
  clear,
}) => {
  return (
    <Modal onClose={onClose} open={visible}>
      <Modal.Header>
        <Modal.Title> Alerta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {description ? (
          <p className={"text-md text-neutral-700 whitespace-pre-line"}>
            {description}
          </p>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        {/* <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancelar
        </Button> */}
        <Button
          appearance={"primary"}
          onClick={() => {
            onConfirmation();
          }}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
