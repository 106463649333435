import { FC } from "react";
import { CellProps, Table } from "rsuite";
import { AttendancePair } from "../../../../domain/attendance-sup/Attendance";
import { Link } from "react-router-dom";

export const AttendanceGroupSupTableOperatorCell: FC<
  CellProps<AttendancePair>
> = ({ rowData, ...props }) => {
  const { entry } = rowData!;
  return (
    <Table.Cell {...props}>
      <Link to={`/dashboard/users/${entry.ownerUid}/main`}>
        {entry.owner ? entry.owner.displayName : entry.ownerUid}
      </Link>
    </Table.Cell>
  );
};
