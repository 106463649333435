import {
  forEach,
  isEqual,
  isNil,
  join,
  keys as getKeys,
  keysIn,
  omit,
  omitBy,
  PropertyName,
  shuffle,
  toInteger,
  toString,
  union,
} from "lodash";

export function ignoreNil<T>(obj: any) {
  return omitBy(obj, [isNil]);
}

export function ignoreNilAndPaths<T>(obj: any, ...paths: PropertyName[]) {
  return omit(ignoreNil(obj), paths) as T;
}

export function ignorePaths<T>(obj: any, ...paths: PropertyName[]) {
  return omit(obj, paths) as T;
}

export function copyDifferentProperties<T, R>(obj1: T, obj2: T): R {
  let result = {} as R;
  let keys = union(getKeys(obj1), getKeys(obj2));
  for (let key of keys) {
    // @ts-ignore
    const val1 = obj1[key];
    // @ts-ignore
    const val2 = obj2[key];
    if (!isEqual(val1, val2)) {
      // @ts-ignore
      result[key] = val2;
    }
  }

  return result;
}

export function copySchema<A, B>(obj1: A, obj2: B): A {
  const result = {};
  forEach(Object.keys(obj1 as any), (key) => {
    // @ts-ignore
    result[key] = obj2 ? obj2[key] : null;
  });
  return result as A;
}

export function undefToNull<T extends object>(object: T): T {
  let copy = { ...object };
  forEach(keysIn(object), (key) => {
    // @ts-ignore
    if (copy[key] === undefined) copy[key] = null;
  });
  return copy;
}

export function generateRandomNumberId() {
  let windowNano = window.performance.now();
  windowNano = windowNano ? windowNano : Math.random() * 200;
  return toInteger(
    join(
      shuffle(toString(toInteger(windowNano + Date.now())).split(""))
    ).replace(/,/g, "")
  );
}
