import React, { FC } from "react";
import { ListChildComponentProps } from "react-window";
import { IconButton } from "rsuite";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { UserSupervision } from "../../../../domain/supervision/Supervision";
import { SupervisionRowTimestamp } from "./timestamp";
import { SupervisionRowOperator } from "./operator";
import { SupervisionRowUnity } from "./unity";
// import {PatrolRowZone} from "./zone";
// import {PatrolRowIncident} from "./incident";

export type SupervisionRowProps = {
  supervisionList: UserSupervision[];
  onPreviewClicked: React.MouseEventHandler<HTMLElement>;
  onSelect: React.MouseEventHandler<HTMLDivElement>;
};

export const SupervisionRow: FC<
  ListChildComponentProps<SupervisionRowProps>
> = ({ data, index, style }) => {
  const { onPreviewClicked, onSelect, supervisionList } = data;
  const item = supervisionList[index];
  return (
    <div style={style} className={"py-2"}>
      <div
        data-index={index}
        onClick={onSelect}
        className={
          "w-full h-full border border-neutral-300 rounded-lg p-2 flex flex-col justify-between content-between"
        }
      >
        <div className={"flex w-full flex-col gap-2"}>
          <SupervisionRowTimestamp item={item} />
          <SupervisionRowOperator item={item} />
          <SupervisionRowUnity item={item} />
          {/* <PatrolRowZone item={item} />
          <PatrolRowIncident item={item} /> */}
        </div>
        <IconButton
          appearance={"primary"}
          icon={<FaIcon icon={faEye} />}
          onClick={onPreviewClicked}
        >
          Vista Previa
        </IconButton>
      </div>
    </div>
  );
};
