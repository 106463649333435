import { db } from "../db";
import { VehicleControl } from "../../../domain/control/Vehicle";
import { toArray } from "lodash";

export class VehicleControlDao {
  static async putVehicleControl(...vehicleControl: VehicleControl[]) {
    return db.vehicle_control.bulkPut(vehicleControl);
  }

  static async getAllUnityVehicleControl(
    unityId: number,
    start: number,
    end: number
  ) {
    return db.vehicle_control
      .where(["unityId+timestamp"])
      .between([unityId, start], [unityId, end], true, true)
      .reverse()
      .sortBy("timestamp");
  }

  static async deleteAllUnityVehicleControl(
    unityId: number,
    start: number,
    end: number
  ) {
    return db.vehicle_control
      .where(["unityId+timestamp"])
      .between([unityId, start], [unityId, end], true, true)
      .delete();
  }

  // static async getUserVehicleControlList(ownerUid: string, start: number, end: number) {
  //     return db.vehicle_control
  //         .where(["ownerUid+timestamp"])
  //         .between([ownerUid, start], [ownerUid, end])
  //         .reverse()
  //         .sortBy("timestamp");
  // }

  static async getUserVehicleControlList(
    ownerUid: string,
    start: number,
    end: number
  ) {
    return db.vehicle_control
      .where(["ownerUid+timestamp"])
      .between([ownerUid, start], [ownerUid, end])
      .reverse()
      .sortBy("timestamp");
  }

  static async deleteAllUserVehicleControl(
    ownerUid: string,
    start: number,
    end: number
  ) {
    return db.vehicle_control
      .where(["ownerUid+timestamp"])
      .between([ownerUid, start], [ownerUid, end])
      .delete();
  }

  //   static async getAllVehicleControl(start: number, end: number) {
  //     return db.vehicle_control
  //       .where("timestamp")
  //       .between(start, end, true, true)
  //       .reverse()
  //       .sortBy("timestamp");
  //   }

  static async getAllVehicleControl(
    orderBy?: string,
    order?: "asc" | "desc" | undefined,
    start?: number,
    end?: number
  ) {
    if (start === undefined && end === undefined) {
      let query = db.vehicle_control.orderBy("timestamp").reverse().toArray();
      let data = await query;
      if (order && order === "desc") data = data.reverse();
      return data;
    } else {
      let query = db.vehicle_control
        .where("timestamp")
        .between(start, end, true, true)
        .sortBy(orderBy ? orderBy : "timestamp");
      let data = await query;
      if (order && order === "desc") data = data.reverse();
      return data;
    }

  }

  static async deleteAllVehicleControl(
    start: number | undefined,
    end: number | undefined
  ) {
    if (start === undefined && end === undefined) {
      return db.vehicle_control.clear();
    } else {
      return db.vehicle_control
        .where("timestamp")
        .between(start, end, true, true)
        .delete();
    }
  }

  static async getContainer(path: string) {
    return db.vehicle_control.get(path);
  }

  static async getVehicleControl(reference: string) {
    return db.vehicle_control.get(reference);
  }
}
