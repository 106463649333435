import { FC } from "react";
import { toTime } from "../../../../lib/date";
import { AttendancePair } from "../../../../domain/attendance/Attendance";
import { faUserClock } from "@fortawesome/free-solid-svg-icons";
import { FaIcon } from "../../../components/fontawesome/icon";

type Props = {
  item: AttendancePair;
};

export const AttendanceListRowDeparture: FC<Props> = ({ item }) => {
  const { departure } = item;
  if (!!departure) {
    const { timestamp } = departure;
    return (
      <div>
        <span className={"text-neutral-400 font-semibold"}>Salida </span>
        {toTime(timestamp)}
      </div>
    );
  } else {
    return (
      <div className={"flex flex-row flex-wrap items-center"}>
        <FaIcon icon={faUserClock} className={"mr-2"} />
        <span>Salida pendiente</span>
      </div>
    );
  }
};
