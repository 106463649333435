import { Zone } from "../../../../../domain/patrol/Zone";
import { Unity } from "../../../../../domain/unity";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { ZoneDocumentImages } from "./vfs";
import { RefObject } from "react";
import { previewPdfFile } from "../../../../../util/file";

pdfMake.vfs = {
  ...pdfFonts.pdfMake.vfs,
};

pdfMake.tableLayouts = {
  layout: {
    hLineWidth: function (_, _2) {
      return 0;
    },
    vLineWidth: function (_) {
      return 0;
    },
    hLineColor: function (_) {
      return "white";
    },
    paddingLeft: function (_) {
      return 0;
    },
    paddingRight: function (_, _2) {
      return 0;
    },
  },
};

export const generateZoneDefinition: (zone: Zone) => TDocumentDefinitions = (
  zone
) => {
  const docDef: TDocumentDefinitions = {
    content: [
      {
        image: "logo",
        style: {
          alignment: "center",
        },
        marginTop: 10,
        width: 440,
      },
      {
        qr: JSON.stringify({
          type: "patrol",
          id: zone.id,
        }),
        foreground: "#212121",
        background: "white",
        fit: 450,
        alignment: "center",
        marginTop: 50,
        marginBottom: 20,
      },
      {
        columns: [
          "Escanear a través de Pentracker",
          {
            text: `${zone.id}`,
            color: "#424242",
          },
        ],
        columnGap: 5,
        alignment: "center",
      },

      {
        text: "© 2023 Pentágono Seguridad  |  https://pentagonosegur.com.pe",
        alignment: "center",
        marginTop: 50,
      },
      {
        image: "pentagono",
        height: 40,
        width: 165,
        alignment: "center",
        marginTop: 8,
      },
    ],
    images: ZoneDocumentImages,
  };
  return docDef;
};

export const createZoneDocument = async (zone: Zone, unity: Unity) => {
  const pdf = await pdfMake.createPdf(generateZoneDefinition(zone));

  await new Promise((resolve) => {
    pdf.download(`${unity.label}___${zone.label}.pdf`, () => {
      resolve(true);
    });
    resolve(true);
  });
};

export const previewZoneDocument = async (
  zone: Zone,
  ref: RefObject<HTMLDivElement>
) => {
  const pdfDocGenerator = await pdfMake.createPdf(generateZoneDefinition(zone));
  const url: string = await new Promise((res) => {
    pdfDocGenerator.getDataUrl((dataUrl) => {
      res(dataUrl);
    });
  });
  await previewPdfFile({ fileUrl: url }, ref);
};
