import { FC, useContext } from "react";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { UserSupervision } from "../../../../../domain/supervision/Supervision";
import { SupervisionList } from "../../../data-display/supervision-list";
import { SupervisionTable } from "../../../data-display/supervision-table";

type Props = {
  items?: UserSupervision[];
  onPreview: (item: UserSupervision) => void;
  onClick: (user: UserSupervision) => void;
};

export const SupervisionUnityGroupByRequestDataName =
  "SupervisionGroupByRequestData";

export const SupervisionUnityGroupByRequestData: FC<Props> = ({
  items,
  onPreview,
  onClick,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <SupervisionList
        onSelect={onClick}
        supervisionList={items}
        onPreviewClicked={onPreview}
        name={SupervisionUnityGroupByRequestDataName}
      />
    );
  } else {
    return (
      <SupervisionTable
        userSupervisionList={items}
        onPreviewClicked={onPreview}
        onSelect={onClick}
        name={SupervisionUnityGroupByRequestDataName}
      />
    );
  }
};
