import { useContext, useState } from "react";
import { Response } from "../../domain/app/Response";
import { AppTimestamp } from "../../domain/app/Timestamp";
import { UserAuthContext } from "../../ui/context/UserContext";
import { UserVisitControl } from "../../domain/control/Visit";
import { VisitControlRepository } from "../../data/repository/VisitControlRepository";
import { LocalErrorLogRepository } from "../../data/repository/LocalErrorLogRepository";
import { generateExcel } from "../../util/generateExcel";

type FormModalValues = {
  [key: string]: any;
};

export function useVisitControlViewModel() {
  const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
  const [visitControlList, setVisitControlList] = useState<
    UserVisitControl[] | undefined
  >();
  const [visitControlTimestamp, setVisitControlTimestamp] = useState<
    AppTimestamp | undefined
  >();
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
  const { appUser } = useContext(UserAuthContext);

  // search
  const [searchEvent, setSearchEvent] = useState<boolean | null>(null);
  const [searchState, setSearchState] = useState<Response<boolean> | null>(
    null
  );
  const [searchResults, setSearchResults] = useState<UserVisitControl[] | null>(
    null
  );

  // generate excel
  const [generateEvent, setGenerateEvent] = useState<boolean | null>(null);

  const [generateState, setGenerateState] = useState<Response<boolean> | null>(
    null
  );
  // modal alert
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [descriptionAlert, setDescriptionAlert] = useState("");

  async function fetchVisitControlList(
    timestamp: number,
    forceRefresh: boolean = false
  ) {
    if (fetchState?.isLoading()) return;
    setFetchState(Response.loading());
    try {
      const result = await VisitControlRepository.getGlobal(
        timestamp,
        forceRefresh,
        appUser
      );
      setVisitControlList(result?.visitControlList);
      setVisitControlTimestamp(result?.timestamp);
      setFetchState(Response.success(true));
    } catch (e: any) {
      setFetchState(Response.failure(e));
    }
  }

  async function searchVisit(values: FormModalValues) {
    setFetchState(Response.loading());
    try {
      const results = await VisitControlRepository.searchVisit(
        values.filter,
        values.search,
        values.searchInAll === true ? null : values.dpInicio.getTime(),
        values.searchInAll === true ? null : values.dpFinal.getTime(),
        values.searchInAll
      );
      console.log("searchResult", results);
      setSearchResults(results || []);
      setFetchState(Response.success(true));
    } catch (e: any) {
      const label = `Error al buscar visita: '${values.search}'`;
      await LocalErrorLogRepository.registerError(label, e);
      setFetchState(Response.failure(new Error(label)));
    }
  }

  async function toGenerateExcel() {
    setFetchState(Response.loading());
    try {
      console.log("to generate excel");
      console.log("search results", searchResults);
      if (searchResults?.length !== undefined && searchResults?.length > 0) {
        generateExcel(searchResults, "VisitControl");
      } else {
        setIsAlertVisible(true);
        setDescriptionAlert(
          "Se necesita obtener resultados para generar Excel"
        );
      }

      setFetchState(Response.success(true));
    } catch (e: any) {
      const label = `Error al generar excel: ''`;
      await LocalErrorLogRepository.registerError(label, e);
      setFetchState(Response.failure(new Error(label)));
    }
  }

  function onFetchStateReceived() {
    setFetchState(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  function requestSearchEvent() {
    setSearchEvent(true);
  }

  function onSearchEventCompleted() {
    setSearchEvent(null);
    setSearchResults(null);
  }
  return {
    //fetch
    fetchState,
    onFetchStateReceived,
    visitControlList,
    visitControlTimestamp,
    fetchVisitControlList,
    //refresh
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
    //search
    searchEvent,
    searchState,
    searchResults,
    requestSearchEvent,
    onSearchEventCompleted,
    searchVisit,

    //generate
    toGenerateExcel,

    //alert
    isAlertVisible,
    setIsAlertVisible,
    descriptionAlert,
  };
}
